import { PlanningGeographicsType } from '../../../Types/result/mix/PlanningGeographicsType'
import { CityModel } from '../../CityModel'
import { PlanningElementClassificationsModel } from '../mix/PlanningElementClassificationsModel'
import { PlanningElementModel } from './PlanningElementModel'

export class PlanningElementsGeographicsModel {
    private _id: number
    private _type: string
    private _budget: number
    private _budgetEstimated: number
    private _cpm: number
    private _cpmEstimated: number
    private _geographicId: number
    private _geographicModel: string
    private _ots: number
    private _otsEstimated: number
    private _planningId: number
    private _city: CityModel
    private _elements: PlanningElementModel[]
    private _elementsClassificationsList: PlanningElementClassificationsModel[]

    constructor(planElem: PlanningGeographicsType, included: any) {
        this._id = planElem.id
        this._type = planElem.type
        this._budget = planElem.attributes.budget
        this._budgetEstimated = planElem.attributes.budget_estimated
        this._cpm = planElem.attributes.cpm
        this._cpmEstimated = planElem.attributes.cpm_estimated
        this._geographicId = planElem.attributes.geographic_id
        this._geographicModel = planElem.attributes.geographic_model
        this._ots = planElem.attributes.ots
        this._otsEstimated = planElem.attributes.ots_estimated
        this._planningId = planElem.attributes.planning_id
        this._city = new CityModel(
            included.find(
                (item) =>
                    item.id === planElem.relationships?.city?.data.id &&
                    item.type === planElem.relationships?.city?.data.type,
            ),
        )

        if (
            included &&
            planElem.relationships?.element_classifications?.data.length
        ) {
            let elemList = []
            planElem.relationships?.element_classifications?.data.map(
                (item) => {
                    included.find((elem) => {
                        if (elem.id === item.id && elem.type === item.type) {
                            elemList.push(
                                new PlanningElementClassificationsModel(
                                    elem,
                                    included,
                                ),
                            )
                            return true
                        } else return false
                    })
                },
            )
            this._elementsClassificationsList = elemList
        }

        if (included && planElem.relationships?.elements?.data?.length) {
            let elemList = []
            planElem.relationships.elements.data.map((item) => {
                included.find((elem) => {
                    if (elem.id === item.id && elem.type === item.type) {
                        elemList.push(new PlanningElementModel(elem, included))
                        return true
                    } else return false
                })
            })
            this._elements = elemList
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get budget(): number {
        return this._budget
    }

    set budget(value) {
        this._budget = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get budgetEstimated(): number {
        return this._budgetEstimated
    }

    set budgetEstimated(value) {
        this._budgetEstimated = value
    }

    get cpm(): number {
        return this._cpm
    }

    set cpm(value) {
        this._cpm = value
    }

    get cpmEstimated(): number {
        return this._cpmEstimated
    }

    set cpmEstimated(value) {
        this._cpmEstimated = value
    }

    get geographicId(): number {
        return this._geographicId
    }

    set geographicId(value) {
        this._geographicId = value
    }

    get geographicModel(): string {
        return this._geographicModel
    }

    set geographicModel(value) {
        this._geographicModel = value
    }

    get ots(): number {
        return this._ots
    }
    set ots(value: number) {
        this._ots = value
    }
    get otsEstimated(): number {
        return this._otsEstimated
    }
    set otsEstimated(value: number) {
        this._otsEstimated = value
    }
    get planningId(): number {
        return this._planningId
    }
    set planningId(value: number) {
        this._planningId = value
    }
    get city(): CityModel {
        return this._city
    }
    set city(value: CityModel) {
        this._city = value
    }
    get elements(): PlanningElementModel[] {
        return this._elements
    }
    set elements(value: PlanningElementModel[]) {
        this._elements = value
    }
    get elementsClassificationsList(): PlanningElementClassificationsModel[] {
        return this._elementsClassificationsList
    }
    set elementsClassificationsList(
        value: PlanningElementClassificationsModel[],
    ) {
        this._elementsClassificationsList = value
    }
}
