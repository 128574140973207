import { FlexType } from '../Types/FlexType'

export class FlexModel {
    private _id: number
    private _type: string
    private _name: string

    constructor(flex: FlexType) {
        this._id = flex.id
        this._type = flex.type
        this._name = flex.attributes.name
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }
}
