// @ts-ignore
import { Skeleton } from 'antd'
import CountUp from 'react-countup'
import womanFill from '../../../../../../../Assets/Img/Grafico-Mujer-Fill.svg'
import woman from '../../../../../../../Assets/Img/Grafico-Mujer-Stroke.svg'
import man from '../../../../../../../Assets/Img/Grafico-Hombre-Stroke.svg'
import manFill from '../../../../../../../Assets/Img/Grafico-Hombre-Fill.svg'
//@ts-ignore
import { utility } from '@tdt-global/utility'

type PropType = {
    load: boolean
    data: any
}

export const EstimatedGender = ({ load, data }: PropType) => {
    const { Functions } = utility
    return (
        <div className='estimated-gender'>
            <div className='body'>
                {!load ? (
                    <>
                        <div className='chart'>
                            <div className='gender-card'>
                                {data.map((item, index) => {
                                    return item.type === 2 ? (
                                        <div className='gender' key={index}>
                                            <div className='svg'>
                                                <img
                                                    src={woman}
                                                    alt='React Logo'
                                                />
                                                <div
                                                    style={{
                                                        maxHeight: `${item.percentage}%`,
                                                    }}>
                                                    <img
                                                        src={womanFill}
                                                        alt='React Logo'
                                                    />
                                                </div>
                                            </div>
                                            <div className='bar'></div>
                                            <div className='legend'>
                                                <div className='title'>
                                                    {item.name}
                                                </div>

                                                <div className='counter'>
                                                    <CountUp
                                                        start={0}
                                                        end={item.percentage}
                                                        delay={0}>
                                                        {({ countUpRef }) => (
                                                            <div>
                                                                <span
                                                                    ref={
                                                                        countUpRef
                                                                    }
                                                                />
                                                                %
                                                            </div>
                                                        )}
                                                    </CountUp>
                                                </div>

                                                <div className='total'>
                                                    {Functions.formatNumber(
                                                        item.value,
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='gender' key={index}>
                                            <div className='svg'>
                                                <img
                                                    src={man}
                                                    alt='React Logo'
                                                />
                                                <div
                                                    style={{
                                                        maxHeight: `${item.percentage}%`,
                                                    }}>
                                                    <img
                                                        src={manFill}
                                                        alt='React Logo'
                                                    />
                                                </div>
                                            </div>
                                            <div className='bar'></div>
                                            <div className='legend'>
                                                <div className='title'>
                                                    {item.name}
                                                </div>

                                                <div className='counter'>
                                                    <CountUp
                                                        start={0}
                                                        end={item.percentage}
                                                        delay={0}>
                                                        {({ countUpRef }) => (
                                                            <div>
                                                                <span
                                                                    ref={
                                                                        countUpRef
                                                                    }
                                                                />
                                                                %
                                                            </div>
                                                        )}
                                                    </CountUp>
                                                </div>

                                                <div className='total'>
                                                    {Functions.formatNumber(
                                                        item.value,
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='footer'>
                                Valores estimados en base a la audiencia
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='chart'>
                            <Skeleton.Avatar
                                active
                                size={'large'}
                                shape={'circle'}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
