// @ts-ignore
import { Skeleton } from 'antd'

type PropType = {
    headerTitle?: string
    bodyTitle1?: string
    value1?: JSX.Element
    bodyTitle2?: string
    value2?: string
    loading: boolean
}

export const InfoCard = ({
    headerTitle,
    bodyTitle1,
    value1,
    bodyTitle2,
    value2,
    loading,
}: PropType) => {
    return (
        <div className='info-card'>
            {(headerTitle || loading) && (
                <div className='info-card-header'>
                    {loading && <Skeleton.Button active block size={'small'} />}
                    {headerTitle}
                </div>
            )}
            <div className='info-card-body'>
                {loading && (
                    <>
                        <Skeleton.Button active block size={'small'} />
                        <Skeleton.Button active block size={'small'} />
                        <Skeleton.Button active block size={'small'} />
                        <Skeleton.Button active block size={'small'} />
                        <Skeleton.Button active block size={'small'} />
                    </>
                )}
                {bodyTitle1 && <div className='titles'>{bodyTitle1}</div>}
                {value1 && <div className='value-small'>{value1}</div>}
                {bodyTitle2 && <div className='titles'>{bodyTitle2}</div>}
                {value2 && <div className='value-large'>{value2}</div>}
            </div>
        </div>
    )
}
