import { useEffect, useState } from 'react'
// @ts-ignore
import { Slider, Skeleton, Popover } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
type PropType = {
    value: number | null
    code: string
    title: string
    description: string
    disabled: boolean
    handleValueChange: (val: number, code: string) => void
}

export const StrategyPoint = ({
    value,
    title,
    description,
    code,
    disabled,
    handleValueChange,
}: PropType) => {
    const [point, setPoint] = useState(null)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setPoint(value)
    }, [value])

    const handleVisibleChange = (visible) => {
        setVisible(visible)
    }

    return (
        <div className='strategy-point'>
            <div className='title'>
                <span className='title-strategy-point'>{title}</span>
                <Popover
                    placement='topLeft'
                    overlayStyle={{ width: '600px' }}
                    content={
                        <div
                            style={{ color: '#595959' }}
                            className='description'>
                            {description}
                        </div>
                    }
                    trigger='click'
                    arrowPointAtCenter
                    visible={visible}
                    onVisibleChange={handleVisibleChange}>
                    <InfoCircleOutlined />
                </Popover>
            </div>
            {value || value === 0 ? (
                <Slider
                    step={1}
                    max={10}
                    min={0}
                    dots
                    value={point}
                    disabled={disabled}
                    tooltipVisible
                    onChange={(val) => setPoint(val)}
                    onAfterChange={(val) => handleValueChange(val, code)}
                />
            ) : (
                <Skeleton.Button active block size={'small'} />
            )}
        </div>
    )
}
