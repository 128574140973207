//@ts-ignore
import { Skeleton, Popover } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { useState } from 'react'

type PropType = {
    iso_type?: string
    amount: number
    helpText?: string
    footer: JSX.Element | string
}

export const HeaderBox = ({ iso_type, amount, helpText, footer }: PropType) => {
    const { Functions } = utility
    const [visible, setVisible] = useState(false)

    return (
        <div className='box budget'>
            <div className='currency'>
                {iso_type || amount ? (
                    <>
                        {iso_type ? iso_type : ''}{' '}
                        <span className='total-amount'>
                            {Functions.formatNumberWithKMB(amount)}
                        </span>
                        {helpText && (
                            <Popover
                                style={{ maxWidth: '300px' }}
                                content={
                                    <div
                                        style={{
                                            color: '#595959',
                                            maxWidth: '300px',
                                        }}
                                        className='description'>
                                        {helpText}
                                    </div>
                                }
                                trigger='click'
                                arrowPointAtCenter
                                visible={visible}
                                onVisibleChange={(visible) =>
                                    setVisible(visible)
                                }>
                                <InfoCircleOutlined />
                            </Popover>
                        )}
                    </>
                ) : (
                    <Skeleton.Input active size={'default'} />
                )}
            </div>
            <div className='text'>
                {iso_type || amount ? (
                    footer
                ) : (
                    <Skeleton.Input active size={'default'} />
                )}
            </div>
        </div>
    )
}
