import { CityType } from '../Types/CityType'
import { GeolocationType } from '../Types/GeolocationType'
import { DepartmentModel } from './DepartmentModel'
import { StateModel } from './StateModel'

export class CityModel {
    private _id: number
    private _type: string
    private _name: string
    private _state_id: number
    private _department_id: number
    private _geolocation: GeolocationType
    private _population: number
    private _state: StateModel
    private _department: DepartmentModel

    constructor(city: CityType, included?) {
        this._id = city.id
        this._type = city.type
        this._name = city.attributes.name
        this._state_id = city.attributes.state_id
        this._department_id = city.attributes.department_id
        this._geolocation = city.attributes.geolocation
        this._population = city.attributes.population
        this._state = null
        this._department = null

        if (included) {
            city?.relationships?.state?.data?.id
                ? (this._state = new StateModel(
                      included.find(
                          (item) =>
                              item.id ===
                                  city?.relationships?.state?.data?.id &&
                              item.type ===
                                  city?.relationships?.state?.data?.type,
                      ),
                  ))
                : (this._state = null)

            city?.relationships?.department?.data?.id
                ? (this._department = new DepartmentModel(
                      included.find(
                          (item) =>
                              item.id ===
                                  city?.relationships?.department?.data?.id &&
                              item.type ===
                                  city?.relationships?.department?.data?.type,
                      ),
                  ))
                : (this._department = null)
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get population(): number {
        return this._population
    }

    set population(value) {
        this._population = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get state_id(): number {
        return this._state_id
    }

    set state_id(value) {
        this._state_id = value
    }

    get department_id(): number {
        return this._department_id
    }

    set department_id(value) {
        this._department_id = value
    }

    get geolocation(): GeolocationType {
        return this._geolocation
    }

    set geolocation(value) {
        this._geolocation = value
    }

    get state(): StateModel {
        return this._state
    }

    set state(value) {
        this._state = value
    }

    get department(): DepartmentModel {
        return this._department
    }

    set department(value) {
        this._department = value
    }
}
