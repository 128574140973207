// @ts-ignore
import { Pagination, Typography, Select, Divider, ConfigProvider } from 'antd'
import es_ES from 'antd/es/locale/es_ES';

const { Option } = Select
const { Text, Title } = Typography

type PropsType = {
    showing: {
        total: number
        totaPages: number
    }
    nextPage: number
    sizePage: number
    loading: boolean
    onChange: (page: number) => void
    onChangeSizePage: (pageSize: string) => void
}

export const Header = ({ ...props }: PropsType) => {
    const { showing, nextPage, sizePage, loading, onChange, onChangeSizePage } =
        props
    return (
        <div className='header'>
            <Title level={5} className='title'>
                Todas las planificaciones
            </Title>

            {showing.total !== 0 && (
                <div className='pagination'>
                    <ConfigProvider locale={es_ES}>
                    <Pagination
                        total={showing.total}
                        showTotal={(total, range) =>
                            `${range[0]}-${range[1]} de ${total}`
                        }
                        showSizeChanger={false}
                        defaultPageSize={10}
                        pageSize={sizePage}
                        defaultCurrent={1}
                        current={nextPage}
                        disabled={loading}
                        onChange={onChange}
                    />
                    </ConfigProvider>
                    <Divider type='vertical' />
                    <Select
                        size='small'
                        defaultValue={`${sizePage}`}
                        disabled={loading}
                        onChange={onChangeSizePage}>
                        <Option value='10'>10 planificaciones</Option>
                        <Option value='25'>25 planificaciones</Option>
                        <Option value='50'>50 planificaciones</Option>
                        <Option value='100'>100 planificaciones</Option>
                    </Select>
                </div>
            )}
        </div>
    )
}
