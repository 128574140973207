// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax

//@ts-ignore
import { utility } from '@tdt-global/utility'
import { ProposalGeographicsModel } from './ProposalGeographicsModel'

export default class MapBoxController {
    private _map!: mapboxgl.Map
    private _mapContainer: any
    private _canvas: any
    private _markers: any[]

    get map(): mapboxgl.Map {
        return this._map
    }

    set map(value: mapboxgl.Map) {
        this._map = value
    }

    get markers(): any[] {
        return this._markers
    }

    set markers(value: any) {
        this._markers = value
    }

    get mapContainer(): any {
        return this._mapContainer
    }

    set mapContainer(value: any) {
        this._mapContainer = value
    }

    get canvas(): any {
        return this._canvas
    }

    set canvas(value: any) {
        this._canvas = value
    }

    get mapIsDefined(): boolean {
        return !!this._map
    }

    constructor() {
        mapboxgl.accessToken = utility.Config.MAP_BOX_KEY
    }

    public initMap(style: string) {
        this._map = new mapboxgl.Map({
            container: this._mapContainer,
            style,
            pitch: utility.Config.MAP_BOX_PITCH,
            zoom: utility.Config.MAP_BOX_ZOOM,
            attributionControl: false,
        })

        this._canvas = this._map.getCanvasContainer()
    }

    public addFullScreenControl() {
        if (this.mapIsDefined) {
            this.map.addControl(new mapboxgl.FullscreenControl())
        }
    }

    public addNavigationControl() {
        if (this.mapIsDefined) {
            this.map.addControl(
                new mapboxgl.NavigationControl({
                    visualizePitch: true,
                }),
            )
        }
    }

    public onLoad(callback: () => void) {
        if (this.mapIsDefined) {
            this.map.on('load', () => callback())
        }
    }

    public destroy() {
        if (this.mapIsDefined) {
            this.map.remove()
        }
    }

    public removeMarker() {
        if (this.mapIsDefined && this._markers?.length) {
            this._markers?.map((item) => item.remove())
            this._markers = []
        }
    }

    public addMarkers(points?: ProposalGeographicsModel[]) {
        if (this.mapIsDefined && points) {
            this.removeMarker()

            const bounds = new mapboxgl.LngLatBounds()
            let markers = []

            if (points.length) {
                points.map((item) => {
                    let coordinates = [
                        item.city.geolocation.lng,
                        item.city.geolocation.lat,
                    ]
                    markers.push(
                        new mapboxgl.Marker()
                            .setLngLat(coordinates)
                            .addTo(this._map),
                    )
                    bounds.extend(coordinates)
                })

                this.map.fitBounds(bounds, {
                    padding: 70,
                    maxZoom: 12,
                })
            }
            this._markers = markers
        }
    }

    // public fitBoundsByFeatureCollection(
    //     featureCollection: AssetsFeatureCollectionType,
    // ) {
    //     if (this.mapIsDefined) {
    //         const bounds = this.map.getBounds()

    //         featureCollection.features.forEach(
    //             (feature: { geometry: { coordinates: [number, number] } }) => {
    //                 const coords = feature.geometry.coordinates
    //                 bounds.extend(coords)
    //             },
    //         )

    //         this.map.fitBounds(bounds, {
    //             padding: 20,
    //         })
    //     }
    // }
}
