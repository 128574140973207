// @ts-ignore
import { Collapse, Divider } from 'antd'

//@ts-ignore
import { utility } from '@tdt-global/utility'
import { AssetsPanel } from './AssetsComponents/AssetsPanel'
import { AssetsListModel } from '../../../../../../Models/result/assetsList/AssetsListModel'
import { HeaderOptimizationModel } from '../../../../../../Models/HeaderOptimizationModel'
import { SkeletonAssestsList } from './AssetsComponents/SkeletonAssets/SkeletonAssestsList'

const { Panel } = Collapse

type PropType = {
    spin: boolean
    data: AssetsListModel
    proposal: HeaderOptimizationModel
    loadResult: () => void
    handleReplaceDrawer: (id: number) => void
}

export const AssetsList = ({
    data,
    spin,
    proposal,
    loadResult,
    handleReplaceDrawer,
}: PropType) => {
    const { Functions } = utility

    const getExtra = (otsValue: number) => {
        return (
            <div>
                {Functions.formatNumber(otsValue.toString())}{' '}
                <span className='ots-string'>OTS</span>
            </div>
        )
    }
    const getHeader = (name, subcategory, category, assetsBool) => {
        let extra = assetsBool ? '' : 'No existen soportes'

        return (
            <div>
                {name}{' '}
                <span className='category'>
                    {subcategory} / {category}
                </span>
                {extra && <span className='not-assets'>{extra}</span>}
            </div>
        )
    }

    return (
        <div className='collapse-result'>
            {spin ? (
                <SkeletonAssestsList />
            ) : (
                data?.planningElements.map((item, index: number) => (
                    <div className='callapse-item' key={index}>
                        <div className='collapse-city'>{item.city.name}</div>
                        <Collapse
                            accordion
                            bordered={false}
                            defaultActiveKey={['0']}
                            expandIconPosition={'left'}>
                            {item.elementsClassificationsList.map(
                                (elem, index: number) => (
                                    <Panel
                                        header={getHeader(
                                            elem.hierarchy?.name,
                                            elem.hierarchy.hierarchy.name,
                                            elem.hierarchy.hierarchy.hierarchy
                                                .name,
                                            !!elem.elementsList?.length,
                                        )}
                                        key={index}
                                        extra={getExtra(elem.otsEstimated)}>
                                        <AssetsPanel
                                            panel={elem}
                                            elementsClass={item}
                                            proposal={proposal}
                                            handleReplaceDrawer={
                                                handleReplaceDrawer
                                            }
                                            loadResult={loadResult}
                                        />
                                    </Panel>
                                ),
                            )}
                        </Collapse>
                    </div>
                ))
            )}
        </div>
    )
}
