import { useState } from 'react'
import { InfoCard } from './InfoCard'

type PropType = {
    cardLists: []
    load: boolean
}

export const InfoCardList = ({ cardLists, load }: PropType) => {
    return (
        <section className='info-card-list'>
            {cardLists.map((item: any, index: number) => (
                <InfoCard
                    key={index}
                    loading={load}
                    headerTitle={item.headerTitle}
                    bodyTitle1={item.bodyTitle1}
                    value1={item.value1}
                    bodyTitle2={item.bodyTitle2}
                    value2={item.value2}
                />
            ))}

            {load && (
                <>
                    <InfoCard loading={load} />
                    <InfoCard loading={load} />
                    <InfoCard loading={load} />
                    <InfoCard loading={load} />
                </>
            )}
        </section>
    )
}
