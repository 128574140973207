import { useSearchParams } from "react-router-dom";
import { Navigation } from "./Navigation/Navigation";
// @ts-ignore
import { SpinnerBar } from "@tdt-global/styleguide";
//@ts-ignore
import { utility } from "@tdt-global/utility";
import { Advertiser } from "./Steps/Advertiser/Advertiser";
import { Objective } from "./Steps/Objective/Objective";
import { Audience } from "./Steps/Audience/Audience";
import { Strategy } from "./Steps/Strategy/Strategy";
import { Details } from "./Steps/Details/Details";
import { Result } from "./Steps/Result/Result";
import { useCallback, useEffect, useState } from "react";
import { Abstract } from "./Abstract/Abstract";
import { Endpoints } from "../../../Common/Endpoints";
import { ProposalModel } from "../../../Models/ProposalModel";
import { ResponseType } from "../../../Types/ResponseType";
import { AudienceModel } from "../../../Models/AudienceModel";
import { ObjectiveModel } from "../../../Models/ObjectiveModel";
import { loaderAbstractType } from "../../../Types/LoaderAbstract";
import { InterestType } from "../../../Types/InterestType";
import { InterestModel } from "../../../Models/InterestModel";
import { AccountModel } from "../../../Models/AccountModel";
import useSWR from "swr";
import { AccountType } from "../../../Types/AccountType";
import { BudgetModel } from "../../../Models/BudgetModel";
import { CurrenciesType } from "../../../Types/CurrenciesType";
import { CurrenciesModel } from "../../../Models/CurrenciesModel";
import { FlexModel } from "../../../Models/FlexModel";
import { FlexType } from "../../../Types/FlexType";
import { ProposalGeographicsModel } from "../../../Models/ProposalGeographicsModel";
import { ProposalGeographicsType } from "../../../Types/ProposalGeographicsType";
import { StrategyModel } from "../../../Models/StrategyModel";
import { StrategyValuesType } from "../../../Types/StrategyValuesType";
import { SuggestedBudgetModel } from "../../../Models/SuggestedBudgetModel";
import { SuggestedBudgetType } from "../../../Types/SuggestedBudgetType";
import { LoadOptimization } from "./LoadOptimization";
import { PlanningsModel } from "../../../Models/PlanningsModel";
import { PreLoad } from "./Components/PreLoad";
import { SkeletonLoad } from "./Components/SkeletonLoad";

const FromStart = () => {
  const { useAxios, Functions, LocalStorage, Config } = utility;
  const {
    postHttpRequest,
    getHttpRequest,
    patchHttpRequest,
    getHttpRequestSWR,
  } = useAxios();

  let [searchParams, setSearchParams] = useSearchParams();
  const [result, setResult] = useState<ProposalModel>(new ProposalModel());
  const [spinnerCreate, setSpinnerCreate] = useState(false);
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const [spinnerAll, setSpinnerAll] = useState(false);
  const [audienceLoad, setAudienceLoad] = useState(true);
  const [budgetLoad, setBudgetLoad] = useState(true);
  const [citiesLoad, setCitiesLoad] = useState(true);
  const [loadOptimization, setLoadOptimization] = useState(false);
  const [hiddenLoadOptimization, setHiddenLoadOptimization] = useState(true);
  const [interestLoad, setInterestLoad] = useState(false);
  const [spinnerUpdateObjective, setSpinnerUpdateObjective] = useState(false);
  const [audience, setAudience] = useState(null);
  const [disabledAll, setDisabledAll] = useState(false);
  const [budgetDistributionSuggested, setBudgetDistributionSuggested] =
    useState<SuggestedBudgetModel[]>();
  const [strategySuggested, setStrategySuggested] =
    useState<StrategyValuesType>();
  const [loadProposal, setLoadProposal] = useState(false);
  const [currencies, setCurrencies] = useState<CurrenciesModel[]>([]);
  const [flex, setFlex] = useState<FlexModel[]>([]);
  const [updateStrategySpinner, setUpdateStrategySpinner] = useState(false);
  const [advertiserList, setAdvertiserList] = useState<AccountModel[]>([]);
  const [loaderAbstract, setLoaderAbstract] = useState<loaderAbstractType[]>(
    []
  );

  const [stepMax, setStepMax] = useState(0);
  const [proposalId, setProposalId] = useState<any>(null);

  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const setLoader = (key: string, loader: boolean) => {
    let obj: loaderAbstractType = {
      key,
      loader,
    };
    let array = loaderAbstract.filter((item) => item.key !== obj.key);
    array.push(obj);
    setLoaderAbstract(array);
  };
  const load = ({ url, params }) => getHttpRequestSWR(url, params);
  const { data, error }: any = useSWR(
    { url: Endpoints.GET_ADVERTISERS + "?sort[0]=name" },
    load
  );
  const setAdvertiserHandle = useCallback(() => {
    setAdvertiserList(
      data?.data.map((item: AccountType) => new AccountModel(item))
    );
  }, [data]);

  useEffect(() => {
    data && setAdvertiserHandle();
  }, [data]);

  const { data: flexData, error: flexError }: any = useSWR(
    {
      url: Endpoints.GET_FLEX_TYPE,
    },
    load
  );

  const setFlexHandle = useCallback(() => {
    setFlex(flexData?.data.map((item: FlexType) => new FlexModel(item)));
  }, [flexData]);

  useEffect(() => {
    flexData && setFlexHandle();
  }, [flexData]);

  const { data: currecyData, error: currencyError }: any = useSWR(
    {
      url: Endpoints.GET_CURRENCIES,
      params: {
        "filter[id][0]": "56",
        "filter[id][1]": "6",
        "filter[id][2]": "76",
      },
    },
    load
  );

  const setCurrenciesHandle = useCallback(() => {
    setCurrencies(
      currecyData?.data.map((item: CurrenciesType) => new CurrenciesModel(item))
    );
  }, [currecyData]);

  useEffect(() => {
    currecyData && setCurrenciesHandle();
  }, [currecyData]);

  useEffect(() => {
    setProposalId(searchParams.get("id"));
  }, [searchParams]);

  //Principal Load

  useEffect(() => {
    if (searchParams.get("id") !== null && searchParams.get("id") !== "null") {
      setAudienceLoad(true);
      setInterestLoad(true);
      setSpinnerLoad(true);
      setLoadProposal(true);
      setSpinnerAll(true);
      getHttpRequest(
        Endpoints.GET_PLANIFICATIONS,
        {
          "filter[id][0]": Number(searchParams.get("id")),
          include: [
            "type",
            "status",
            "objectives",
            "account",
            "account.logo",
            "audience",
            "audience.socioeconomics",
            "strategy",
          ],
        },
        (response) => {
          let proposal = new ProposalModel(
            response.data.data[0],
            response.data.included
          );

          if (proposal.audience_id) {
            getHttpRequest(
              Endpoints.GET_AUDIENCE,
              {
                "filter[id][0]": proposal.audience_id,
                include: ["account", "gender", "socioeconomics", "interests"],
              },
              (response: ResponseType) => {
                setAudienceLoad(false);
                let audience = new AudienceModel(
                  response.data.data[0],
                  response.data.included
                );
                proposal.audience = audience;

                getHttpRequest(
                  Endpoints.GET_INTERESTS_BY_AUDIENCE.replace(
                    "{id}",
                    proposal.audience_id.toString()
                  ),
                  {},
                  (response) => {
                    setInterestLoad(false);
                    proposal.interest = response?.data.data.map(
                      (item: InterestType) => new InterestModel(item)
                    );
                    changeResultWithOutSave(proposal);
                    getMaxStepFromProposal(proposal);
                    searchParams.get("tab") === "new" &&
                      changeStepWhenEdit(proposal);
                    setLoadProposal(false);
                    setSpinnerLoad(false);
                    handleStrategySuggested();

                    !proposal.strategy && setSpinnerAll(false);
                  },
                  (error) => {
                    Functions.openNotificationError(
                      Functions.errorObject(error),
                      "Error"
                    );
                    setInterestLoad(false);
                    setSpinnerLoad(false);
                  },
                  () => {}
                );
              },
              (error) => {
                Functions.openNotificationError(
                  Functions.errorObject(error),
                  "Error"
                );
                setAudienceLoad(false);
                setSpinnerLoad(false);
              },
              () => {}
            );
          }
        },
        (error) => {
          Functions.openNotificationError(
            Functions.errorObject(error),
            "Error"
          );
          setAudienceLoad(false);
          setSpinnerLoad(false);
        },
        () => {}
      );
    } else {
      let account = JSON.parse(LocalStorage.getAdvertiser());
      if (account) {
        result.account = account;
        setResult(result);
        setStepMaxHandle(1);
      }
    }
  }, []);

  useEffect(() => {
    if (proposalId) {
      setSearchParams({
        option: searchParams.get("option"),
        status: searchParams.get("status"),
        id: proposalId.toString(),
        tab: searchParams.get("tab"),
      });
      result.id = proposalId;
      setResult(result);
    }
  }, [proposalId]);

  useEffect(() => {
    if (audience) {
      setAudienceLoad(true);
      getHttpRequest(
        Endpoints.GET_AUDIENCE,
        {
          "filter[id][0]": result.audience_id,
          include: ["account", "gender", "socioeconomics", "interests"],
        },
        (response: ResponseType) => {
          setAudienceLoad(false);
          let audience = new AudienceModel(
            response.data.data[0],
            response.data.included
          );
          result.audience = audience;
          changeResultWithOutSave(result);
        },
        (error) => {
          Functions.openNotificationError(
            Functions.errorObject(error),
            "Error"
          );
          setAudienceLoad(false);
        },
        () => {}
      );
    }
  }, [audience]);

  useEffect(() => {
    if (!budgetLoad && !citiesLoad && spinnerAll) {
      setSpinnerAll(false);
    }
  }, [budgetLoad, citiesLoad, spinnerAll]);

  useEffect(() => {
    if (result.strategy) {
      if (!result.budget && getMaxStepFromProposal(result) !== 3) {
        setBudgetLoad(true);
        getHttpRequest(
          Endpoints.GET_BUDGET_BY_PROPOSAL_ID,
          {
            "filter[proposal][0]": result.id,
            include: ["flexType", "currency"],
          },
          (response: ResponseType) => {
            setBudgetLoad(false);
            let budget = new BudgetModel(
              response.data.data[0],
              response.data.included
            );
            result.budget = budget;
            changeResultWithOutSave(result);
          },
          (error) => {
            Functions.openNotificationError(
              Functions.errorObject(error),
              "Error"
            );
            setBudgetLoad(false);
          },
          () => {}
        );
      } else {
        setBudgetLoad(false);
      }

      if (!result.proposalGeographics && getMaxStepFromProposal(result) !== 3) {
        loadCitiesProposal();
        handleBudgetDistributionSuggested();
      } else {
        setCitiesLoad(false);
      }
    }
  }, [result.strategy]);

  const handleBudgetDistributionSuggested = () => {
    getHttpRequest(
      Endpoints.GET_SUGGESTED_BUDGET_BY_PROPOSAL.replace(
        "{id}",
        result.id.toString()
      ),
      {},
      (response) => {
        let suggestedBudget: SuggestedBudgetModel[] = response?.data.data.map(
          (item: SuggestedBudgetType) => new SuggestedBudgetModel(item)
        );

        setBudgetDistributionSuggested(suggestedBudget);
      },
      (error) => {
        Functions.openNotificationError(Functions.errorObject(error), "Error");
      },
      () => {}
    );
  };

  const handleStrategySuggested = () => {
    getHttpRequest(
      Endpoints.GET_STRATEGY_BY_PROPOSAL_ID.replace(
        "{id}",
        result.id.toString()
      ),
      {},
      (response) => {
        const {
          frequency,
          coverage,
          segmentation_high,
          segmentation_low,
          impact,
        } = response.data.data;

        let segmentation = 0;
        let valuePost: any = {
          impact: impact,
          coverage: coverage,
          frequency: frequency,
        };
        if (segmentation_high > segmentation_low) {
          valuePost.segmentation_high = segmentation_high;
          segmentation = segmentation_high;
        } else if (segmentation_high < segmentation_low) {
          valuePost.segmentation_low = segmentation_low;
          segmentation = segmentation_low;
        } else {
          valuePost.segmentation_low = segmentation_low;
          valuePost.segmentation_high = segmentation_high;
        }

        let value = {
          impact: impact,
          segmentation: segmentation,
          coverage: coverage,
          frequency: frequency,
        };

        setStrategySuggested(value);
      },
      (error) => {
        Functions.openNotificationError(Functions.errorObject(error), "Error");
      },
      () => {}
    );
  };

  const handleRefreshStrategy = () => {
    setUpdateStrategySpinner(true);
    getHttpRequest(
      Endpoints.GET_STRATEGY_BY_PROPOSAL_ID.replace(
        "{id}",
        result.id.toString()
      ),
      {},
      (response) => {
        const {
          frequency,
          coverage,
          segmentation_high,
          segmentation_low,
          impact,
        } = response.data.data;

        let segmentation = 0;
        let valuePost: any = {
          impact: impact,
          coverage: coverage,
          frequency: frequency,
        };
        if (segmentation_high > segmentation_low) {
          valuePost.segmentation_high = segmentation_high;
          segmentation = segmentation_high;
        } else if (segmentation_high < segmentation_low) {
          valuePost.segmentation_low = segmentation_low;
          segmentation = segmentation_low;
        } else {
          valuePost.segmentation_low = segmentation_low;
          valuePost.segmentation_high = segmentation_high;
        }

        let value = {
          impact: impact,
          segmentation: segmentation,
          coverage: coverage,
          frequency: frequency,
        };

        setStrategySuggested(value);

        getHttpRequest(
          Endpoints.GET_DESCRIPTION_STRATEGY,
          { ...value },
          (response) => {
            let description = response.data.data.description;

            patchHttpRequest(
              Endpoints.PATCH_STRATEGY_BY_ID.replace(
                "{id}",
                result.strategy.id.toString()
              ),
              {
                data: {
                  id: result.strategy.id,
                  type: "proposal_strategies",
                  attributes: {
                    ...valuePost,
                    description: description,
                  },
                },
              },
              (response) => {
                let strategy = new StrategyModel(response.data.data);
                result.strategy = strategy;
                changeResultWithOutSave(result);
                setUpdateStrategySpinner(false);
                setLoader("strategy", false);
              },
              (error) => {
                Functions.openNotificationError(
                  Functions.errorObject(error),
                  "Error"
                );
                setLoader("strategy", false);
              },
              () => {}
            );
          },
          (error) => {
            Functions.openNotificationError(
              Functions.errorObject(error),
              "Error"
            );
          },
          () => {}
        );
      },
      (error) => {
        Functions.openNotificationError(Functions.errorObject(error), "Error");
      },
      () => {}
    );
  };

  const loadCitiesProposal = () => {
    setLoader("cities", true);
    setCitiesLoad(true);
    getHttpRequest(
      Endpoints.GET_PROPOSAL_GEOGRAPHICS_BY_PROPOSAL.replace(
        "{id}",
        result.id.toString()
      ),
      {
        sort: ["-budget"],
        include: ["city.state", "city.department", "city"],
      },
      (response: ResponseType) => {
        let proposalGeographics = response?.data.data.map(
          (item: ProposalGeographicsType) =>
            new ProposalGeographicsModel(item, response.data.included)
        );
        result.proposalGeographics = proposalGeographics;
        changeResultWithOutSave(result);
        setCitiesLoad(false);
        setLoader("cities", false);
      },
      (error) => {
        Functions.openNotificationError(Functions.errorObject(error), "Error");
        setLoader("cities", false);
      },
      () => {}
    );
  };

  const changeStepWhenEdit = (proposal: ProposalModel) => {
    if (searchParams.get("status") === "edit") {
      let max = getMaxStepFromProposal(proposal);
      setSearchParams({
        option: searchParams.get("option"),
        status: searchParams.get("status"),
        id: searchParams.get("id"),
        tab:
          searchParams.get("tab") !== max.toString()
            ? max.toString()
            : searchParams.get("tab"),
      });
    }
  };

  const setStepMaxHandle = (step: number) => {
    if (step > stepMax) {
      setStepMax(step);
    }
  };

  const getMaxStepFromProposal = (proposal: ProposalModel) => {
    if (proposal.planningsId) {
      setStepMax(5);
      return 5;
    } else if (proposal.name && proposal.start_date) {
      setStepMax(4);
      return 4;
    } else if (proposal.strategy) {
      setStepMax(3);
      return 3;
    } else if (proposal.audience) {
      setStepMax(2);
      return 2;
    } else if (proposal.objectives) {
      setStepMax(1);
      return 1;
    }
  };

  const setParams = (str: number) => {
    let params: any = { tab: str.toString() };
    if (searchParams.get("option")) {
      params.option = searchParams.get("option");
    }
    if (searchParams.get("id")) {
      params.id = searchParams.get("id");
    }
    if (searchParams.get("status")) {
      params.status = searchParams.get("status");
    }

    setSearchParams(params);
  };

  const changeResultWithOutSave = (proposal) => {
    setResult(proposal);
    forceUpdate();
  };

  const finishAdvertiser = () => {
    nextStep();
    setStepMaxHandle(1);
  };

  const finishAudience = () => {
    nextStep();
    setStepMaxHandle(3);
  };

  const finishStrategy = () => {
    nextStep();
    setStepMaxHandle(4);
  };

  const getOptimization = () => {
    setLoadOptimization(true);

    createPlannings();
  };

  const createPlannings = () => {
    postHttpRequest(
      Endpoints.POST_PLANNINGS,
      {
        data: {
          type: "plannings",
          attributes: {
            proposal_id: result.id,
          },
        },
      },
      (response: ResponseType) => {
        let plannings = new PlanningsModel(response.data.data);

        patchHttpRequest(
          Endpoints.PATCH_PROPOSAL_BY_ID.replace("{id}", proposalId.toString()),
          {
            data: {
              id: proposalId,
              type: "proposals",
              attributes: {
                planning_id: plannings.id,
              },
            },
          },
          (response: ResponseType) => {
            result.planningsId = plannings.id;
            changeResultWithOutSave(result);
            setLoadOptimization(false);
            nextStep();
            setStepMaxHandle(5);
          },
          (error) => {
            Functions.openNotificationError(
              Functions.errorObject(error),
              "Error"
            );
          },
          () => {}
        );
      },
      (error) => {
        Functions.openNotificationError(Functions.errorObject(error), "Error");
      },
      () => {},
      // { baseURL: 'https://api.planning.dev.tolber.io/api/' },
      { baseURL: Config.END_POINT_PLANNING + "api/" }
    );
  };

  const onChangeDetail = (data) => {
    let param: any = {};

    if (data.key === "dates") {
      if (data.value.length) {
        param.start_date = data.value[0]?.format("YYYY-MM-DD");
        param.finish_date = data.value[1]?.format("YYYY-MM-DD");
      } else {
        param.start_date = "";
        param.finish_date = "";
      }
      saveData(param, {}, true, data.key);
    } else {
      param.name = data.value;
      saveData(param, {}, true, "campaignName");
    }
  };

  const updateProposalDetail = (data) => {
    let param: any = {};
    if (data.dates) {
      if (data.dates.length) {
        param.start_date = data.dates[0]?.format("YYYY-MM-DD");
        param.finish_date = data.dates[1]?.format("YYYY-MM-DD");
      } else {
        param.start_date = "";
        param.finish_date = "";
      }
    }

    param.name = data.name;
    saveData(param, {}, true, "", false);
  };

  const onChangeResult = (data: any) => {
    let param = {};
    let relationships = {};
    if (data.account) {
      if (!result?.account?.id) {
        nextStep();
        setStepMaxHandle(1);
      } else {
        forceUpdate();
      }
      param = {
        account_id: data.account.id,
      };
      result.account = data.account;
      setResult(result);
    }

    if (data.objective) {
      if (data.next) {
        nextStep();
        setStepMaxHandle(2);
      } else {
        forceUpdate();
      }

      let objective: ObjectiveModel = data.objective.objective;
      objective.primary = true;

      let restObjectives: ObjectiveModel[] = data.objective?.subObjective
        ? data.objective?.subObjective?.map((item) => {
            item.primary = false;
            return item;
          })
        : [];

      let objectiveParam = {
        type: data.objective.objective.type,
        id: data.objective.objective.id,
        primary: true,
      };

      let restObjectivesParam = data.objective?.subObjective
        ? data.objective?.subObjective?.map((item) => ({
            type: item.type,
            id: item.id,
            primary: false,
          }))
        : [];
      relationships = {
        objectives: {
          data: [objectiveParam, ...restObjectivesParam],
        },
      };
      let newResult = result;
      newResult.objectives = [objective, ...restObjectives];
      setResult(newResult);
    }
    if (!proposalId && data.account) {
      LocalStorage.setAdvertiser(JSON.stringify(data.account.accountObject));
    } else saveData(param, relationships, data.next);
  };

  const saveData = (
    param,
    relationships?,
    next = true,
    updateAbstract = "",
    showAlert = true
  ) => {
    if (proposalId) {
      // update
      let params = {
        id: proposalId,
        type: "proposals",
        attributes: {
          proposal_type_id: 1,
          ...param,
        },
        relationships: { ...relationships },
      };
      !next && setSpinnerUpdateObjective(true);
      updateAbstract !== "" && setLoader(updateAbstract, true);

      patchHttpRequest(
        Endpoints.PATCH_PROPOSAL_BY_ID.replace("{id}", proposalId.toString()),
        {
          data: params,
        },
        (response: ResponseType) => {
          !next && setSpinnerUpdateObjective(false);

          if (param.start_date && param.finish_date) {
            result.start_date = param.start_date;
            result.finish_date = param.finish_date;
          }
          if (param.name) {
            result.name = param.name;
          }

          if (result.strategy && params.relationships.objectives) {
            handleRefreshStrategy();
          }
          changeResultWithOutSave(result);
          setLoader(updateAbstract, false);
          setLoader("campaignName", false);
          setLoader("dates", false);
          setDisabledAll(false);
          if (showAlert) {
            Functions.openAlertSuccess("Se han guardado todos los cambios");
          }
        },
        (error) => {
          Functions.openNotificationError(
            Functions.errorObject(error),
            "Error"
          );
          !next && setSpinnerUpdateObjective(false);
        },
        () => {}
      );
    } else {
      // post
      setSpinnerCreate(true);
      let params = {
        type: "proposals",
        attributes: {
          proposal_type_id: 1,
          proposal_status_id: 1,
          account_id: result?.account?.id,
        },
        relationships: { ...relationships },
      };

      let paramsAudience = {
        type: "audiences",
        attributes: {
          account_id: result?.account?.id,
          name: "",
          age: {
            start: 15,
            end: 44,
          },
          gender_id: 3,
        },
        relationships: {
          socioeconomics: {
            data: [
              { type: "socioeconomics", id: 2 },
              { type: "socioeconomics", id: 3 },
            ],
          },
          interests: {
            data: [],
          },
        },
      };

      postHttpRequest(
        Endpoints.POST_PROPOSAL,
        { data: params },
        (response: ResponseType) => {
          let proposal = new ProposalModel(
            response.data.data,
            response.data.included
          );
          setProposalId(proposal.id);
          postHttpRequest(
            Endpoints.POST_AUDIENCE,
            { data: paramsAudience },
            (response: ResponseType) => {
              let audience = new AudienceModel(response.data.data);
              setAudience(audience);
              result.audience = audience;
              result.audience_id = audience.id;
              changeResultWithOutSave(result);
              patchHttpRequest(
                Endpoints.PATCH_PROPOSAL_BY_ID.replace(
                  "{id}",
                  proposal.id.toString()
                ),
                {
                  data: {
                    id: proposal.id,
                    type: "proposals",
                    attributes: {
                      audience_id: audience.id,
                    },
                  },
                },
                (response: ResponseType) => {
                  setSpinnerCreate(false);
                  setDisabledAll(false);
                },
                (error) => {
                  Functions.openNotificationError(
                    Functions.errorObject(error),
                    "Error"
                  );
                  setSpinnerCreate(false);
                },
                () => {}
              );
            },
            (error) => {
              Functions.openNotificationError(
                Functions.errorObject(error),
                "Error"
              );
              setSpinnerCreate(false);
            },
            () => {}
          );
        },
        (error) => {
          Functions.openNotificationError(
            Functions.errorObject(error),
            "Error"
          );
          setSpinnerCreate(false);
        },
        () => {}
      );
    }
  };

  const nextStep = () => {
    setParams(Number(searchParams.get("tab")) + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [searchParams]);

  useEffect(() => {
    if (!loadOptimization) {
      setTimeout(() => {
        setHiddenLoadOptimization(true);
      }, 600);
    } else {
      setHiddenLoadOptimization(false);
    }
  }, [loadOptimization]);

  const RenderContent = () => {
    switch (Number(searchParams.get("tab"))) {
      case 0:
        return (
          <Advertiser
            spinnerAccountList={!!data}
            advertiserList={advertiserList}
            spinnerLoad={spinnerLoad}
            proposal={result}
            onChangeResult={onChangeResult}
            finishAdvertiser={finishAdvertiser}
          />
        );
        break;
      case 1:
        return (
          <Objective
            spinnerLoad={spinnerLoad}
            loadUpdateObjective={spinnerUpdateObjective}
            onChangeResult={onChangeResult}
            spinnerCreate={spinnerCreate}
            proposal={result}
          />
        );
        break;
      case 2:
        return (
          <Audience
            proposal={result}
            audienceLoad={audienceLoad}
            interestLoad={interestLoad}
            loaderAbstract={loaderAbstract}
            handleRefreshStrategy={handleRefreshStrategy}
            finishAudience={finishAudience}
            setLoaderAbstract={setLoader}
            changeResultWithOutSave={changeResultWithOutSave}
          />
        );
        break;
      case 3:
        return (
          <Strategy
            proposal={result}
            updateStrategySpinner={updateStrategySpinner}
            strategySuggested={strategySuggested}
            setStrategySuggested={setStrategySuggested}
            setLoaderAbstract={setLoader}
            finishStrategy={finishStrategy}
            changeResultWithOutSave={changeResultWithOutSave}
          />
        );
        break;
      case 4:
        return (
          <Details
            proposal={result}
            currencies={currencies}
            loaderAbstract={loaderAbstract}
            flex={flex}
            budgetDistributionSuggested={budgetDistributionSuggested}
            disabledAll={disabledAll}
            setDisabledAll={setDisabledAll}
            setBudgetDistributionSuggested={setBudgetDistributionSuggested}
            setLoaderAbstract={setLoader}
            getOptimization={getOptimization}
            updateProposalDetail={updateProposalDetail}
            onChangeDetail={onChangeDetail}
            changeResultWithOutSave={changeResultWithOutSave}
          />
        );
        break;
      case 5:
        return <Result proposal={result} />;
        break;

      default:
        break;
    }
  };

  return (
    <>
      {loadProposal ? (
        <PreLoad />
      ) : spinnerAll ? (
        <SkeletonLoad />
      ) : (
        <>
          {!hiddenLoadOptimization && (
            <LoadOptimization
              loadOptimization={loadOptimization}
              setLoadOptimization={setLoadOptimization}
            />
          )}
          {!loadOptimization && (
            <div className="from-start">
              <div className="navigation">
                <Navigation
                  searchParams={searchParams}
                  setParams={setParams}
                  stepMax={stepMax}
                  proposal={result}
                />
              </div>
              <div className="content">{RenderContent()}</div>

              {Number(searchParams.get("tab")) !== 5 && (
                <div className="abstract">
                  <SpinnerBar loading={spinnerLoad || spinnerCreate}>
                    <div className="container">
                      <Abstract
                        result={result}
                        flex={flex}
                        currencies={currencies}
                        loaderAbstract={loaderAbstract}
                      />
                    </div>
                  </SpinnerBar>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};
export default FromStart;
