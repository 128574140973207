import { PlanningElementClassificationsType } from '../../../Types/result/mix/PlanningElementClassificationsType'
import { MainActionModel } from '../../MainActionModel'
import { PlanningElementModel } from '../assetsList/PlanningElementModel'
import { HierarchyModel } from './HierarchyModel'

export class PlanningElementClassificationsModel {
    private _id: number
    private _type: string
    private _planningId: number
    private _planningGeographicId: number
    private _hierarchyId: number
    private _budgetEstimated: number
    private _budget: number
    private _otsEstimated: number
    private _ots: number
    private _cpmEstimated: number
    private _cpm: number
    private _totalElements: number
    private _hierarchy: HierarchyModel
    private _mainActionId: number
    private _mainAction: MainActionModel
    private _elementsList: PlanningElementModel[]

    constructor(planElem: PlanningElementClassificationsType, included: any) {
        this._id = planElem.id
        this._type = planElem.type
        this._planningId = planElem.attributes.planning_id
        this._planningGeographicId = planElem.attributes.planning_geographic_id
        this._hierarchyId = planElem.attributes.hierarchy_id
        this._budgetEstimated = planElem.attributes.budget_estimated
        this._budget = planElem.attributes.budget
        this._otsEstimated = planElem.attributes.ots_estimated
        this._ots = planElem.attributes.ots
        this._cpmEstimated = planElem.attributes.cpm_estimated
        this._cpm = planElem.attributes.cpm
        this._mainActionId = planElem.attributes.main_action_id
        this._totalElements = planElem.attributes.total_elements

        if (included) {
            this._hierarchy = new HierarchyModel(
                included.find(
                    (item) =>
                        item.id ===
                            planElem.relationships.hierarchy?.data?.id &&
                        item.type ===
                            planElem.relationships.hierarchy?.data?.type,
                ),
                included,
            )
        }

        if (included && planElem.relationships?.main_action?.data) {
            this._mainAction = new MainActionModel(
                included.find(
                    (item) =>
                        item.id ===
                            planElem.relationships?.main_action?.data?.id &&
                        item.type ===
                            planElem.relationships?.main_action?.data?.type,
                ),
            )
        }

        if (included && planElem.relationships?.elements?.data.length) {
            let elemList = []
            planElem.relationships?.elements?.data.map((item) => {
                included.find((elem) => {
                    if (elem.id === item.id && elem.type === item.type) {
                        elemList.push(new PlanningElementModel(elem, included))
                        return true
                    } else return false
                })
            })
            this._elementsList = elemList
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get mainActionId(): number {
        return this._mainActionId
    }

    set mainActionId(value) {
        this._mainActionId = value
    }

    get mainAction(): MainActionModel {
        return this._mainAction
    }

    set mainAction(value) {
        this._mainAction = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get planningId(): number {
        return this._planningId
    }
    set planningId(value: number) {
        this._planningId = value
    }

    get planningGeographicId(): number {
        return this._planningGeographicId
    }
    set planningGeographicId(value: number) {
        this._planningGeographicId = value
    }

    get hierarchyId(): number {
        return this._hierarchyId
    }
    set hierarchyId(value: number) {
        this._hierarchyId = value
    }
    get budgetEstimated(): number {
        return this._budgetEstimated
    }
    set budgetEstimated(value: number) {
        this._budgetEstimated = value
    }
    get budget(): number {
        return this._budget
    }
    set budget(value: number) {
        this._budget = value
    }
    get otsEstimated(): number {
        return this._otsEstimated
    }
    set otsEstimated(value: number) {
        this._otsEstimated = value
    }
    get ots(): number {
        return this._ots
    }
    set ots(value: number) {
        this._ots = value
    }
    get cpmEstimated(): number {
        return this._cpmEstimated
    }
    set cpmEstimated(value: number) {
        this._cpmEstimated = value
    }
    get cpm(): number {
        return this._cpm
    }
    set cpm(value: number) {
        this._cpm = value
    }
    get totalElements(): number {
        return this._totalElements
    }
    set totalElements(value: number) {
        this._totalElements = value
    }
    get hierarchy(): HierarchyModel {
        return this._hierarchy
    }
    set hierarchy(value: HierarchyModel) {
        this._hierarchy = value
    }

    get elementsList(): PlanningElementModel[] {
        return this._elementsList
    }
    set elementsList(value: PlanningElementModel[]) {
        this._elementsList = value
    }
}
