import { AddressType } from '../Types/AddressType'
import { GeolocationType } from '../Types/GeolocationType'

export class AddressModel {
    private _id: number
    private _type: string
    private _cityId: number
    private _address: string
    private _street: string | null
    private _number: number | null
    private _vertexStreet: string | null
    private _vertexStreet2: string | null
    private _district: string | null
    private _zone: string | null
    private _zipCode: string | null
    private _geolocation: GeolocationType
    private _addressableType: string | null
    private _addressableId: number | null

    constructor(elem: AddressType) {
        this._id = elem.id
        this._type = elem.type
        this._cityId = elem.attributes.city_id
        this._address = elem.attributes.address
        this._street = elem.attributes.street
        this._number = elem.attributes.number
        this._vertexStreet = elem.attributes.vertex_street
        this._vertexStreet2 = elem.attributes.vertex_street2
        this._district = elem.attributes.district
        this._zone = elem.attributes.zone
        this._zipCode = elem.attributes.zip_code
        this._geolocation = elem.attributes.geolocation
        this._addressableType = elem.attributes.addressable_type
        this._addressableId = elem.attributes.addressable_id
    }

    get street(): string | null {
        return this._street
    }

    set street(value: string | null) {
        this._street = value
    }

    get id(): number {
        return this._id
    }

    set id(value: number) {
        this._id = value
    }

    get cityId(): number {
        return this._cityId
    }

    set cityId(value: number) {
        this._cityId = value
    }

    get number(): number | null {
        return this._number
    }

    set number(value: number | null) {
        this._number = value
    }

    get vertexStreet(): string | null {
        return this._vertexStreet
    }

    set vertexStreet(value: string | null) {
        this._vertexStreet = value
    }

    get type(): string {
        return this._type
    }

    set type(value: string) {
        this._type = value
    }

    get address(): string {
        return this._address
    }

    set address(value: string) {
        this._address = value
    }

    get vertexStreet2(): string | null {
        return this._vertexStreet2
    }

    set vertexStreet2(value: string | null) {
        this._vertexStreet2 = value
    }

    get zone(): string | null {
        return this._zone
    }

    set zone(value: string | null) {
        this._zone = value
    }

    get zipCode(): string | null {
        return this._zipCode
    }

    set zipCode(value: string | null) {
        this._zipCode = value
    }

    get geolocation(): GeolocationType | null {
        return this._geolocation
    }

    set geolocation(value: GeolocationType | null) {
        this._geolocation = value
    }

    get addressableType(): any | null {
        return this._addressableType
    }

    set addressableType(value: string | null) {
        this._addressableType = value
    }

    get district(): string | null {
        return this._district
    }

    set district(value: string | null) {
        this._district = value
    }

    get addressableId(): number | null {
        return this._addressableId
    }

    set addressableId(value: number | null) {
        this._addressableId = value
    }
}
