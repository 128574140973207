// @ts-ignore
import { Button } from '@tdt-global/styleguide'
import { ArrowRightOutlined } from '@ant-design/icons'
import { ProposalModel } from '../../../../../Models/ProposalModel'

type PropType = {
    changeValues: boolean
    spinnerCreate: boolean
    active: boolean
    proposal: ProposalModel
    onNext: () => void
}
export const Footer = ({
    onNext,
    proposal,
    changeValues,
    active,
    spinnerCreate,
}: PropType) => {
    return (
        <>
            <Button loading={spinnerCreate} onClick={onNext} type={'primary'}>
                {proposal.objectives.length
                    ? !active
                        ? 'Modificar objetivo'
                        : changeValues
                        ? 'Modificar objetivo'
                        : 'Siguiente'
                    : 'Siguiente'}
                <ArrowRightOutlined />
            </Button>
            {!proposal.objectives.length && (
                <span>Próximo paso, define la audiencia</span>
            )}
        </>
    )
}
