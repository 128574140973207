export const localeES: any = {
    lang: {
        locale: 'es_ES',
        placeholder: 'Seleccionar fecha',
        rangePlaceholder: ['Fecha inicial', 'Fecha final'],
        today: 'Hoy',
        now: 'Ahora',
        backToToday: 'Volver a hoy',
        ok: 'OK',
        clear: 'Limpiar',
        month: 'Mes',
        year: 'Año',
        timeSelect: 'Selecciona hora',
        dateSelect: 'Selecciona fecha',
        monthSelect: 'Elige un mes',
        yearSelect: 'Elige un año',
        decadeSelect: 'Elige una década',
        yearFormat: 'YYYY',
        dateFormat: 'M/D/YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'M/D/YYYY HH:mm:ss',
        monthFormat: 'MMMM',
        monthBeforeYear: true,
        previousMonth: 'Mes anterior (Página anterior)',
        nextMonth: 'El próximo mes (Página siguiente)',
        previousYear: 'Último año (Control + izquierda)',
        nextYear: 'Próximo año (Control + derecha)',
        previousDecade: 'Última década',
        nextDecade: 'Próxima década',
        previousCentury: 'Last century',
        nextCentury: 'Next century',
    },
    timePickerLocale: {
        placeholder: 'Seleccionar hora',
    },
    dateFormat: 'YYYY-MM-DD',
    dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
    weekFormat: 'YYYY-wo',
    monthFormat: 'YYYY-MM',
}
