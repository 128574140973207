import { ProposalModel } from '../../../../../Models/ProposalModel'
import { TabsHeader } from '../TabsHeader'
import {
    ArrowRightOutlined,
    InfoCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons'
import * as Highcharts from 'highcharts'
import HighchartMore from 'highcharts/highcharts-more'
import { RadarChart } from '../../Components/RadarChart'
// @ts-ignore
import { Button } from '@tdt-global/styleguide'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { Box } from '../Box'
import { useCallback, useEffect, useState } from 'react'
// @ts-ignore
import { Skeleton, Popover } from 'antd'
import { Endpoints } from '../../../../../Common/Endpoints'
import { StrategyModel } from '../../../../../Models/StrategyModel'
import { StrategyPoint } from './StrategyPoint'
import { StrategyValuesType } from '../../../../../Types/StrategyValuesType'
HighchartMore(Highcharts)

type PropType = {
    proposal: ProposalModel
    updateStrategySpinner: boolean
    strategySuggested: StrategyValuesType
    finishStrategy: () => void
    setStrategySuggested: (strategy: StrategyValuesType) => void
    setLoaderAbstract: (key: string, loader: boolean) => void
    changeResultWithOutSave: (proposal: ProposalModel) => void
}

export const Strategy = ({
    proposal,
    updateStrategySpinner,
    strategySuggested,
    setStrategySuggested,
    finishStrategy,
    setLoaderAbstract,
    changeResultWithOutSave,
}: PropType) => {
    const { useAxios, Functions } = utility
    const { postHttpRequest, patchHttpRequest, getHttpRequest } = useAxios()
    const [updateSpinner, setUpdateSpinner] = useState(false)
    const [refreshDisabled, setRefreshDisabled] = useState(true)
    const [dataGraph, setDataGraph] = useState([])
    const [visible, setVisible] = useState(false)
    const [value, setValue] = useState<StrategyValuesType>(null)
    const [createSpinner, setCreateSpinner] = useState(false)

    let categories = [
        'Producir impacto',
        'Especificar segmentación',
        'Buscar cobertura',
        'Buscar frecuencia',
    ]

    useEffect(() => {
        if (updateStrategySpinner) {
            setValue({
                impact: null,
                segmentation: null,
                coverage: null,
                frequency: null,
            })
            setUpdateSpinner(true)
            setLoaderAbstract('strategy', true)
        } else {
            setLoaderAbstract('strategy', false)
            setUpdateSpinner(false)
        }
    }, [updateStrategySpinner])

    useEffect(() => {
        if (strategySuggested && value) {
            setRefreshDisabled(
                Functions.compareTwoObjects(value, strategySuggested),
            )
            setDataGraph(dataForGraph)
        }
    }, [strategySuggested, value])

    const handleVisibleChange = (visible) => {
        setVisible(visible)
    }

    const dataForGraph = useCallback(() => {
        const text = {
            impact: 'Producir impacto',
            segmentation: 'Especificar segmentación',
            coverage: 'Buscar cobertura',
            frequency: 'Buscar frecuencia',
        }
        let element = []
        let elementSuggest = []
        for (const key in text) {
            if (
                Object.prototype.hasOwnProperty.call(value, key) &&
                Object.prototype.hasOwnProperty.call(strategySuggested, key)
            ) {
                element = [...element, value[key]]
                elementSuggest = [...elementSuggest, strategySuggested[key]]
            }
        }
        let dataValue = {
            type: 'area',
            name: 'Tú',
            data: element,
            pointPlacement: 'on',
            color: '#5B8FF9',
        }
        let dataSuggest = {
            type: 'area',
            name: 'Planning',
            data: elementSuggest,
            pointPlacement: 'on',
            color: '#61DDAA',
        }
        return [dataValue, dataSuggest]
    }, [strategySuggested, value])

    const createStrategy = () => {
        setCreateSpinner(true)
        getHttpRequest(
            Endpoints.GET_STRATEGY_BY_PROPOSAL_ID.replace(
                '{id}',
                proposal.id.toString(),
            ),
            {},
            (response) => {
                const {
                    frequency,
                    coverage,
                    segmentation_high,
                    segmentation_low,
                    impact,
                } = response.data.data

                let segmentation = 0
                let valuePost: any = {
                    impact: impact,
                    coverage: coverage,
                    frequency: frequency,
                }

                if (segmentation_high > segmentation_low) {
                    valuePost.segmentation_high = segmentation_high
                    segmentation = segmentation_high
                } else if (segmentation_high < segmentation_low) {
                    valuePost.segmentation_low = segmentation_low
                    segmentation = segmentation_low
                } else {
                    valuePost.segmentation_low = segmentation_low
                    valuePost.segmentation_high = segmentation_high
                }

                let value = {
                    impact: impact,
                    segmentation: segmentation,
                    coverage: coverage,
                    frequency: frequency,
                }
                getHttpRequest(
                    Endpoints.GET_DESCRIPTION_STRATEGY,
                    { ...value },
                    (response) => {
                        let description = response.data.data.description

                        postHttpRequest(
                            Endpoints.POST_STRATEGY,
                            {
                                data: {
                                    type: 'proposal_strategies',
                                    attributes: {
                                        proposal_id: proposal.id,
                                        ...valuePost,
                                        description: description,
                                    },
                                },
                            },
                            (response) => {
                                let strategy = new StrategyModel(
                                    response.data.data,
                                )
                                setValue({
                                    coverage: coverage,
                                    frequency: frequency,
                                    impact: impact,
                                    segmentation: segmentation,
                                })
                                setStrategySuggested(value)
                                proposal.strategy = strategy
                                changeResultWithOutSave(proposal)
                                setCreateSpinner(false)
                            },
                            (error) => {
                                Functions.openNotificationError(
                                    Functions.errorObject(error),
                                    'Error',
                                )
                                setCreateSpinner(false)
                            },
                            () => {},
                        )
                    },
                    (error) => {
                        Functions.openNotificationError(
                            Functions.errorObject(error),
                            'Error',
                        )
                        setCreateSpinner(false)
                    },
                    () => {},
                )
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
                setCreateSpinner(false)
            },
            () => {},
        )
    }

    useEffect(() => {
        if (proposal.id && !proposal.strategy) {
            createStrategy()
        }
        if (proposal.strategy) {
            const {
                coverage,
                frequency,
                impact,
                segmentation_high,
                segmentation_low,
            } = proposal.strategy

            let value: any = {
                coverage: coverage,
                frequency: frequency,
                impact: impact,
            }
            if (segmentation_high > segmentation_low) {
                value.segmentation = segmentation_high
            } else if (segmentation_high < segmentation_low) {
                value.segmentation = segmentation_low
            } else {
                value.segmentation = segmentation_high
            }
            setValue(value)
        }
    }, [proposal, proposal.strategy])

    const handleValueChange = (val, code) => {
        if (value[code] !== val) {
            setRefreshDisabled(true)
            let newValue = {
                ...value,
                [code]: val,
            }

            let valuePost: any = { ...newValue }

            if (
                proposal.strategy.segmentation_high >
                proposal.strategy.segmentation_low
            ) {
                valuePost.segmentation_high =
                    code === 'segmentation' ? val : value.segmentation
            } else if (
                proposal.strategy.segmentation_high <
                proposal.strategy.segmentation_low
            ) {
                valuePost.segmentation_low =
                    code === 'segmentation' ? val : value.segmentation
            } else {
                valuePost.segmentation_high =
                    code === 'segmentation' ? val : value.segmentation
                valuePost.segmentation_low =
                    code === 'segmentation' ? val : value.segmentation
            }
            delete valuePost.segmentation

            setUpdateSpinner(true)
            setLoaderAbstract('strategy', true)
            getHttpRequest(
                Endpoints.GET_DESCRIPTION_STRATEGY,
                { ...newValue },
                (response) => {
                    let description = response.data.data.description

                    patchHttpRequest(
                        Endpoints.PATCH_STRATEGY_BY_ID.replace(
                            '{id}',
                            proposal.strategy.id.toString(),
                        ),
                        {
                            data: {
                                id: proposal.strategy.id,
                                type: 'proposal_strategies',
                                attributes: {
                                    ...valuePost,
                                    description: description,
                                },
                            },
                        },
                        (response) => {
                            let strategy = new StrategyModel(response.data.data)
                            proposal.strategy = strategy
                            setRefreshDisabled(false)
                            setValue(newValue)
                            changeResultWithOutSave(proposal)
                            setUpdateSpinner(false)
                            setLoaderAbstract('strategy', false)
                            Functions.openAlertSuccess(
                                'Se han guardado todos los cambios',
                            )
                        },
                        (error) => {
                            Functions.openNotificationError(
                                Functions.errorObject(error),
                                'Error',
                            )
                            setValue(value)
                            setLoaderAbstract('strategy', false)
                            setUpdateSpinner(false)
                        },
                        () => {},
                    )
                },
                (error) => {
                    Functions.openNotificationError(
                        Functions.errorObject(error),
                        'Error',
                    )
                    setLoaderAbstract('strategy', false)
                    setUpdateSpinner(false)
                },
                () => {},
            )
        }
    }

    const handleRefresh = () => {
        setUpdateSpinner(true)
        setRefreshDisabled(true)
        setLoaderAbstract('strategy', true)
        getHttpRequest(
            Endpoints.GET_STRATEGY_BY_PROPOSAL_ID.replace(
                '{id}',
                proposal.id.toString(),
            ),
            {},
            (response) => {
                const {
                    frequency,
                    coverage,
                    segmentation_high,
                    segmentation_low,
                    impact,
                } = response.data.data

                let segmentation = 0
                let valuePost: any = {
                    impact: impact,
                    coverage: coverage,
                    frequency: frequency,
                    segmentation_high,
                    segmentation_low,
                }
                if (segmentation_high > segmentation_low) {
                    segmentation = segmentation_high
                } else if (segmentation_high < segmentation_low) {
                    segmentation = segmentation_low
                }

                let value = {
                    impact: impact,
                    segmentation: segmentation,
                    coverage: coverage,
                    frequency: frequency,
                }
                getHttpRequest(
                    Endpoints.GET_DESCRIPTION_STRATEGY,
                    { ...value },
                    (response) => {
                        let description = response.data.data.description
                        setValue(value)
                        patchHttpRequest(
                            Endpoints.PATCH_STRATEGY_BY_ID.replace(
                                '{id}',
                                proposal.strategy.id.toString(),
                            ),
                            {
                                data: {
                                    id: proposal.strategy.id,
                                    type: 'proposal_strategies',
                                    attributes: {
                                        ...valuePost,
                                        description: description,
                                    },
                                },
                            },
                            (response) => {
                                let strategy = new StrategyModel(
                                    response.data.data,
                                )
                                proposal.strategy = strategy
                                changeResultWithOutSave(proposal)
                                setUpdateSpinner(false)
                                setLoaderAbstract('strategy', false)
                                Functions.openAlertSuccess(
                                    'Se han guardado todos los cambios',
                                )
                            },
                            (error) => {
                                Functions.openNotificationError(
                                    Functions.errorObject(error),
                                    'Error',
                                )
                                setLoaderAbstract('strategy', false)
                                setUpdateSpinner(false)
                            },
                            () => {},
                        )
                    },
                    (error) => {
                        Functions.openNotificationError(
                            Functions.errorObject(error),
                            'Error',
                        )
                        setCreateSpinner(false)
                    },
                    () => {},
                )
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
                setCreateSpinner(false)
            },
            () => {},
        )
    }

    const skeleton = (
        <>
            <Skeleton.Button active block size={'small'} />
            <Skeleton.Button active block size={'small'} />
            <Skeleton.Button active block size={'small'} />
            <Skeleton.Button active block size={'small'} />
            <Skeleton.Button active block size={'small'} />
        </>
    )

    const skeletonGraph = (
        <>
            <Skeleton.Avatar active size={'large'} />
        </>
    )

    return (
        <div className='strategy-tab'>
            <TabsHeader
                title='Configura la estrategia'
                text='Los parámetros de configuración de la estrategia van a generar un mix de medios alineado al objetivo de tu campaña. Planning 3.0 tiene la capacidad de elegir de forma inteligente los parámetros óptimos para alcanzar tu objetivo. ¡Si te consideras un experto puedes editar los parámetros! Si te consideras un principiante te recomendamos mantenerlos como están.'
            />

            <Box>
                <div className='strategy-body'>
                    <div className='first'>
                        <div className='refresh-button'>
                            <Button
                                onClick={handleRefresh}
                                type='ghost'
                                disabled={refreshDisabled}
                                size='small'>
                                Restaurar valores
                                <SyncOutlined />
                            </Button>
                            <Popover
                                placement='topRight'
                                overlayStyle={{ width: '320px' }}
                                content={
                                    <div style={{ color: '#595959' }}>
                                        Restaura los valores iniciales sugeridos
                                        por Planning.
                                    </div>
                                }
                                trigger='click'
                                arrowPointAtCenter
                                visible={visible}
                                onVisibleChange={handleVisibleChange}>
                                <InfoCircleOutlined />
                            </Popover>
                        </div>
                        <div className='strategy-point-container'>
                            <StrategyPoint
                                title='Buscar cobertura'
                                description='Porcentaje de población dentro de un área geográfica a la que se puede llegar mediante un plan de medios.'
                                value={value?.coverage}
                                code='coverage'
                                disabled={updateSpinner}
                                handleValueChange={handleValueChange}
                            />                         
                            <StrategyPoint
                                title='Buscar frecuencia'
                                description='La cantidad promedio de veces que una persona nota un mensaje publicitario durante un período de tiempo definido.'
                                value={value?.frequency}
                                code='frequency'
                                disabled={updateSpinner}
                                handleValueChange={handleValueChange}
                            />
                            <StrategyPoint
                                title='Producir impacto'
                                description='Es el grado de influencia sobre la audiencia. Está relacionado con la forma en que un medio se conecta con el consumidor.'
                                value={value?.impact}
                                code='impact'
                                disabled={updateSpinner}
                                handleValueChange={handleValueChange}
                            />
                            <StrategyPoint
                                title='Especificar segmentación'
                                description='Se denomina a las variables de selección que sean necesarias acotar para lograr una mayor especificidad a la hora de evaluar la eficacia de la campaña.'
                                value={value?.segmentation}
                                code='segmentation'
                                disabled={updateSpinner}
                                handleValueChange={handleValueChange}
                            />
                        </div>

                        <div className='graph'>
                            <div className='text'>
                                <div>
                                    {proposal?.strategy &&
                                    strategySuggested &&
                                    dataGraph.length !== 0 ? (
                                        <RadarChart
                                            categories={categories}
                                            series={dataGraph}
                                            legend={false}
                                            label={false}
                                        />
                                    ) : (
                                        skeletonGraph
                                    )}
                                </div>
                            </div>
                            <span>
                                Cuánto se aproxima la configuración de
                                estrategia que hayas elegido a los parámetros
                                sugeridos por Planning.
                            </span>
                        </div>
                    </div>
                    <div className='recommended-text'>
                        <div className='text'>
                            <div>
                                {!updateSpinner
                                    ? proposal?.strategy?.description
                                        ? proposal?.strategy?.description
                                        : skeleton
                                    : skeleton}
                            </div>
                        </div>
                    </div>
                </div>
            </Box>

            <div className='footer-next'>
                <Button onClick={finishStrategy} type={'primary'}>
                    Siguiente <ArrowRightOutlined />
                </Button>
                <span className='next'>
                    Próximo paso, completa los detalles.
                </span>
            </div>
        </div>
    )
}
