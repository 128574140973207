import { HeaderOptimizationModel } from '../../../../../../../Models/HeaderOptimizationModel'
import { PlanningElementModel } from '../../../../../../../Models/result/assetsList/PlanningElementModel'
// @ts-ignore
import { Modal, Image, Descriptions } from 'antd'
// @ts-ignore
import { Button } from '@tdt-global/styleguide'
// @ts-ignore
import { utility } from '@tdt-global/utility'
import { PlanningElementClassificationsModel } from '../../../../../../../Models/result/mix/PlanningElementClassificationsModel'
import { PlanningElementsGeographicsModel } from '../../../../../../../Models/result/assetsList/PlanningElementsGeographicsModel'
import { DeleteOutlined, RetweetOutlined } from '@ant-design/icons/lib/icons'

type PropType = {
    visibleModal: {
        visible: boolean
        element: PlanningElementModel | null
    }
    elementsClass: PlanningElementsGeographicsModel
    panel: PlanningElementClassificationsModel
    proposal: HeaderOptimizationModel
    handleOk: () => void
    setVisibleModal: (visibleModal) => void
    handleReplaceDrawer: (id: number) => void
}

export const BodyModal = ({
    visibleModal,
    proposal,
    panel,
    elementsClass,
    handleOk,
    setVisibleModal,
    handleReplaceDrawer,
}: PropType) => {
    const { Functions, ShowImage } = utility
    const { element, visible } = visibleModal
    let color = panel?.hierarchy?.colour.startsWith('#')
        ? panel?.hierarchy?.colour
        : '#' + panel?.hierarchy?.colour

    return (
        <Modal
            centered
            visible={visibleModal.visible}
            onOk={() => setVisibleModal({ visible: false, id: null })}
            onCancel={() => setVisibleModal({ visible: false, id: null })}
            footer={null}
            zIndex={1000}
            width={832}>
            <div id='modal-map-result'>
                <div className='header'>
                    <div className='left'>
                        <div
                            className='image'
                            style={{
                                backgroundColor: color,
                            }}>
                            {panel?.hierarchy?.acronym}
                        </div>
                        <div className='info'>
                            <div className='semi-bold deep-purple font-14 m-b-2'>
                                {panel?.hierarchy?.name}
                            </div>
                            <div className='deep-purple m-b-2'>
                                {panel?.hierarchy?.hierarchy?.name}
                            </div>
                            <div className='deep-purple m-b-2'>
                                {panel?.hierarchy?.hierarchy?.hierarchy?.name}
                            </div>
                            <div className='semi-bold deep-purple font-14 m-t-6'>
                                {Functions.formatNumber(element?.price)}
                                <span className='normal-bold m-l-2'>
                                    {
                                        proposal?.proposal_budget?.currency
                                            ?.iso_code
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='right'>
                        {Functions.formatNumber(element?.otsEstimated)} OTS
                    </div>
                </div>
                <div className='body'>
                    <div className='left'>
                        <div className='image'>
                            <ShowImage
                                url={element?.element?.image?.dir}
                                isAnimated={true}
                                style={{ width: 400, height: 300 }}
                            />
                        </div>
                    </div>
                    <div className='right'>
                        <div className='table'>
                            <Descriptions size='small' column={1} bordered>
                                {element?.cpmEstimated && (
                                    <Descriptions.Item
                                        labelStyle={{ textAlign: 'right' }}
                                        label='CPM'>
                                        {`${element?.cpmEstimated} ${proposal?.proposal_budget?.currency?.iso_code}`}
                                    </Descriptions.Item>
                                )}
                                {element?.element?.code && (
                                    <Descriptions.Item
                                        labelStyle={{ textAlign: 'right' }}
                                        label='Código'>
                                        {element?.element?.code}
                                    </Descriptions.Item>
                                )}
                                {/* {element?.description.material && (
                                    <Descriptions.Item
                                        labelStyle={{ textAlign: 'right' }}
                                        label='Material'>
                                        {element?.description.material}
                                    </Descriptions.Item>
                                )} */}
                                {element?.period?.daysQuantity.toString() && (
                                    <Descriptions.Item
                                        labelStyle={{ textAlign: 'right' }}
                                        label='Tipo de contratación'>
                                        {`${element?.period?.daysQuantity.toString()} días`}
                                    </Descriptions.Item>
                                )}
                                {elementsClass?.city?.name && (
                                    <Descriptions.Item
                                        labelStyle={{ textAlign: 'right' }}
                                        label='Ciudad'>
                                        {elementsClass?.city?.name}
                                    </Descriptions.Item>
                                )}
                                {element?.element?.address?.address && (
                                    <Descriptions.Item
                                        labelStyle={{ textAlign: 'right' }}
                                        label='Ubicación'>
                                        {element?.element?.address?.address}
                                    </Descriptions.Item>
                                )}
                                {element?.element?.traffic?.name && (
                                    <Descriptions.Item
                                        labelStyle={{ textAlign: 'right' }}
                                        label='Tráfico'>
                                        {element?.element?.traffic?.name}
                                    </Descriptions.Item>
                                )}
                                {element?.element?.lightingType?.name && (
                                    <Descriptions.Item
                                        labelStyle={{ textAlign: 'right' }}
                                        label='Tipo de iluminación'>
                                        {element?.element?.lightingType?.name}
                                    </Descriptions.Item>
                                )}
                                {element?.element?.vendor?.name && (
                                    <Descriptions.Item
                                        labelStyle={{ textAlign: 'right' }}
                                        label='Proveedor'>
                                        {element?.element?.vendor?.name}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        </div>
                    </div>
                </div>
                <div className='footer'>
                    <Button
                        key='back'
                        ghost
                        size='small'
                        onClick={() =>
                            handleReplaceDrawer(visibleModal.element.id)
                        }>
                        Reemplazar <RetweetOutlined />
                    </Button>

                    <Button
                        key='submit'
                        ghost
                        size='small'
                        // loading={loading}
                        onClick={handleOk}>
                        Eliminar <DeleteOutlined />
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
