// @ts-ignore
import { Skeleton, Select, Empty } from 'antd'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { useEffect, useState } from 'react'
import { Endpoints } from '../../../../../Common/Endpoints'
import { InterestModel } from '../../../../../Models/InterestModel'
import { InterestType } from '../../../../../Types/InterestType'
import { InterestItem } from './InterestItem'
import { ProposalModel } from '../../../../../Models/ProposalModel'
import { loaderAbstractType } from '../../../../../Types/LoaderAbstract'
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons'
//@ts-ignore
import { Button } from '@tdt-global/styleguide'
import { InterestProfileModel } from '../../../../../Models/interest/InterestProfileModel'
import { BodyModalInterest } from './BodyModalInterest'
const { Option } = Select

type PropoType = {
    proposal: ProposalModel
    interestLoad: boolean
    updateSpinner: boolean
    loaderAbstract: loaderAbstractType[]
    setUpdateSpinner: (value: boolean) => void
    setLoaderAbstract: (key: string, loader: boolean) => void
    changeResultWithOutSave: (proposal: ProposalModel) => void
}

export const InterestProfile = ({
    proposal,
    interestLoad,
    updateSpinner,
    loaderAbstract,
    setUpdateSpinner,
    setLoaderAbstract,
    changeResultWithOutSave,
}: PropoType) => {
    const { useAxios, Functions } = utility
    const { getHttpRequest, patchHttpRequest } = useAxios()
    const [interestSelection, setInterestSelection] = useState<InterestModel[]>(
        [],
    )
    const [timeOut, setTimeOut] = useState(null)
    const [req, setReq] = useState(null)
    const [spinnerSearch, setSpinnerSearch] = useState<boolean>(false)
    const [data, setData] = useState<InterestModel[]>([])
    const [value, setValue] = useState(null)

    const [searchValue, setSearchValue] = useState('')
    const [noData, setNoData] = useState(null)
    const [interestModal, setInterestModal] = useState([])
    const [interestModalspin, setInterestModalSpin] = useState(false)
    const [visibleModal, setVisibleModal] = useState({
        visible: false,
    })

    useEffect(()=>{
        setInterestModalSpin(true)
        getHttpRequest(
            Endpoints.GET_INTERESTS_TYPES,
            { include: ['categories', 'categories.subcategories'] },
            (response) => {
                setInterestModal(
                    response.data.data.map(
                        (item) =>
                            new InterestProfileModel(
                                item,
                                response.data.included,
                            ),
                    ),
                )
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {
                setInterestModalSpin(false)
            },
        )
    },[])

    useEffect(() => {
        if (proposal.interest) {
            setInterestSelection(proposal.interest)
        }
    }, [proposal.interest])

    const updateInterest = (interestParams, interestList) => {
        setLoaderAbstract('interest', true)
        setUpdateSpinner(true)
        let params = {
            data: {
                id: proposal.audience_id,
                type: 'audiences',
                attributes: {
                    account_id: proposal?.account?.id,
                },
                relationships: {
                    interests: {
                        data: interestParams,
                    },
                },
            },
        }
        patchHttpRequest(
            Endpoints.PATCH_AUDIENCE_BY_ID.replace(
                '{id}',
                proposal.audience_id.toString(),
            ),
            params,
            (response) => {
                setLoaderAbstract('interest', false)
                proposal.interest = interestList
                changeResultWithOutSave(proposal)
                setUpdateSpinner(false)
                setValue(null)
                setData([])
                setSearchValue('')
                setNoData(null)
                Functions.openAlertSuccess('Se han guardado todos los cambios')
            },
            (error) => {
                setLoaderAbstract('interest', false)
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {},
        )
    }

    const handleChangeAdd = (value) => {
        let selectedValue = value.split('-')
        if (
            !interestSelection.find(
                (item) =>
                    item.id === Number(selectedValue[0]) &&
                    item.type === selectedValue[1],
            )
        ) {
            let newInterest = [...interestSelection]
            let interest = data.find(
                (item) =>
                    item.id === Number(selectedValue[0]) &&
                    item.type === selectedValue[1],
            )
            newInterest.push(interest)
            let list = newInterest.map((item) => {
                return { type: item.type, id: item.id }
            })
            updateInterest(list, newInterest)
        }
    }

    const handleChangeDel = (value) => {
        let selectedValue = value.split('-')

        let newInterest = interestSelection.filter(
            (item) =>
                item.id !== Number(selectedValue[0]) ||
                item.type !== selectedValue[1],
        )

        let list = newInterest.map((item) => {
            return { type: item.type, id: item.id }
        })
        updateInterest(list, newInterest)
    }

    const handleChangeDelAll = () => {
        if (
            !loaderAbstract.find((item) => item.key === 'interest')?.loader &&
            !updateSpinner &&
            interestSelection.length !== 0
        ) {
            updateInterest([], [])
        }
    }

    const handleSearch = (value: string) => {
        setSearchValue(value)
        setNoData(null)
        if (value.length >= 3) {
            clearTimeout(timeOut)
            setData([])
            setSpinnerSearch(true)
            if (req) {
                req.cancel()
                setReq(null)
            }
            setTimeOut(
                setTimeout(() => {
                    setReq(
                        getHttpRequest(
                            Endpoints.GET_INTERESTS_SEARCH,
                            { 'filter[text]': value },
                            (response) => {
                                let searchData = response?.data.data.map(
                                    (item: InterestType) =>
                                        new InterestModel(
                                            item,
                                            response.data.included,
                                        ),
                                )

                                if (searchData.length === 0) {
                                    setNoData(true)
                                } else {
                                    setNoData(null)
                                }
                                setData(searchData)
                            },
                            (error) => {
                                Functions.openNotificationError(
                                    Functions.errorObject(error),
                                    'Error',
                                )
                            },
                            () => {
                                setSpinnerSearch(false)
                            },
                        ),
                    )
                }, 1000),
            )
        } else {
            setData([])
        }
    }
    const getOptions = () => {
        let result = data.map((item, index) => (
            <Option
                key={index}
                value={`${item.id}-${item.type}`}
                className={
                    interestSelection.find(
                        (elem) =>
                            elem.id === item.id && elem.type === item.type,
                    )
                        ? `selected`
                        : ``
                }>
                <div className='option-item'>
                    <div className='title'>{item.sup_level}</div>
                    <div className='name'>{item.name}</div>
                </div>
            </Option>
        ))
        return result
    }

    const notFoundContent = () => {
        if (searchValue.length < 3) {
            return (
                <div className='letters-select-search'>
                    <ExclamationCircleFilled />
                    <span className='add-three-characters'>
                        Ingresa al menos 3 caracteres
                    </span>
                </div>
            )
        }
        if (spinnerSearch || !noData) {
            return (
                <LoadingOutlined
                    style={{ fontSize: 24, color: '#8C8C8C' }}
                    spin
                />
            )
        }
        if (noData) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={'No se encontraron resultados'}
                />
            )
        }
    }

    const showInterestModal = () => {
        setVisibleModal({ visible: true })
    }

    return (
        <div className='profile'>
            <div className='top'>
                <div className='search-box'>
                    <Select
                        virtual={false}
                        placeholder='Prueba: Fitness, compras, comidas'
                        size='large'
                        showSearch
                        placement='topLeft'
                        value={value}
                        dropdownClassName='dropdown-menu-select-group'
                        defaultActiveFirstOption={false}
                        onSearch={handleSearch}
                        filterOption={false}
                        notFoundContent={notFoundContent()}
                        disabled={
                            loaderAbstract.find(
                                (item) => item.key === 'interest',
                            )?.loader || updateSpinner
                        }
                        style={{ width: '100%' }}
                        onChange={handleChangeAdd}>
                        {getOptions()}
                    </Select>
                </div>
                <div className='button-explore'>
                    <Button ghost size='large' onClick={showInterestModal} disabled={updateSpinner}>
                        Explorar
                    </Button>
                </div>
                <BodyModalInterest
                    interestModal={interestModal}
                    interestModalspin={interestModalspin}
                    visibleModal={visibleModal}
                    setVisibleModal={setVisibleModal}
                />
            </div>
            <div className='bottom'>
                {interestLoad && (
                    <>
                        <Skeleton.Input
                            style={{ width: 150, height: 40 }}
                            active
                            size={'small'}
                        />
                        <Skeleton.Input
                            style={{ width: 150, height: 40 }}
                            active
                            size={'small'}
                        />

                        <Skeleton.Input
                            style={{ width: 150, height: 40 }}
                            active
                            size={'small'}
                        />
                    </>
                )}
                {interestSelection &&
                    interestSelection.map((item, index) => (
                        <InterestItem
                            key={index}
                            updateSpinner={updateSpinner}
                            loaderAbstract={loaderAbstract}
                            interest={item}
                            handleChangeDel={handleChangeDel}
                        />
                    ))}
            </div>
            {interestSelection.length > 1 && (
                <div className='delete-container'>
                    <div
                        className={`delete-button ${
                            loaderAbstract.find(
                                (item) => item.key === 'interest',
                            )?.loader || updateSpinner
                                ? 'disabled'
                                : ''
                        }`}
                        onClick={handleChangeDelAll}>
                        Eliminar todos
                    </div>
                </div>
            )}
        </div>
    )
}
