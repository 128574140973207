import { InterestType } from '../Types/InterestType'

export class InterestModel {
    private _id: number
    private _type: string
    private _name: string
    private _interest_type_id?: number
    private _sup_level?: string
    private _interest_category_id?: number

    constructor(interest: InterestType, included?) {
        this._id = interest.id
        this._name = interest.attributes.name
        this._interest_type_id = interest.attributes.interest_type_id
        this._interest_category_id = interest.attributes.interest_category_id
        this._type = interest.type

        if (included) {
            if (
                !interest.attributes.interest_type_id &&
                !interest.attributes.interest_category_id
            ) {
                this._sup_level = 'Intereses'
            } else if (interest.attributes.interest_type_id) {
                let aux = included.find(
                    (item) =>
                        item.id === interest.relationships.type.data.id &&
                        item.type === interest.relationships.type.data.type,
                )
                this._sup_level = aux.attributes.name
            } else if (interest.attributes.interest_category_id) {
                let aux = included.find(
                    (item) =>
                        item.id === interest.relationships.category.data.id &&
                        item.type === interest.relationships.category.data.type,
                )
                this._sup_level = aux.attributes.name
            } else this._sup_level = null
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get sup_level(): string {
        return this._sup_level
    }

    set sup_level(value) {
        this._sup_level = value
    }

    get interest_category_id(): number {
        return this._interest_category_id
    }

    set interest_category_id(value) {
        this._interest_category_id = value
    }

    get interest_type_id(): number {
        return this._interest_type_id
    }

    set interest_type_id(value) {
        this._interest_type_id = value
    }
}
