import { KeyboardEvent } from 'react'
import {
    Form,
    InputNumber,
    // @ts-ignore
} from 'antd'

type PropType = {
    required: boolean
    suffixSelector?: JSX.Element
    name: string
    className: string
    errorMessage: string
    placeholder: string
    preffix?: string
    size: 'small' | 'middle' | 'large' | undefined
    label?: JSX.Element | string
    disabled?: boolean
    min?: number
    errorMessageMax?: string
    max?: number
}

export const InputNumberDetail = ({
    label,
    name,
    required,
    preffix,
    suffixSelector,
    className,
    errorMessage,
    placeholder,
    size,
    disabled,
    errorMessageMax,
    max,
    min,
}: PropType) => {
    let formatter = (value) =>
        `${value}`.replace(/\./, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    let parser = (x) =>
        parseFloat(
            `${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/, '.'),
        )

    const handler = (e: any) => {
        var valoresAceptados = /^[0-9]+$/
        if (e.key.match(valoresAceptados)) {
            return true
        } else {
            e.preventDefault()
            return false
        }
    }

    return (
        <Form.Item
            className={className}
            name={name}
            label={label}
            rules={[
                {
                    required: required,
                    message: errorMessage,
                },
                () => ({
                    validator(_, value) {
                        if (!max) {
                            return Promise.resolve()
                        } else if (value <= max) {
                            return Promise.resolve()
                        } else if (value > max) {
                            return Promise.reject(new Error(errorMessageMax))
                        }
                        return Promise.resolve()
                    },
                }),
            ]}>
            <InputNumber
                placeholder={placeholder}
                size={size}
                prefix={preffix}
                disabled={disabled}
                min={min}
                style={{ width: '100%' }}
                formatter={(value: any) => formatter(value)}
                parser={(x: any) => {
                    let a: any = parser(x)
                    return a
                }}
                onKeyPress={handler}
                addonBefore={suffixSelector ? suffixSelector : null}
            />
        </Form.Item>
    )
}
