// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
//@ts-ignore
import { utility } from "@tdt-global/utility";

import { useEffect, useRef, useState } from "react";
import MapBoxController from "../../../../../Models/MapBoxController";
import { ProposalModel } from "../../../../../Models/ProposalModel";

mapboxgl.accessToken = utility.Config.MAP_BOX_KEY;

type PropType = {
  proposal: ProposalModel;
};

export const MapDetail = ({ proposal }: PropType) => {
  const { Functions } = utility;
  const mapCtr = useRef(new MapBoxController()).current;
  const mapContainerRef = useRef(null);

  const mapInit = () => {
    mapCtr.mapContainer = mapContainerRef.current;
    mapCtr.initMap(Functions.getTranslationMap("es"));
    mapCtr.addNavigationControl();
    mapCtr.map.setZoom(8);
    mapCtr.map.setCenter({
      lng: -99.141595,
      lat: 19.411892,
    });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      mapCtr.map.resize();
    });

    const mapContainer = document
      .getElementsByClassName("map-container")
      .item(0);
    if (mapContainer instanceof Element) {
      resizeObserver.observe(mapContainer);
    }
  }, []);

  useEffect(() => {
    mapCtr.destroy();
    mapInit();
  }, []);

  useEffect(() => {
    proposal?.proposalGeographics &&
      mapCtr.addMarkers(proposal?.proposalGeographics);
  }, [proposal.proposalGeographics]);

  return <div ref={mapContainerRef} className="map-container" />;
};
