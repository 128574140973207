import {
    DeleteOutlined,
} from '@ant-design/icons'
// @ts-ignore
import { Image, Divider } from 'antd'
import default_img from '../../../../../../../Assets/Img/img-default.svg'

//@ts-ignore
import { utility } from '@tdt-global/utility'
import { MixMedioData } from '../../../../../../../Types/result/mix/MixMedioData'
import { HeaderOptimizationModel } from '../../../../../../../Models/HeaderOptimizationModel'
import { MixComponentsInfoWindow } from './MixComponentsInfoWindow'

type PropType = {
    panel: MixMedioData
    proposal: HeaderOptimizationModel
}

export const MixPanel = ({ panel, proposal }: PropType) => {
    const { Functions } = utility

    const getIsoCode = () => {
        return proposal?.proposal_budget?.currency.iso_code
    }

    return (
        <div className='mix-panel'>
            {panel.elements.length ? (
                <div className='mix-panel-list'>
                    {panel.elements.map((item) => (
                        <div
                            className='mix-panel-item'
                            key={item.elementClassifications.id}>
                            <div className='image'>
                                <Image
                                    width={'100%'}
                                    height={'100%'}
                                    fallback={default_img}
                                    alt='imagen de soporte'
                                    src={item?.image ? item.image : 'error'}
                                />
                                <div className='text-in-image'>
                                    <span className='text-strategy'>
                                        {item.strategy}
                                    </span>
                                    <MixComponentsInfoWindow
                                        strategy={item.strategy}
                                    />
                                </div>
                            </div>
                            <div className='body'>
                                <div className='info'>
                                    <div className='assets-type semi-bold m-b-4'>
                                        {item.assetsType}
                                    </div>
                                    <div className='sub-title m-b-12'>
                                        {`${item.subCategory} / ${item.material}`}
                                    </div>

                                    <div className='flex g-8'>
                                        <div className='box semi-bold'>{`${item?.elementClassifications.totalElements}u`}</div>
                                        <div className='box flex'>
                                            <div className='semi-bold'>{`${Functions.formatNumber(
                                                item.elementClassifications
                                                    .budgetEstimated,
                                            )} `}</div>
                                            <span className='f-10 m-l-2'>
                                                {getIsoCode()}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className='flex g-4'
                                        style={{ marginTop: '12px' }}>
                                        <div className='box flex'>
                                            <div className='semi-bold'>{`${Functions.formatNumber(
                                                Math.round(
                                                    (item
                                                        ?.elementClassifications
                                                        .budgetEstimated /
                                                        panel
                                                            .planningGeographics
                                                            .budgetEstimated) *
                                                        100,
                                                ),
                                            )}  `}</div>
                                            <span className='f-10 m-l-2'>
                                                %
                                            </span>
                                        </div>
                                        <span className='f-10'>
                                            Consumo de presupuesto
                                        </span>
                                    </div>
                                    <Divider />
                                    <div className='flex g-4 m-b-12'>
                                        <div
                                            className={
                                                item.elementClassifications.cpmEstimated
                                                    ? 'box flex'
                                                    : 'no-info'
                                            }>
                                            {item.elementClassifications
                                                .cpmEstimated ? (
                                                <div className='semi-bold'>
                                                    {`${Functions.formatNumber(
                                                        item
                                                            .elementClassifications
                                                            .cpmEstimated,
                                                    )} `}
                                                    <span className='f-10 m-l-2'>
                                                        {getIsoCode()}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className='empty-cpm'>
                                                    Sin información
                                                </div>
                                            )}
                                        </div>

                                        <span className='f-10'>CPM</span>
                                    </div>

                                    <div className='flex g-12 m-b-12'>
                                        <div className='flex g-4'>
                                            <div className='box semi-bold'>{`${Functions.formatNumber(
                                                item?.elementClassifications
                                                    .otsEstimated,
                                            )}`}</div>
                                            <span className='f-10'>OTS</span>
                                        </div>
                                        <div className='flex g-4'>
                                            <div className='box flex g-2'>
                                                <div className='semi-bold'>{`${Functions.formatNumber(
                                                    Math.round(
                                                        (item
                                                            ?.elementClassifications
                                                            .otsEstimated /
                                                            panel
                                                                .planningGeographics
                                                                .ots_estimated) *
                                                            100,
                                                    ),
                                                )} `}</div>
                                                <span className='f-10 m-l-2'>
                                                    %
                                                </span>
                                            </div>
                                            <span className='f-10'>
                                                OTS totales
                                            </span>
                                        </div>
                                    </div>
                                    <Divider />
                                </div>
                                <div className='actions'>
                                    {/* <EnvironmentOutlined /> */}
                                    <DeleteOutlined />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                'No encontramos soportes en esta ciudad. Hemos redistribuido el presupuesto teniendo esto en consideración.'
            )}
        </div>
    )
}
