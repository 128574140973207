import { GenderPerformanceType } from '../Types/GenderPerformanceType'

export class GenderPerformanceModel {
    private _genderId: number
    private _label: string
    private _value: number
    private _percentage: string

    constructor(gender: GenderPerformanceType) {
        this._genderId = gender.gender_id
        this._label = gender.label
        this._value = gender.value
        this._percentage = gender.percentage
    }

    get genderId(): number {
        return this._genderId
    }

    set genderId(value) {
        this._genderId = value
    }

    get label(): string {
        return this._label
    }

    set label(value) {
        this._label = value
    }

    get value(): number {
        return this._value
    }

    set value(value) {
        this._value = value
    }

    get percentage(): string {
        return this._percentage
    }

    set percentage(value) {
        this._percentage = value
    }
}
