import { UnitMeasureType } from '../../Types/element/UnitMeasureType'

export class UnitMeasureModel {
    private _id: number
    private _type: string
    private _symbol: string | null
    private _name: string | null

    constructor(unitM: UnitMeasureType) {
        this._id = unitM.id
        this._type = unitM.type
        this._symbol = unitM.attributes.symbol
        this._name = unitM.attributes.name
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get symbol(): string {
        return this._symbol
    }

    set symbol(value) {
        this._symbol = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }
}
