import { PlanningsType } from '../Types/PlanningsType'

export class PlanningsModel {
    private _id: number
    private _type: string
    private _proposalId: number
    private _budgetTotal: number | string
    private _budgetEstimated: number | string
    private _populationEstimated: number
    private _populationTotal: number
    private _otsEstimated: number
    private _otsTotal: number
    private _cpmEstimated: number
    private _cpmTotal: number

    constructor(plannings: PlanningsType) {
        this._id = plannings.id
        this._type = plannings.type
        this._proposalId = plannings.attributes.proposal_id
        this._budgetTotal = plannings.attributes.budget_total
        this._budgetEstimated = plannings.attributes.budget_estimated
        this._populationEstimated = plannings.attributes.population_estimated
        this._populationTotal = plannings.attributes.population_total
        this._otsEstimated = plannings.attributes.ots_estimated
        this._otsTotal = plannings.attributes.ots_total
        this._cpmEstimated = plannings.attributes.cpm_estimated
        this._cpmTotal = plannings.attributes.cpm_total
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get proposalId(): number {
        return this._proposalId
    }

    set proposalId(value) {
        this._proposalId = value
    }

    get budgetTotal(): number | string {
        return this._budgetTotal
    }

    set budgetTotal(value) {
        this._budgetTotal = value
    }

    get populationEstimated(): number {
        return this._populationEstimated
    }

    set populationEstimated(value) {
        this._populationEstimated = value
    }

    get populationTotal(): number {
        return this._populationTotal
    }

    set populationTotal(value) {
        this._populationTotal = value
    }

    get otsEstimated(): number {
        return this._otsEstimated
    }

    set otsEstimated(value) {
        this._otsEstimated = value
    }

    get otsTotal(): number {
        return this._otsTotal
    }

    set otsTotal(value) {
        this._otsTotal = value
    }

    get cpmEstimated(): number {
        return this._cpmEstimated
    }

    set cpmEstimated(value) {
        this._cpmEstimated = value
    }

    get cpmTotal(): number {
        return this._cpmTotal
    }

    set cpmTotal(value) {
        this._cpmTotal = value
    }

    get budgetEstimated(): number | string {
        return this._budgetEstimated
    }
    set budgetEstimated(value: number | string) {
        this._budgetEstimated = value
    }
}
