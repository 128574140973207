import { ObjectiveType } from '../Types/ObjectiveType'
import { SubObjectiveType } from '../Types/SubObjectiveType'
import { SubObjectiveModel } from './SubObjectiveModel'

export class ObjectiveModel {
    private _id: number
    private _name: string
    private _type: string
    private _description: string
    private _icon: string | null
    private _order: number
    private _primary: any
    private _subObjectives: SubObjectiveModel[]

    constructor(objective: ObjectiveType, included?: SubObjectiveType[]) {
        this._id = objective.id
        this._name = objective.attributes.name
        this._description = objective.attributes.description
        this._icon = objective.attributes.icon
        this._order = objective.attributes.order
        this._primary = objective.attributes.primary
        this._type = objective.type
        let array = []
        included.map((item) => {
            if (item.id !== objective.id) {
                array.push(new SubObjectiveModel(item))
            }
        })

        this._subObjectives = array
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get description(): string {
        return this._description
    }

    set description(value) {
        this._description = value
    }

    get icon(): string | null {
        return this._icon
    }

    set icon(value) {
        this._icon = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get order(): number | null {
        return this._order
    }

    set order(value) {
        this._order = value
    }

    get primary(): any {
        return this._primary
    }

    set primary(value) {
        this._primary = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get subObjectives(): SubObjectiveModel[] {
        return this._subObjectives
    }

    set subObjectives(value) {
        this._subObjectives = value
    }
}
