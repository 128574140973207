import { AccountType } from '../Types/AccountType'
import { ProposalStatusType } from '../Types/ProposalStatusType'
import { ProposalType } from '../Types/ProposalType'
import { ProposalTypesType } from '../Types/ProposalTypesType'
import { StrategyType } from '../Types/StrategyType'
import { SubObjectiveType } from '../Types/SubObjectiveType'
import { AccountModel } from './AccountModel'
import { AudienceModel } from './AudienceModel'
import { BudgetModel } from './BudgetModel'
import { InterestModel } from './InterestModel'
import { ObjectiveModel } from './ObjectiveModel'
import { ProposalGeographicsModel } from './ProposalGeographicsModel'
import { ProposalStatusModel } from './ProposalStatusModel'
import { ProposalTypesModel } from './ProposalTypesModel'
import { StrategyModel } from './StrategyModel'
import { SubObjectiveModel } from './SubObjectiveModel'
import { UserModel } from './UserModel'

export class ProposalModel {
    private _id: number | null
    private _key: number | null
    private _name: string | null
    private _type: string | null
    private _account_id: number | null
    private _planningsId: number | null
    private _order_number: number | null
    private _user_id: string | null
    private _proposal_type_id: number | null
    private _proposal_status_id: number | null
    private _start_date: string | null
    private _finish_date: string | null
    private _created_at: string | null
    private _audience_id: number | null
    private _objectives: ObjectiveModel[] | null
    private _account: AccountModel | null
    private _status: ProposalStatusModel | null
    private _proposal_types: ProposalTypesModel | null
    private _geographics_text: string | null
    private _audience: AudienceModel | null
    private _interest?: InterestModel[]
    private _strategy?: StrategyModel
    private _user?: UserModel
    private _budget?: BudgetModel
    private _proposalGeographics?: ProposalGeographicsModel[]

    constructor(proposal?: ProposalType, included?) {
        if (!proposal) {
            this._id = null
            this._key = null
            this._name = null
            this._account_id = null
            this._planningsId = null
            this._user_id = null
            this._order_number = null
            this._proposal_type_id = null
            this._proposal_status_id = null
            this._start_date = null
            this._finish_date = null
            this._created_at = null
            this._audience_id = null
            this._audience = null
            this._objectives = []
            this._status = null
            this._proposal_types = null
            this._account = null
            this._interest = null
            this._type = 'proposal'
            this._strategy = null
            this._geographics_text = null
            this._user = null
            this._budget = null
            this._proposalGeographics = null
        } else {
            this._id = proposal.id
            this._key = proposal.id
            this._name = proposal.attributes.name
            this._order_number = proposal.attributes.order_number
            this._account_id = proposal.attributes.account_id
            this._planningsId = proposal.attributes.planning_id
            this._user_id = proposal.attributes.user_id
            this._proposal_type_id = proposal.attributes.proposal_type_id
            this._proposal_status_id = proposal.attributes.proposal_status_id
            this._start_date = proposal.attributes.start_date
            this._finish_date = proposal.attributes.finish_date
            this._created_at = proposal.attributes.created_at
            this._audience_id = proposal.attributes.audience_id
            this._budget = null
            this._geographics_text = proposal.attributes.geographics_text
            this._type = proposal.type
            this._interest = null
            this._strategy = null

            if (
                proposal.attributes.audience_id &&
                proposal.relationships.audience.data
            ) {
                this._audience = new AudienceModel(
                    included.find(
                        (item) =>
                            item.id ===
                                proposal.relationships.audience.data.id &&
                            item.type ===
                                proposal.relationships.audience.data.type,
                    ),
                )
            } else this._audience = null

            let objectivesList = []
            let status = null
            let proposal_types = null
            let account = null

            if (included) {
                if (proposal.relationships.user) {
                    let user = included.find(
                        (item) =>
                            item.id ===
                                proposal.relationships?.user?.data?.id &&
                            item.type ===
                                proposal.relationships?.user?.data?.type,
                    )
                    this._user = user ? new UserModel(user) : null
                }
                if (proposal.relationships.strategy) {
                    this._strategy = new StrategyModel(
                        included.find(
                            (item) =>
                                item.id ===
                                    proposal.relationships?.strategy?.data.id &&
                                item.type ===
                                    proposal.relationships?.strategy?.data.type,
                        ),
                    )
                }
                if (proposal.relationships.objectives) {
                    proposal.relationships?.objectives?.data?.map((item) => {
                        let element: SubObjectiveType = included.find(
                            (elem) =>
                                elem.id === item.id && elem.type === item.type,
                        )
                        element &&
                            objectivesList.push(new SubObjectiveModel(element))
                    })
                }
                if (proposal.relationships?.status?.data) {
                    let element: ProposalStatusType = included.find(
                        (elem) =>
                            elem.id ===
                                proposal.relationships?.status?.data.id &&
                            elem.type ===
                                proposal.relationships?.status?.data.type,
                    )
                    if (element) status = new ProposalStatusModel(element)
                }

                if (proposal.relationships?.type?.data) {
                    let element: ProposalTypesType = included.find(
                        (elem) =>
                            elem.id === proposal.relationships?.type?.data.id &&
                            elem.type ===
                                proposal.relationships?.type?.data.type,
                    )
                    if (element)
                        proposal_types = new ProposalTypesModel(element)
                }

                if (proposal.relationships?.account?.data) {
                    let element: AccountType = included.find(
                        (elem) =>
                            elem.id ===
                                proposal.relationships?.account?.data.id &&
                            elem.type ===
                                proposal.relationships?.account?.data.type,
                    )
                    if (element) account = new AccountModel(element)
                }
            }
            this._objectives = objectivesList
            this._status = status
            this._proposal_types = proposal_types
            this._account = account
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get key(): number {
        return this._key
    }

    set key(value) {
        this._key = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get account_id(): number {
        return this._account_id
    }

    set account_id(value) {
        this._account_id = value
    }

    get planningsId(): number {
        return this._planningsId
    }

    set planningsId(value) {
        this._planningsId = value
    }

    get user_id(): string {
        return this._user_id
    }

    set user_id(value) {
        this._user_id = value
    }

    get proposal_type_id(): number {
        return this._proposal_type_id
    }

    set proposal_type_id(value) {
        this._proposal_type_id = value
    }

    get proposal_status_id(): number {
        return this._proposal_status_id
    }

    set proposal_status_id(value) {
        this._proposal_status_id = value
    }

    get start_date(): string {
        return this._start_date
    }

    set start_date(value) {
        this._start_date = value
    }

    get finish_date(): string {
        return this._finish_date
    }

    set finish_date(value) {
        this._finish_date = value
    }

    get created_at(): string {
        return this._created_at
    }

    set created_at(value) {
        this._created_at = value
    }

    get order_number(): number {
        return this._order_number
    }

    set order_number(value) {
        this._order_number = value
    }

    get audience_id(): number | null {
        return this._audience_id
    }

    set audience_id(value) {
        this._audience_id = value
    }

    get objectives(): ObjectiveModel[] | null {
        return this._objectives
    }

    set objectives(value) {
        this._objectives = value
    }

    get status(): ProposalStatusModel | null {
        return this._status
    }

    set status(value) {
        this._status = value
    }

    get proposal_types(): ProposalTypesModel | null {
        return this._proposal_types
    }

    set proposal_types(value) {
        this._proposal_types = value
    }

    get account(): AccountModel | null {
        return this._account
    }

    set account(value) {
        this._account = value
    }

    get audience(): AudienceModel | null {
        return this._audience
    }

    set audience(value) {
        this._audience = value
    }

    get interest(): InterestModel[] {
        return this._interest
    }

    set interest(value) {
        this._interest = value
    }

    get strategy(): StrategyModel {
        return this._strategy
    }

    set strategy(value) {
        this._strategy = value
    }

    get user(): UserModel {
        return this._user
    }

    set user(value) {
        this._user = value
    }

    get budget(): BudgetModel {
        return this._budget
    }

    set budget(value) {
        this._budget = value
    }

    get proposalGeographics(): ProposalGeographicsModel[] {
        return this._proposalGeographics
    }

    set proposalGeographics(value) {
        this._proposalGeographics = value
    }

    get geographics_text(): string {
        return this._geographics_text
    }

    set geographics_text(value) {
        this._geographics_text = value
    }
}
