import { CloseOutlined } from '@ant-design/icons'
// @ts-ignore
import { Tooltip } from 'antd'
import { ProposalModel } from '../../../../../Models/ProposalModel'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { loaderAbstractType } from '../../../../../Types/LoaderAbstract'

type PropType = {
    proposal: ProposalModel
    loaderAbstract: loaderAbstractType[]
    disabledAll: boolean
    handleChangeDel: (id: number) => void
}

export const CitiesList = ({
    proposal,
    disabledAll,
    loaderAbstract,
    handleChangeDel,
}: PropType) => {
    const { Functions } = utility

    return (
        <>
            {proposal?.proposalGeographics &&
                proposal?.proposalGeographics?.length !== 0 && (
                    <div className='cities-list'>
                        {proposal?.proposalGeographics?.map((item, index) => (
                            <div
                                key={index}
                                className={`cities-item ${
                                    loaderAbstract.find(
                                        (item) => item.key === 'cities',
                                    )?.loader || disabledAll
                                        ? 'disabled'
                                        : ''
                                }`}>
                                <div className='info'>
                                    <span>{item.city.name}</span>
                                    <span>
                                        {`${Functions.formatNumberWithKMB(
                                            item.city.population,
                                        )} habs.`}
                                    </span>
                                </div>
                                <Tooltip title='Eliminar'>
                                    <div
                                        className='icon'
                                        onClick={() =>
                                            handleChangeDel(item.id)
                                        }>
                                        <CloseOutlined />
                                    </div>
                                </Tooltip>
                            </div>
                        ))}
                    </div>
                )}
        </>
    )
}
