import { StatusType } from '../../Types/status/StatusType'

export class StatusModel {
    private _id: number
    private _proposalTypeId: number | null
    private _type: string
    private _name: string
    private _color: string
    private _textColor: string
    constructor(status: StatusType) {
        this._id = status.id
        this._name = status.attributes.name
        this._type = status.type
        this._proposalTypeId = status.attributes.proposal_type_id
        this._color = status.attributes.color
        this._textColor = status.attributes.text_color
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get proposalTypeId(): number | null {
        return this._proposalTypeId
    }

    set proposalTypeId(value) {
        this._proposalTypeId = value
    }

    get color(): string {
        return this._color
    }

    set color(value) {
        this._color = value
    }

    get textColor(): string {
        return this._textColor
    }

    set textColor(value) {
        this._textColor = value
    }
}
