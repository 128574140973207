//@ts-ignore
import { utility } from '@tdt-global/utility'
import { ResultType } from '../../../../../Types/ResultType'

type PropType = {
    elem: any
    proposal: ResultType
    isAnimated: boolean
    onChange: (elem: any) => void
}

const AdvertiserItem = ({ ...props }: PropType) => {
    const { elem, proposal, isAnimated, onChange } = props
    const { ShowImage } = utility
    return (
        <div
            className={`advertiser-item ${
                proposal?.account?.id === elem.id ? 'selected' : ''
            }`}
            id={proposal?.account?.id === elem.id ? 'advertiser-selected' : ''}
            onClick={() => onChange(elem)}>
            <div className='image'>
                <ShowImage url={elem?.file} isAnimated={true} />
            </div>
            <div className='name'>{elem?.name}</div>
        </div>
    )
}

export default AdvertiserItem
