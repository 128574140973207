// @ts-ignore
import { Skeleton } from 'antd'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { ProposalModel } from '../../../../../../Models/ProposalModel'
import moment from 'moment'

type PropType = {
    data: ProposalModel
}

export const AdvertiserBox = ({ data, ...props }: PropType) => {
    const { ShowImage } = utility

    let startDate = moment(data?.start_date).format('DD MMM. YYYY')

    let finishDate = moment(data?.finish_date).format('DD MMM. YYYY')

    return (
        <div className='box advertiser'>
            <div className='left'>
                {data?.account.file ? (
                    <div className='container-image'>
                        <ShowImage
                            style={{
                                width: '49px',
                                height: '49px',
                                borderRadius: '2px',
                                borderColor: '#FFFFFF',
                            }}
                            url={data?.account.file}
                            isAnimated={true}
                        />
                    </div>
                ) : (
                    <Skeleton.Avatar active size={56} shape={'square'} />
                )}
            </div>
            <div className='right'>
                <div className='title'>
                    {data?.account.name ? (
                        data?.account.name
                    ) : (
                        <Skeleton.Input active size={'default'} />
                    )}
                </div>
                <div className='sub-title'>
                    {data?.name ? (
                        data?.name
                    ) : (
                        <>
                            <Skeleton.Input active size={'default'} />
                        </>
                    )}
                </div>
                <div className='sub-title'>
                    {data?.start_date && data?.finish_date ? (
                        `${startDate} - ${finishDate}`
                    ) : (
                        <>
                            <Skeleton.Input active size={'default'} />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
