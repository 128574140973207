// @ts-ignore
import { Skeleton } from 'antd'
import { GeographicsPerformanceType } from '../../../../../../../Types/GeographicsPerformanceType'
//@ts-ignore
import HighchartsReact from 'highcharts-react-official'
import addTreemapModule from 'highcharts/modules/treemap'
import Highcharts from 'highcharts'
import { useEffect, useState } from 'react'
addTreemapModule(Highcharts)

type PropType = {
    load: boolean
    geographics: GeographicsPerformanceType[]
    valueToShow: string
}

type HighChartsValue = {
    name: string
    value: number
}

export const GeographicalDistributionChart = ({
    load,
    geographics,
    valueToShow,
}: PropType) => {
    const [getData, setGetData] = useState<HighChartsValue[]>([
        { name: '', value: 0 },
    ])

    useEffect(() => {
        setGetData(
            geographics?.map((item) => ({
                name: item.label,
                value: item?.[valueToShow],
            })),
        )
    }, [geographics])

    const config: any = {
        chart: {
            width: 500,
            height: 300,
            color: 'lightcyan',
        },
        series: [
            {
                type: 'treemap',
                data: getData,
                legendType: 'point',
                layoutAlgorithm: 'squarified',
            },
        ],
        title: {
            text: '',
        },
        legend: {
            enabled: true,
            showInLegend: true,
            align: 'center',
        },
        plotOptions: {
            treemap: {
                allowDrillToNode: true,
                showInLegend: true,
                point: {
                    events: {
                      legendItemClick: function() {
                        return false;
                      }
                    }
                  }
            },
            series: {
                dataLabels: {
                    color: 'white',
                    border: 'white',
                    style: {
                        fontSize: '13px',
                    },
                },
            },
        },
        credits: {
            enabled: false,
        },
    }
    return (
        <div className='geographical-distribution-chart'>
            <div className='body'>
                {!load ? (
                    <>
                        <div className='chart'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={config}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className='chart'>
                            <Skeleton.Avatar
                                active
                                size={'large'}
                                shape={'circle'}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
