import { Menu, Dropdown, Tooltip } from 'antd'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { useState } from 'react'
import { ProposalModel } from '../../Models/ProposalModel'
import { StatusModel } from '../../Models/status/StatusModel'
import { Endpoints } from '../../Common/Endpoints'
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons'

type PropsType = {
    nextPage: number
    loading: boolean
    planList: any[] | null
    planSelected: {
        ids: any[]
    }
    sizePage: number
    statusList: StatusModel[]
    status: any
    item: any
    forceLoadList: () => void
    setLoading: (loading: boolean) => void
    setNextPage: (number) => void
}

export const ManageStatus = ({
    nextPage,
    status,
    item,
    statusList,
    forceLoadList,
    setNextPage,
}: PropsType) => {
    const { useAxios, Functions } = utility
    const { patchHttpRequest } = useAxios()
    const [statusSpinner, setStatusSpinner] = useState(false)

    const statusMenu = (item) => (
        <Menu onClick={(e) => handleMenuClick(e, item)}>
            {statusList.map((item) => (
                <Menu.Item key={item.id}>{item.name}</Menu.Item>
            ))}
        </Menu>
    )

    const handleMenuClick = (e, item: ProposalModel) => {
        setStatusSpinner(true)
        patchHttpRequest(
            Endpoints.PATCH_PROPOSAL_BY_ID.replace('{id}', item.id.toString()),
            {
                data: {
                    id: item.id,
                    type: 'proposals',
                    attributes: {
                        proposal_status_id: Number(e.key),
                    },
                },
            },
            (response: ResponseType) => {
                nextPage === 1 ? forceLoadList() : setNextPage(1)
                Functions.openNotificationSuccess('Estado actualizado', 'Éxito')
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {
                setStatusSpinner(false)
            },
        )
    }
    return (
        <div className='status'>
            <div
                className='text'
                style={{
                    color: status.text_color,
                    backgroundColor: status.color,
                }}>
                <Tooltip placement='topLeft' title={status.name}>
                    {status.name}
                </Tooltip>
            </div>
            <Tooltip placement='top' title='Cambiar estado'>
                <Dropdown className='more' overlay={statusMenu(item)}>
                    {statusSpinner ? <LoadingOutlined /> : <MoreOutlined />}
                </Dropdown>
            </Tooltip>
        </div>
    )
}
