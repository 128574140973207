import { GenderType } from '../Types/GenderType'

export class GenderModel {
    private _id: number
    private _name: string
    private _type: string

    constructor(gender: GenderType) {
        this._id = gender.id
        this._name = gender.attributes.name
        this._type = gender.type
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }
}
