// @ts-ignore
import { Modal, Collapse, Skeleton } from 'antd'
import { TabsInterestProfile } from '../../../../../Models/interest/Components/TabsInterestProfile'
import { InterestProfileModel } from '../../../../../Models/interest/InterestProfileModel'

const { Panel } = Collapse

type PropType = {
    visibleModal: {
        visible: boolean
    }
    interestModalspin: boolean
    interestModal: InterestProfileModel[]
    setVisibleModal: (visibleModal) => void
}

export const BodyModalInterest = ({
    visibleModal,
    interestModalspin,
    interestModal,
    setVisibleModal,
}: PropType) => {
    const skeletonAvatar = (
        <span>
            <Skeleton.Avatar active size={'small'} />
            <Skeleton.Button active size={'small'} />
        </span>
    )

    const stylePanel = {
        marginBottom: '8px',
        backgroundColor: '#FAFAFA',
        border: 0,
    }

    return (
        <Modal
            centered
            visible={visibleModal.visible}
            onCancel={() => setVisibleModal({ visible: false })}
            footer={null}
            title='Explorar'
            width={832}>
            <div id='interest-modal'>
                <div className='interest-body'>
                    <Collapse
                        bordered={false}
                        defaultActiveKey='0'
                        expandIconPosition={'right'}>
                        {interestModalspin ? (
                            <>
                                <Panel
                                    header={
                                        <Skeleton.Button
                                            active
                                            size={'small'}
                                        />
                                    }
                                    key={0}
                                    style={stylePanel}>
                                    {skeletonAvatar}
                                    {skeletonAvatar}
                                    {skeletonAvatar}
                                    {skeletonAvatar}
                                    {skeletonAvatar}
                                    {skeletonAvatar}
                                    {skeletonAvatar}
                                </Panel>
                                <Panel
                                    header={
                                        <Skeleton.Button
                                            active
                                            size={'small'}
                                        />
                                    }
                                    key={1}
                                    style={stylePanel}>
                                    {skeletonAvatar}
                                </Panel>
                                <Panel
                                    header={
                                        <Skeleton.Button
                                            active
                                            size={'small'}
                                        />
                                    }
                                    key={2}
                                    style={stylePanel}>
                                    {skeletonAvatar}
                                </Panel>
                                <Panel
                                    header={
                                        <Skeleton.Button
                                            active
                                            size={'small'}
                                        />
                                    }
                                    key={3}
                                    style={stylePanel}>
                                    {skeletonAvatar}
                                </Panel>
                            </>
                        ) : (
                            interestModal.map((item, index) => (
                                <Panel
                                    style={stylePanel}
                                    header={<span>{item.name}</span>}
                                    key={index}>
                                    <TabsInterestProfile
                                        interestModal={item.interestList}
                                    />
                                </Panel>
                            ))
                        )}
                    </Collapse>
                </div>
            </div>
        </Modal>
    )
}
