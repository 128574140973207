import { TrafficType } from '../../Types/element/TrafficType'

export class TrafficModel {
    private _type: string
    private _id: number
    private _name: string

    constructor(traffic: TrafficType) {
        this._type = traffic.type
        this._id = traffic.id
        this._name = traffic.attributes.name
    }

    get type(): string {
        return this._type
    }
    set type(value: string) {
        this._type = value
    }

    get name(): string {
        return this._name
    }
    set name(value: string) {
        this._name = value
    }

    get id(): number {
        return this._id
    }
    set id(value: number) {
        this._id = value
    }
}
