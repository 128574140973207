import { useEffect } from 'react'
import { CurrenciesModel } from '../../../../../../Models/CurrenciesModel'
import { ProposalModel } from '../../../../../../Models/ProposalModel'
import { InputNumberDetail } from '../FormItems/InputNumberDetail'

type PropType = {
    proposal: ProposalModel
    form: any
    disabledAll: boolean
    currencies: CurrenciesModel[]
}

export const CityInputForm = ({
    proposal,
    form,
    currencies,
    disabledAll,
}: PropType) => {
    useEffect(() => {
        var formValue = proposal.proposalGeographics.reduce(function (
            obj,
            itm,
        ) {
            obj['input-' + itm.id] = itm.budget
            return obj
        },
        {})
        form.setFieldsValue(formValue)
    }, [proposal.proposalGeographics])

    return (
        <>
            {proposal.proposalGeographics.map((item, index) => (
                <div key={index}>
                    <InputNumberDetail
                        size='large'
                        name={`input-${item?.id}`}
                        label={
                            <div className='budget-city'>
                                <span className='budget-city-bold'>{`${item?.city?.name}, `}</span>
                                <span>
                                    {`${item?.city?.department?.name}, ${item.city.state.name}`}
                                </span>
                            </div>
                        }
                        min={1}
                        max={2000000000}
                        preffix={
                            currencies.find(
                                (item) =>
                                    item.id === proposal?.budget?.currency_id,
                            ).iso_code
                        }
                        disabled={disabledAll}
                        className='small text-end'
                        placeholder='Ingrese un importe'
                        errorMessage='¡Por favor ingresa el presupuesto de la campaña!'
                        required
                    />
                </div>
            ))}
        </>
    )
}
