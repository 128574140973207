import { ProposalStatusType } from '../Types/ProposalStatusType'

export class ProposalStatusModel {
    private _id: number
    private _name: string
    private _type: string
    private _proposal_type_id: number
    private _color: string
    private _text_color: string

    constructor(status: ProposalStatusType) {
        this._id = status.id
        this._name = status.attributes.name
        this._proposal_type_id = status.attributes.proposal_type_id
        this._type = status.type
        this._color = status.attributes.color
        this._text_color = status.attributes.text_color
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get proposal_type_id(): number {
        return this._proposal_type_id
    }

    set proposal_type_id(value) {
        this._proposal_type_id = value
    }

    get color(): string {
        return this._color
    }

    set color(value) {
        this._color = value
    }

    get text_color(): string {
        return this._text_color
    }

    set text_color(value) {
        this._text_color = value
    }
}
