// @ts-ignore
import { Form, Select, Empty } from "antd";
import { useState } from "react";

const { Option } = Select;
//@ts-ignore
import { utility } from "@tdt-global/utility";
import { Endpoints } from "../../../../../Common/Endpoints";
import { CityType } from "../../../../../Types/CityType";
import { CityModel } from "../../../../../Models/CityModel";
import { ProposalModel } from "../../../../../Models/ProposalModel";
import { MapDetail } from "./MapDetail";
import { CitiesList } from "./CitiesList";
import { loaderAbstractType } from "../../../../../Types/LoaderAbstract";
import {
  ExclamationCircleFilled,
  LoadingOutlined,
  StopOutlined,
} from "@ant-design/icons";

type PropType = {
  form: any;
  proposal: ProposalModel;
  loaderAbstract: loaderAbstractType[];
  disabledAll: boolean;
  setDisabledAll: (value: boolean) => void;
  getSuggestedBudget: (any?: any) => void;
  setLoaderAbstract: (key: string, loader: boolean) => void;
  changeResultWithOutSave: (proposal: ProposalModel) => void;
};

export const Cities = ({
  form,
  proposal,
  loaderAbstract,
  disabledAll,
  setDisabledAll,
  getSuggestedBudget,
  setLoaderAbstract,
}: PropType) => {
  const { useAxios, Functions } = utility;
  const { getHttpRequest, postHttpRequest, deleteHttpRequest } = useAxios();
  const [data, setData] = useState<CityModel[]>([]);
  const [req, setReq] = useState(null);
  const [timeOut, setTimeOut] = useState(null);
  const [spinnerSearch, setSpinnerSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const [noData, setNoData] = useState(null);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setNoData(null);
    if (value.length >= 3) {
      clearTimeout(timeOut);
      setData([]);
      if (req) {
        req.cancel();
        setReq(null);
      }
      setTimeOut(
        setTimeout(() => {
          setSpinnerSearch(true);
          setReq(
            getHttpRequest(
              Endpoints.GET_CITIES,
              {
                "filter[text]": value,
                "filter[country][0]": 4,
                // "filter[department]": -1,
                "filter[id]": [89, 4762],
                sort: ["-population"],
                include: ["state", "department"],
              },
              (response) => {
                let searchData = response?.data.data.map(
                  (item: CityType) =>
                    new CityModel(item, response.data.included)
                );
                if (searchData.length === 0) {
                  setNoData(true);
                } else {
                  setNoData(null);
                }
                setData(searchData);
              },
              (error) => {
                Functions.openNotificationError(
                  Functions.errorObject(error),
                  "Error"
                );
              },
              () => {
                setSpinnerSearch(false);
              }
            )
          );
        }, 1000)
      );
    } else {
      setData([]);
    }
  };

  const handleChangeAdd = (value) => {
    if (
      !proposal?.proposalGeographics?.find(
        (item) => item.city.id === Number(value)
      )
    ) {
      let param = {
        data: [{ id: value, type: "cities" }],
        include: ["city.state", "city.department", "city"],
      };

      setLoaderAbstract("proposalGeographics", true);
      setDisabledAll(true);
      postHttpRequest(
        Endpoints.POST_CITY_TO_PROPOSAL.replace("{id}", proposal.id.toString()),
        param,
        (response) => {
          setLoaderAbstract("cities", false);
          setData([]);
          setSearchValue("");
          setNoData(null);
          getSuggestedBudget();
        },
        (error) => {
          Functions.openNotificationError(
            Functions.errorObject(error),
            "Error"
          );
          setLoaderAbstract("cities", false);
        },
        () => {}
      );
    }
    form.setFieldsValue({
      cities: null,
    });
  };

  const handleChangeDel = (value) => {
    setLoaderAbstract("cities", true);
    setDisabledAll(true);
    deleteHttpRequest(
      Endpoints.DEL_PROPOSAL_GEOGRAPHICS.replace(
        "{proposal}",
        proposal.id.toString()
      ).replace("{id}", value.toString()),
      {},
      (response) => {
        getSuggestedBudget();
      },
      (error) => {
        Functions.openNotificationError(Functions.errorObject(error), "Error");
        setLoaderAbstract("cities", false);
      },
      () => {}
    );
  };

  const getOptions = () => {
    // let aux = data.length - 1
    let result = data.map((item, index) => (
      <Option
        key={index}
        value={item.id}
        // disabled={index === aux}
        className={`${
          proposal?.proposalGeographics?.find(
            (elem) => elem.city.id === item.id
          )
            ? "selected"
            : ""
          // } ${index === aux ? 'no-assets-search' : ''}`}>
        }  ''}`}
      >
        <div style={{ display: "flex" }}>
          <span
            style={{
              fontWeight: "600",
              // color: index === aux ? '#8C8C8C' : '#595959',
              color: "#595959",
            }}
          >
            {item.name}
          </span>
          <span style={{ color: "#8C8C8C" }}>
            {`, ${item.department?.name}, ${item.state?.name}`}
          </span>
          {/* {index === aux && (
                        <span style={{ color: '#595959', marginLeft: 'auto' }}>
                            <StopOutlined />
                            {` Sin soportes`}
                        </span>
                    )} */}
        </div>
      </Option>
    ));
    return result;
  };

  const notFoundContent = () => {
    if (searchValue.length < 3) {
      return (
        <div className="letters-select-search">
          <ExclamationCircleFilled />
          <span className="add-three-characters">
            Ingresa al menos 3 caracteres
          </span>
        </div>
      );
    }
    if (spinnerSearch || !noData) {
      return (
        <LoadingOutlined style={{ fontSize: 24, color: "#8C8C8C" }} spin />
      );
    }
    if (noData) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"No se encontraron resultados"}
        />
      );
    }
  };

  return (
    <div className="cities">
      <Form.Item
        className="full asterisk"
        label="Ciudades"
        name="cities"
        rules={[
          () => ({
            validator(_) {
              if (proposal?.proposalGeographics?.length > 0) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Por favor selecciona al menos una ciudad.")
              );
            },
          }),
        ]}
      >
        <Select
          virtual={false}
          placeholder="Busca..."
          size="large"
          showSearch
          onSearch={handleSearch}
          placement="topLeft"
          filterOption={false}
          notFoundContent={notFoundContent()}
          style={{ width: "100%" }}
          disabled={
            loaderAbstract.find((item) => item.key === "proposalGeographics")
              ?.loader || disabledAll
          }
          onChange={handleChangeAdd}
        >
          {getOptions()}
        </Select>
      </Form.Item>

      <CitiesList
        loaderAbstract={loaderAbstract}
        proposal={proposal}
        disabledAll={disabledAll}
        handleChangeDel={handleChangeDel}
      />

      <MapDetail proposal={proposal} />
    </div>
  );
};
