import { CurrenciesType } from '../Types/CurrenciesType'

export class CurrenciesModel {
    private _id: number
    private _name: string
    private _type: string
    private _iso_code: string
    private _symbol: string

    constructor(currency: CurrenciesType) {
        this._id = currency.id
        this._name = currency.attributes.name
        this._type = currency.type
        this._iso_code = currency.attributes.iso_code
        this._symbol = currency.attributes.symbol
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get iso_code(): string {
        return this._iso_code
    }

    set iso_code(value) {
        this._iso_code = value
    }

    get symbol(): string {
        return this._symbol
    }

    set symbol(value) {
        this._symbol = value
    }
}
