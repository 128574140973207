// @ts-ignore
import { Table, Tooltip, Menu, Dropdown, Empty } from 'antd'
//@ts-ignore
import { utility } from '@tdt-global/utility'
// @ts-ignore
import { ButtonsModalConfirm, SpinnerBar } from '@tdt-global/styleguide'
import lottieAnimation from '../../Assets/Img/load-planification-list.json'
import Lottie from 'lottie-web'
import {
    EyeOutlined,
    EditOutlined,
    MoreOutlined,
    DeleteOutlined,
    LoadingOutlined,
} from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { Endpoints } from '../../Common/Endpoints'
// @ts-ignore
import moment from 'moment'
import { UserModel } from '../../Models/UserModel'
import { ShowModalType } from '../../Types/ShowModalType'
import { createSearchParams, useLocation } from 'react-router-dom'
import { Path } from '../../Common/Path'
import { StatusModel } from '../../Models/status/StatusModel'
import { ProposalModel } from '../../Models/ProposalModel'
import { ManageStatus } from './ManageStatus'

type PropsType = {
    nextPage: number
    loading: boolean
    planList: any[] | null
    planSelected: {
        ids: any[]
    }
    sizePage: number
    isAnimated: boolean
    setLoading: (loading: boolean) => void
    setNextPage: (number) => void
    success: (response: any) => void
    setPlanSelected: ({ ids }) => void
}

export const PlanificationTable = ({ ...props }: PropsType) => {
    const {
        nextPage,
        planList,
        planSelected,
        sizePage,
        loading,
        isAnimated,
        setLoading,
        success,
        setNextPage,
        setPlanSelected,
    } = props
    const { useAxios, Functions, ShowImage, ShowModal } = utility
    const { getHttpRequest, deleteHttpRequest, patchHttpRequest } = useAxios()
    const [deleteSpinner, setDeleteSpinner] = useState(false)
    const [statusSpinner, setStatusSpinner] = useState(false)
    const [statusList, setStatusList] = useState<StatusModel[]>([])
    const [propsModal, setPropsModal] = useState<ShowModalType>({
        show: false,
        title: '',
        className: '',
        hideModal: null,
        component: null,
        modalProps: {},
        confirmLoading: false,
    })

    const location = useLocation()

    const handleRowSelection = (rowKeys: React.Key[]) =>
        setPlanSelected({ ids: rowKeys })

    const handleClickView = (rowData: any) => {
        console.log(rowData)
    }

    const handleClickEdit = (rowData: any) => {
        const searchParams = new URLSearchParams()
        searchParams.append('tab', 'new')
        searchParams.append('option', 'fromStart')
        searchParams.append('id', rowData.id.toString())
        searchParams.append('status', 'edit')
        window.open(`${Path.NEW_PLANNING}?${createSearchParams(searchParams)}`)
    }

    const hideModal = () => {
        setPropsModal({
            show: false,
            ...propsModal,
        })
    }

    const handleClickDelete = (rowData: any) => {
        const handleOk = () => {
            setDeleteSpinner(true)
            setPropsModal({
                show: false,
                ...propsModal,
            })
            deleteHttpRequest(
                Endpoints.DEL_PROPOSAL.replace('{id}', rowData.id.toString()),
                {},
                (response: any) => {
                    nextPage === 1 ? forceLoadList() : setNextPage(1)
                    setDeleteSpinner(false)
                    Functions.openNotificationSuccess(
                        'Planificación eliminada.',
                        'Éxito',
                    )
                },
                (error) => {
                    Functions.openNotificationError(
                        Functions.errorObject(error),
                        'Error',
                    )
                },
                () => {},
            )
        }
        setPropsModal({
            show: true,
            className: 'modal-confirm',
            title: 'Eliminar planificación',
            component: (
                <>
                    Estás por eliminar la planificación. Esta acción no puede
                    deshacerse. ¿Deseas eliminar la planificación?
                    <ButtonsModalConfirm
                        okText='Eliminar'
                        cancelText='Cancelar'
                        handleOK={handleOk}
                        handleCancel={hideModal}
                    />
                </>
            ),
            hideModal: hideModal,
            modalProps: {
                closable: false,
                width: 588,
                footer: null,
            },
        })
    }

    const forceLoadList = () => {
        setLoading(true)
    }

    useEffect(() => {
        loadStatus()
        loadPlanList()
    }, [loading])

    useEffect(() => {
        setLoading(true)
    }, [nextPage, sizePage])

    const loadStatus = () => {
        setStatusSpinner(true)
        getHttpRequest(
            Endpoints.GET_STATUSES_ALL,
            {},
            successStatus,
            error,
            alwaysStatus,
        )
    }

    const successStatus = (response: any) => {
        setStatusList(
            response.data.data.map((item: any) => new StatusModel(item)),
        )
    }
    const alwaysStatus = () => setStatusSpinner(false)

    const initLottie = () => {
        Lottie.loadAnimation({
            container: document.getElementById('animation-load'), // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: lottieAnimation, // the path to the animation json
        })
    }

    useEffect(() => {
        planList === null ? initLottie() : Lottie.destroy()
        if (planList && planList?.length !== 0 && location?.state) {
            let rowId = document.querySelector(
                // @ts-ignore
                `tr[data-row-key="${location?.state?.id}"]`,
            )
            let className = rowId.className
            if (rowId) {
                rowId.className = `${rowId.className} proposal-saved-animation`
                setTimeout(() => {
                    rowId.className = className
                }, 7000)
                window.history.replaceState('', 'replaceState')
            }
        }
    }, [planList])

    const loadPlanList = () => {
        if (loading) {
            const data = {
                'page[size]': sizePage,
                'page[number]': nextPage,
                view: 'switch',
                sort: ['-createdAt'],
                include: [
                    'type',
                    'status',
                    'objectives',
                    'account',
                    'account.logo',
                    'audience',
                    'user',
                ],
            }

            getHttpRequest(
                Endpoints.GET_PLANIFICATIONS_PAGINATED,
                data,
                success,
                error,
                always,
            )
        }
    }

    const error = (error: any) =>
        Functions.openNotificationError(Functions.errorObject(error), 'Error')

    const always = () => setLoading(false)

    const columns: any = [
        {
            title: 'Código',
            dataIndex: 'order_number',
            key: 'order_number',
            fixed: 'left',
            width: 100,
            align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (order_number: any) => (
                <Tooltip placement='topLeft' title={order_number}>
                    {order_number}
                </Tooltip>
            ),
        },
        {
            title: 'Anunciante',
            dataIndex: 'account',
            key: 'account',
            fixed: 'left',
            width: 250,
            ellipsis: {
                showTitle: false,
            },
            render: (account: any) => (
                <Tooltip placement='topLeft' title={`${account?.name}`}>
                    <div className='advertiser-table'>
                        <div className='image-advertiser'>
                            <ShowImage url={account?.file} isAnimated={true} />
                        </div>
                        <span>{account?.name}</span>
                    </div>
                </Tooltip>
            ),
        },

        {
            title: 'Campaña',
            dataIndex: 'name',
            width: 300,
            key: 'name',
            ellipsis: {
                showTitle: false,
            },
            render: (name: any) => (
                <Tooltip placement='topLeft' title={name}>
                    <div className='campaing-table ellipsis-two-lines'>
                        {name}
                    </div>
                </Tooltip>
            ),
        },

        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            ellipsis: {
                showTitle: false,
            },
            render: (status: any, item: any) => {
                return (
                    <ManageStatus
                        nextPage={nextPage}
                        loading={loading}
                        planList={planList}
                        planSelected={planSelected}
                        sizePage={sizePage}
                        status={status}
                        item={item}
                        setLoading={setLoading}
                        setNextPage={setNextPage}
                        forceLoadList={forceLoadList}
                        statusList={statusList}
                    />
                )
            },
        },
        // {
        //     title: 'Objetivo',
        //     dataIndex: 'objectives',
        //     key: 'objectives',
        //     width: 250,
        //     ellipsis: {
        //         showTitle: false,
        //     },
        //     render: (objectives: any) => {
        //         let objective = objectives.find((item) => item.primary)

        //         return (
        //             <Tooltip placement='topLeft' title={objective?.name}>
        //                 {objective?.name}
        //             </Tooltip>
        //         )
        //     },
        // },

        {
            title: 'Período',
            dataIndex: 'period',
            key: 'period',
            width: 210,
            ellipsis: {
                showTitle: false,
            },
            render: (period: any, item: any) => {
                let date =
                    item.start_date && item.finish_date
                        ? `${moment(item.start_date)
                              .format('DD MMM. YYYY')
                              .toLowerCase()} - ${moment(item.finish_date)
                              .format('DD MMM. YYYY')
                              .toLowerCase()}`
                        : ''

                return (
                    <Tooltip placement='topLeft' title={date}>
                        {date}
                    </Tooltip>
                )
            },
        },
        {
            title: 'Ciudades',
            dataIndex: 'country',
            key: 'country',
            width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (country: any, item: any) => (
                <Tooltip placement='topLeft' title={item?.geographics_text}>
                    <div className='ellipsis-two-lines'>
                        {item?.geographics_text}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: 'Fecha de creación',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 160,
            ellipsis: {
                showTitle: false,
            },
            render: (created_at: any) => {
                let date = `${moment(created_at)
                    .format('DD MMM. YYYY HH:mm')
                    .toLowerCase()}`

                return (
                    <Tooltip placement='topLeft' title={date}>
                        {date}
                    </Tooltip>
                )
            },
        },
        {
            title: 'Usuario',
            dataIndex: 'user',
            key: 'user',
            width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (user: UserModel, item: any) => (
                <Tooltip
                    placement='topLeft'
                    title={`${user.first_name} ${user.last_name}`}>
                    {`${user.first_name} ${user.last_name}`}
                </Tooltip>
            ),
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            ellipsis: {
                showTitle: false,
            },
            width: 150,
            fixed: 'right',
            render: (_: any, item: any) => (
                <div className={'text-center actions-table'}>
                    <Tooltip placement='top' title='Ver'>
                        <EyeOutlined onClick={() => handleClickView(item)} />
                    </Tooltip>
                    <Tooltip placement='top' title='Editar'>
                        <EditOutlined onClick={() => handleClickEdit(item)} />
                    </Tooltip>
                    <Tooltip placement='top' title='Eliminar'>
                        <DeleteOutlined
                            onClick={() => handleClickDelete(item)}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ]

    let widthTable = 0
    columns.forEach((column) => (widthTable += column.width))

    return (
        <div className='planification-table'>
            <div id='animation-load'></div>
            {planList?.length !== 0 && planList && (
                <>
                    <SpinnerBar loading={loading}></SpinnerBar>
                    <Table
                        id='planification_table'
                        columns={columns}
                        scroll={{
                            x: widthTable,
                            y: Functions.getTableHeightByWindowsSize(290),
                        }}
                        // rowSelection={{
                        //     selectedRowKeys: planSelected?.ids,
                        //     onChange: (rowKeys: React.Key[]) =>
                        //         handleRowSelection(rowKeys),
                        // }}
                        dataSource={planList}
                        pagination={false}
                        loading={{
                            spinning: loading || deleteSpinner,
                            indicator: null,
                        }}
                    />
                </>
            )}
            {planList && planList?.length === 0 && (
                <div className='empty-table'>
                    <Empty
                        description={
                            <>
                                <p>No tienes planificaciones para mostrar.</p>
                                <p>Crea una nueva planificación.</p>
                            </>
                        }
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                </div>
            )}

            <ShowModal {...propsModal} />
        </div>
    )
}
