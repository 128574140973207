import { AgeRangePerformanceType } from '../Types/AgeRangePerformanceType'
import { ElementClassificationsType } from '../Types/ElementClassificationsType'
import { GeographicsPerformanceType } from '../Types/GeographicsPerformanceType'
import { PerformanceType } from '../Types/PerformanceType'
import { RadarChartType } from '../Types/result/performance/RadarChartType'
import { BudgetModel } from './BudgetModel'
import { GenderPerformanceModel } from './GenderPerformanceModel'
import { PlanningsModel } from './PlanningsModel'
import { ProposalModel } from './ProposalModel'

export class PerformanceModel {
    private _planning: PlanningsModel
    private _proposal: ProposalModel
    private _proposalBudget: BudgetModel
    private _genders: GenderPerformanceModel[]
    private _ageRanges: AgeRangePerformanceType[]
    private _elementClassifications: ElementClassificationsType[]
    private _geographics: GeographicsPerformanceType[]
    private _radarChart: RadarChartType[]

    constructor(performance: PerformanceType, included?) {
        this._planning = new PlanningsModel(performance.planning)
        this._proposal = new ProposalModel(performance.proposal, included)
        this._proposalBudget = new BudgetModel(
            performance.proposal_budget,
            included,
        )
        this._genders = performance.genders.map(
            (item) => new GenderPerformanceModel(item),
        )
        this._ageRanges = performance.age_ranges
        this._elementClassifications = performance.element_classifications
        this._geographics = performance.geographics
        this._radarChart = performance.radarChart
    }

    get planning(): PlanningsModel {
        return this._planning
    }

    set planning(value) {
        this._planning = value
    }

    get proposal(): ProposalModel {
        return this._proposal
    }

    set proposal(value) {
        this._proposal = value
    }

    get genders(): GenderPerformanceModel[] {
        return this._genders
    }

    set genders(value) {
        this._genders = value
    }

    get ageRanges(): AgeRangePerformanceType[] {
        return this._ageRanges
    }

    set ageRanges(value) {
        this._ageRanges = value
    }

    get elementClassifications(): ElementClassificationsType[] {
        return this._elementClassifications
    }

    set elementClassifications(value) {
        this._elementClassifications = value
    }

    get proposalBudget(): BudgetModel {
        return this._proposalBudget
    }

    set proposalBudget(value) {
        this._proposalBudget = value
    }

    get geographics(): GeographicsPerformanceType[] {
        return this._geographics
    }

    set geographics(value) {
        this._geographics = value
    }

    get radarChart(): RadarChartType[] {
        return this._radarChart
    }

    set radarChart(value) {
        this._radarChart = value
    }
}
