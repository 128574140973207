import { InterestModel } from '../../../../../Models/InterestModel'
// @ts-ignore
import { Tooltip } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { loaderAbstractType } from '../../../../../Types/LoaderAbstract'

type PropType = {
    interest: InterestModel
    loaderAbstract: loaderAbstractType[]
    updateSpinner: boolean
    handleChangeDel: (value) => void
}

export const InterestItem = ({
    interest,
    updateSpinner,
    loaderAbstract,
    handleChangeDel,
}: PropType) => {
    const { name, id, type } = interest
    let value = `${id}-${type}`
    return (
        <div
            className={`interest-item ${
                loaderAbstract.find((item) => item.key === 'interest')
                    ?.loader || updateSpinner
                    ? 'disabled'
                    : ''
            }`}>
            <span>{name}</span>
            <Tooltip title='Eliminar'>
                <div
                    className='icon'
                    onClick={() =>
                        !loaderAbstract.find((item) => item.key === 'interest')
                            ?.loader &&
                        !updateSpinner &&
                        handleChangeDel(value)
                    }>
                    <CloseOutlined />
                </div>
            </Tooltip>
        </div>
    )
}
