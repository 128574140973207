import { PlanningElementType } from '../../../Types/result/assetsList/PlanningElementType'
import { PeriodModel } from '../../PeriodModel'
import { HierarchyModel } from '../mix/HierarchyModel'
import { PlanningGeographicsModel } from '../mix/PlanningGeographicsModel'
import { ElementModel } from './ElementModel'

export class PlanningElementModel {
    private _id: number
    private _type: string
    private _planningId: number | null
    private _planningGeographicId: number | null
    private _planningElementClassificationId: number | null
    private _hierarchyId: number | null
    private _elementId: number
    private _price: number | null
    private _ots: number | null
    private _otsEstimated: number | string | null
    private _cpm: number | null
    private _cpmEstimated: number | string | null
    private _created_at: string | null
    private _element: ElementModel
    private _hierarchy: HierarchyModel
    private _period: PeriodModel
    private _planningGeographic: PlanningGeographicsModel

    constructor(elem: PlanningElementType, included: any) {
        this._id = elem.id
        this._type = elem.type
        this._planningId = elem.attributes.planning_id
        this._planningGeographicId = elem.attributes.planning_geographic_id
        this._planningElementClassificationId =
            elem.attributes.planning_element_classification_id
        this._hierarchyId = elem.attributes.hierarchy_id
        this._elementId = elem.attributes.element_id
        this._periodId = elem.attributes.period_id
        this._price = elem.attributes.price
        this._ots = elem.attributes.ots
        this._otsEstimated = elem.attributes.ots_estimated
        this._cpm = elem.attributes.cpm
        this._cpmEstimated = elem.attributes.cpm_estimated
        this._created_at = elem.attributes.created_at
        this._element = null
        this._period = null

        if (included && elem.relationships?.element?.data) {
            this._element = new ElementModel(
                included.find(
                    (item) =>
                        item.id === elem.relationships.element.data.id &&
                        item.type === elem.relationships.element.data.type,
                ),
                included,
            )
        }

        if (included && elem.relationships?.hierarchy?.data) {
            this._hierarchy = new HierarchyModel(
                included.find(
                    (item) =>
                        item.id === elem.relationships.hierarchy.data.id &&
                        item.type === elem.relationships.hierarchy.data.type,
                ),
                included,
            )
        }

        if (included && elem.relationships?.period?.data) {
            this._period = new PeriodModel(
                included.find(
                    (item) =>
                        item.id === elem.relationships.period.data.id &&
                        item.type === elem.relationships.period.data.type,
                ),
                included,
            )
        }

        if (included && elem.relationships?.geographic?.data) {
            this._planningGeographic = new PlanningGeographicsModel(
                included.find(
                    (item) =>
                        item.id === elem.relationships.geographic.data.id &&
                        item.type === elem.relationships.geographic.data.type,
                ),
                included,
            )
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get planningId(): number {
        return this._planningId
    }

    set planningId(value) {
        this._planningId = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get planningElementClassificationId(): number {
        return this._planningElementClassificationId
    }

    set planningElementClassificationId(value) {
        this._planningElementClassificationId = value
    }

    get hierarchyId(): number {
        return this._hierarchyId
    }

    set hierarchyId(value) {
        this._hierarchyId = value
    }

    get planningGeographicId_1(): number | null {
        return this._planningGeographicId
    }
    set planningGeographicId_1(value: number | null) {
        this._planningGeographicId = value
    }
    get planningGeographicId(): number | null {
        return this._planningGeographicId
    }
    set planningGeographicId(value: number | null) {
        this._planningGeographicId = value
    }
    get elementId(): number {
        return this._elementId
    }
    set elementId(value: number) {
        this._elementId = value
    }
    private _periodId: number | null
    get periodId(): number | null {
        return this._periodId
    }
    set periodId(value: number | null) {
        this._periodId = value
    }
    get price(): number | null {
        return this._price
    }
    set price(value: number | null) {
        this._price = value
    }
    get ots(): number | null {
        return this._ots
    }
    set ots(value: number | null) {
        this._ots = value
    }
    get cpm(): number | null {
        return this._cpm
    }
    set cpm(value: number | null) {
        this._cpm = value
    }
    get created_at(): string | null {
        return this._created_at
    }
    set created_at(value: string | null) {
        this._created_at = value
    }

    get element(): ElementModel {
        return this._element
    }
    set element(value: ElementModel) {
        this._element = value
    }

    get hierarchy(): HierarchyModel {
        return this._hierarchy
    }
    set hierarchy(value: HierarchyModel) {
        this._hierarchy = value
    }

    get otsEstimated(): number | string | null {
        return this._otsEstimated
    }
    set otsEstimated(value: number | string | null) {
        this._otsEstimated = value
    }

    get cpmEstimated(): number | string | null {
        return this._cpmEstimated
    }
    set cpmEstimated(value: number | string | null) {
        this._cpmEstimated = value
    }

    get period(): PeriodModel {
        return this._period
    }
    set period(value: PeriodModel) {
        this._period = value
    }

    get planningGeographic(): PlanningGeographicsModel {
        return this._planningGeographic
    }
    set planningGeographic(value: PlanningGeographicsModel) {
        this._planningGeographic = value
    }
}
