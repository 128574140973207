import { ElementType } from '../../../Types/result/assetsList/ElementType'
import { AddressModel } from '../../AddressModel'
import { CityModel } from '../../CityModel'
import { LightingTypesModel } from '../../element/LightingTypesModel'
import { OrganizationsModel } from '../../element/OrganizationsModel'
import { TrafficModel } from '../../element/TrafficModel'
import { UnitMeasureModel } from '../../element/UnitMeasureModel'
import ImageModel from '../../ImageModel'
import { HierarchyModel } from '../mix/HierarchyModel'

export class ElementModel {
    private _id: number
    private _type: string
    private _minimumAmount: number | null
    private _code: string | null
    private _ots: number | null
    private _adsPerDay: number | null
    private _maxCampaings: number | null
    private _rotated: any | null
    private _powerOnTime: any | null
    private _requiresAccess: any | null
    private _hasLighting: any | null
    private _observations: string | null
    private _advertisingTax: any | null
    private _commercialHeight: any | null
    private _commercialWidth: any | null
    private _addressesId: number | null
    private _socioeconomicLevelId: number | null
    private _trafficId: number | null
    private _orientationId: number | null
    private _contentTypeId: number | null
    private _lightingTypeId: number | null
    private _elementClassificationId: number | null
    private _environmentId: number | null
    private _elementStructureId: number | null
    private _periodsId: number | null
    private _hierarchyId: number | null
    private _companyId: number | null
    private _externalId: number | null
    private _createdAt: string | null
    private _address: AddressModel | null
    private _city: CityModel | null
    private _image: ImageModel
    private _lightingType: LightingTypesModel
    private _traffic: TrafficModel
    private _unitMeasureId: number | null
    private _hierarchy?: HierarchyModel | null
    private _unitMeasure?: UnitMeasureModel | null
    private _vendorId?: number | null
    private _vendor?: OrganizationsModel

    constructor(elem: ElementType, included: any) {
        this._id = elem.id
        this._type = elem.type
        this._minimumAmount = elem.attributes.minimum_amount
        this._code = elem.attributes.code
        this._ots = elem.attributes.ots
        this._adsPerDay = elem.attributes.ads_per_day
        this._maxCampaings = elem.attributes.max_campaings
        this._rotated = elem.attributes.rotated
        this._powerOnTime = elem.attributes.power_on_time
        this._requiresAccess = elem.attributes.requires_access
        this._hasLighting = elem.attributes.has_lighting
        this._observations = elem.attributes.observations
        this._advertisingTax = elem.attributes.advertising_tax
        this._commercialHeight = elem.attributes.commercial_height
        this._commercialWidth = elem.attributes.commercial_width
        this._addressesId = elem.attributes.addresses_id
        this._socioeconomicLevelId = elem.attributes.socioeconomic_level_id
        this._trafficId = elem.attributes.traffic_id
        this._orientationId = elem.attributes.orientation_id
        this._contentTypeId = elem.attributes.content_type_id
        this._lightingTypeId = elem.attributes.lighting_type_id
        this._elementClassificationId =
            elem.attributes.element_classification_id
        this._environmentId = elem.attributes.environment_id
        this._elementStructureId = elem.attributes.element_structure_id
        this._periodsId = elem.attributes.periods_id
        this._hierarchyId = elem.attributes.hierarchy_id
        this._companyId = elem.attributes.company_id
        this._externalId = elem.attributes.external_id
        this._createdAt = elem.attributes.created_at
        this._unitMeasureId = elem.attributes.unit_measure_id
        this._vendorId = elem.attributes?.vendor_id

        if (included && elem.relationships.address?.data) {
            this._address = new AddressModel(
                included.find(
                    (address) =>
                        address.id === elem.relationships.address.data.id &&
                        address.type === elem.relationships.address.data.type,
                ),
            )
        }

        if (included && elem.relationships.vendor?.data) {
            this._vendor = new OrganizationsModel(
                included.find(
                    (vendor) =>
                        vendor.id === elem.relationships.vendor.data.id &&
                        vendor.type === elem.relationships.vendor.data.type,
                ),
            )
        }

        if (included && elem.relationships.city?.data) {
            this._city = new CityModel(
                included.find(
                    (city) =>
                        city.id === elem.relationships.city.data.id &&
                        city.type === elem.relationships.city.data.type,
                ),
            )
        }

        if (included && elem.relationships?.hierarchy?.data) {
            this._hierarchy = new HierarchyModel(
                included.find(
                    (item) =>
                        item.id === elem.relationships.hierarchy.data.id &&
                        item.type === elem.relationships.hierarchy.data.type,
                ),
                included,
            )
        }

        if (included && elem.relationships?.lightingType?.data) {
            this._lightingType = new LightingTypesModel(
                included.find(
                    (lighting) =>
                        lighting.id ===
                            elem.relationships?.lightingType?.data?.id &&
                        lighting.type ===
                            elem.relationships?.lightingType?.data?.type,
                ),
            )
        }

        if (included && elem.relationships?.traffic?.data) {
            this._traffic = new TrafficModel(
                included.find(
                    (traffic) =>
                        traffic.id === elem.relationships?.traffic?.data?.id &&
                        traffic.type ===
                            elem.relationships?.traffic?.data?.type,
                ),
            )
        }

        if (included && elem.relationships?.image?.data) {
            this._image = new ImageModel(
                included.find(
                    (image) =>
                        image.id === elem.relationships.image.data.id &&
                        image.type === elem.relationships.image.data.type,
                ),
            )
        }

        if (included && elem.relationships?.unit_measure?.data) {
            this._unitMeasure = new UnitMeasureModel(
                included.find(
                    (unitMeasure) =>
                        unitMeasure.id ===
                            elem.relationships.unit_measure.data.id &&
                        unitMeasure.type ===
                            elem.relationships.unit_measure.data.type,
                ),
            )
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get hierarchy(): HierarchyModel {
        return this._hierarchy
    }
    set hierarchy(value: HierarchyModel) {
        this._hierarchy = value
    }

    get unitMeasure(): UnitMeasureModel {
        return this._unitMeasure
    }
    set unitMeasure(value: UnitMeasureModel) {
        this._unitMeasure = value
    }

    get unitMeasureId(): number | null {
        return this._unitMeasureId
    }
    set unitMeasureId(value: number | null) {
        this._unitMeasureId = value
    }

    get lightingType(): LightingTypesModel {
        return this._lightingType
    }
    set lightingType(value: LightingTypesModel) {
        this._lightingType = value
    }
    get traffic(): TrafficModel {
        return this._traffic
    }
    set traffic(value: TrafficModel) {
        this._traffic = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get minimumAmount(): number {
        return this._minimumAmount
    }

    set minimumAmount(value) {
        this._minimumAmount = value
    }

    get code(): string {
        return this._code
    }

    set code(value) {
        this._code = value
    }

    get ots(): number {
        return this._ots
    }

    set ots(value) {
        this._ots = value
    }

    get adsPerDay(): number {
        return this._adsPerDay
    }

    set adsPerDay(value) {
        this._adsPerDay = value
    }

    get maxCampaings(): number {
        return this._maxCampaings
    }

    set maxCampaings(value) {
        this._maxCampaings = value
    }

    get rotated(): any {
        return this._rotated
    }

    set rotated(value) {
        this._rotated = value
    }

    get powerOnTime(): any {
        return this._powerOnTime
    }

    set powerOnTime(value) {
        this._powerOnTime = value
    }

    get requiresAccess(): any {
        return this._requiresAccess
    }

    set requiresAccess(value) {
        this._requiresAccess = value
    }

    get hasLighting(): any {
        return this._hasLighting
    }

    set hasLighting(value) {
        this._hasLighting = value
    }

    get observations(): string {
        return this._observations
    }

    set observations(value) {
        this._observations = value
    }

    get advertisingTax(): any {
        return this._advertisingTax
    }

    set advertisingTax(value) {
        this._advertisingTax = value
    }

    get commercialHeight(): any {
        return this._commercialHeight
    }

    set commercialHeight(value) {
        this._commercialHeight = value
    }

    get commercialWidth(): any {
        return this._commercialWidth
    }

    set commercialWidth(value) {
        this._commercialWidth = value
    }

    get addressesId(): number {
        return this._addressesId
    }

    set addressesId(value) {
        this._addressesId = value
    }

    get socioeconomicLevelId(): number {
        return this._socioeconomicLevelId
    }

    set socioeconomicLevelId(value) {
        this._socioeconomicLevelId = value
    }

    get trafficId(): number {
        return this._trafficId
    }

    set trafficId(value) {
        this._trafficId = value
    }

    get orientationId(): number {
        return this._orientationId
    }

    set orientationId(value) {
        this._orientationId = value
    }

    get contentTypeId(): number {
        return this._contentTypeId
    }

    set contentTypeId(value) {
        this._contentTypeId = value
    }

    get lightingTypeId(): number {
        return this._lightingTypeId
    }

    set lightingTypeId(value) {
        this._lightingTypeId = value
    }

    get elementClassificationId(): number {
        return this._elementClassificationId
    }

    set elementClassificationId(value) {
        this._elementClassificationId = value
    }

    get environmentId(): number {
        return this._environmentId
    }

    set environmentId(value) {
        this._environmentId = value
    }

    get elementStructureId(): number {
        return this._elementStructureId
    }

    set elementStructureId(value) {
        this._elementStructureId = value
    }

    get periodsId(): number {
        return this._periodsId
    }

    set periodsId(value) {
        this._periodsId = value
    }

    get hierarchyId(): number {
        return this._hierarchyId
    }

    set hierarchyId(value) {
        this._hierarchyId = value
    }

    get companyId(): number {
        return this._companyId
    }

    set companyId(value) {
        this._companyId = value
    }

    get externalId(): number {
        return this._externalId
    }

    set externalId(value) {
        this._externalId = value
    }

    get createdAt(): string {
        return this._createdAt
    }

    set createdAt(value) {
        this._createdAt = value
    }

    get address(): AddressModel | null {
        return this._address
    }
    set address(value: AddressModel | null) {
        this._address = value
    }

    get image(): ImageModel | null {
        return this._image
    }
    set image(value: ImageModel | null) {
        this._image = value
    }

    get city(): CityModel | null {
        return this._city
    }
    set city(value: CityModel | null) {
        this._city = value
    }

    get vendorId(): number | null {
        return this._vendorId
    }
    set vendorId(value: number | null) {
        this._vendorId = value
    }

    get vendor(): OrganizationsModel | null {
        return this._vendor
    }
    set vendor(value: OrganizationsModel | null) {
        this._vendor = value
    }
}
