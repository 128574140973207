import { HierarchyType } from '../../../Types/result/mix/HierarchyType'
import ImageModel from '../../ImageModel'

export class HierarchyModel {
    private _id: number
    private _type: string
    private _name: string
    private _hierarchyTypeId: number
    private _parentId: number
    private _companyId: number
    private _externalId: number
    private _image: ImageModel | null
    private _hierarchy: HierarchyModel | null
    private _colour?: string
    private _acronym?: string

    constructor(hierarchy: HierarchyType, included: any) {
        this._id = hierarchy.id
        this._type = hierarchy.type
        this._name = hierarchy.attributes.name
        this._hierarchyTypeId = hierarchy.attributes.hierarchy_type_id
        this._parentId = hierarchy.attributes.parent_id
        this._companyId = hierarchy.attributes.company_id
        this._externalId = hierarchy.attributes.external_id
        this._colour = hierarchy.attributes.colour
        this._acronym = hierarchy.attributes.acronym

        if (hierarchy.relationships.image && included) {
            this._image = new ImageModel(
                included.find(
                    (item) =>
                        item.id === hierarchy.relationships.image.data.id &&
                        item.type === hierarchy.relationships.image.data.type,
                ),
            )
        }

        if (hierarchy.attributes.parent_id && included) {
            this._hierarchy = new HierarchyModel(
                included.find(
                    (item) =>
                        item.id === hierarchy.attributes.parent_id &&
                        item.type === 'hierarchies',
                ),
                included,
            )
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get hierarchy(): HierarchyModel {
        return this._hierarchy
    }
    set hierarchy(value: HierarchyModel) {
        this._hierarchy = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get name(): string {
        return this._name
    }
    set name(value: string) {
        this._name = value
    }

    get hierarchyTypeId(): number {
        return this._hierarchyTypeId
    }
    set hierarchyTypeId(value: number) {
        this._hierarchyTypeId = value
    }

    get parentId(): number {
        return this._parentId
    }
    set parentId(value: number) {
        this._parentId = value
    }
    get companyId(): number {
        return this._companyId
    }
    set companyId(value: number) {
        this._companyId = value
    }
    get externalId(): number {
        return this._externalId
    }
    set externalId(value: number) {
        this._externalId = value
    }
    get image(): ImageModel {
        return this._image
    }
    set image(value: ImageModel) {
        this._image = value
    }

    get colour(): string {
        return this._colour
    }
    set colour(value: string) {
        this._colour = value
    }
    get acronym(): string {
        return this._acronym
    }
    set acronym(value: string) {
        this._acronym = value
    }
}
