// @ts-ignore
import { ColorList } from '@tdt-global/styleguide'

import { useEffect, useState } from 'react'
import { ProposalGeographicsModel } from '../../../Models/ProposalGeographicsModel'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

type PropType = {
    radius?: number
    label?: boolean
    legend?: boolean
    distribution: ProposalGeographicsModel[]
    legendPosition?: Highcharts.AlignValue
}

type HighChartsValue = {
    name: string
    y: number
}

export const ChartBudget = ({
    legend = true,
    distribution,
    legendPosition = 'center',
}: PropType) => {
    const [getData, setGetData] = useState<HighChartsValue[]>([
        { name: '', y: 0 },
    ])

    useEffect(() => {
        setGetData(
            distribution?.map((item) => ({
                name: item.city.name,
                y: item.budget,
            })),
        )
    }, [distribution])

    Highcharts.setOptions({
        lang: {
            thousandsSep: '.',
        },
    })

    let color = ColorList()

    const options: Highcharts.Options = {
        chart: {
            backgroundColor: 'none',
            spacingBottom: 10,
        },
        title: {
            text: '',
        },
        tooltip: {
            pointFormat: '{point.y:,.0f}',
        },

        plotOptions: {
            pie: {
                size: '100%',
                colors: color,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: { enabled: false },
                showInLegend: true,
                point: {
                    events: {
                        legendItemClick: function (e) {
                            e.preventDefault()
                        },
                    },
                },
            },

            series: {
                states: {
                    hover: {
                        enabled: true,
                    },
                    inactive: {
                        opacity: 1,
                    },
                },
            },
        },

        legend: {
            symbolHeight: 8,
            itemMarginBottom: 5,
            itemWidth: 120,
            enabled: legend,
            align: legendPosition,
            verticalAlign: legendPosition === 'center' ? 'bottom' : 'middle',
            layout: legendPosition === 'center' ? 'horizontal' : 'vertical',
            symbolPadding: 10,
            symbolWidth: 0,
            x: 0,
            y: 0,
            itemStyle: {
                fontWeight: 'normal',
                color: '#71678B',
                fontFamily: 'Segoe UI, Regular',
                fontSize: '13px',
                textAlign: 'left',
            },
        },

        series: [
            {
                type: 'pie',
                data: getData,
            },
        ],
        credits: {
            enabled: false,
        },
    }

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </>
    )
}
