import {
  DeleteOutlined,
  EyeOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
// @ts-ignore
import { Table, Tooltip } from "antd";
import classNames from "classnames";
import ResizeObserver from "rc-resize-observer";
import React, { useEffect, useRef, useState } from "react";
import { VariableSizeGrid as Grid } from "react-window";
import { PlanningElementModel } from "../../../../../../../Models/result/assetsList/PlanningElementModel";
// @ts-ignore
import { SpinnerBar, ButtonsModalConfirm } from "@tdt-global/styleguide";
//@ts-ignore
import { utility } from "@tdt-global/utility";
import { HeaderOptimizationModel } from "../../../../../../../Models/HeaderOptimizationModel";
import { PlanningElementsGeographicsModel } from "../../../../../../../Models/result/assetsList/PlanningElementsGeographicsModel";
import { PlanningElementClassificationsModel } from "../../../../../../../Models/result/mix/PlanningElementClassificationsModel";
import { BodyModal } from "./BodyModal";
import { ShowModalType } from "../../../../../../../Types/ShowModalType";
import { Endpoints } from "../../../../../../../Common/Endpoints";

type PropType = {
  list: PlanningElementModel[];
  loading: boolean;
  panel: PlanningElementClassificationsModel;
  proposal: HeaderOptimizationModel;
  elementsClass: PlanningElementsGeographicsModel;
  loadResult: () => void;
  handleReplaceDrawer: (id: number) => void;
};

const VirtualTable = (props: any) => {
  const { columns, scroll, loading } = props;
  const [tableWidth, setTableWidth] = useState(0);

  const widthColumnCount = columns!.filter(({ width }) => !width).length;
  let sumWidth = 0;
  const mergedColumns = columns!.map((column, index) => {
    if (column.width) {
      sumWidth += Number(column.width);
      return column;
    }

    if (index === columns.length - 1) {
      return {
        ...column,
        width:
          Math.floor(Math.abs(tableWidth - sumWidth) / widthColumnCount) - 8,
      };
    } else
      return {
        ...column,
        width: Math.floor(Math.abs(tableWidth - sumWidth) / widthColumnCount),
      };
  });

  const gridRef = useRef<any>();
  const [connectObject] = useState<any>(() => {
    const obj = {};
    Object.defineProperty(obj, "scrollLeft", {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    });

    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef.current.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  const renderVirtualList = (
    rawData: object[],
    { scrollbarSize, ref, onScroll }: any
  ) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * 142;

    const Cell = ({ columnIndex, rowIndex, style }) => {
      let aux = (mergedColumns as any)[columnIndex].render(
        rawData[rowIndex],
        rawData[rowIndex]
      );

      return (
        <div
          className={classNames("virtual-table-cell", {
            "virtual-table-cell-last": columnIndex === mergedColumns.length - 1,
          })}
          style={style}
        >
          {aux}
        </div>
      );
    };

    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={(index: number) => {
          const { width } = mergedColumns[index];
          return totalHeight > scroll!.y! && index === mergedColumns.length - 1
            ? (width as number) - scrollbarSize - 1
            : (width as number);
        }}
        height={scroll!.y as number}
        rowCount={rawData.length}
        rowHeight={() => 142}
        width={tableWidth}
        onScroll={({ scrollLeft }: { scrollLeft: number }) => {
          onScroll({ scrollLeft });
        }}
      >
        {Cell}
      </Grid>
    );
  };
  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <SpinnerBar loading={loading}></SpinnerBar>
      <Table
        {...props}
        loading={{
          spinning: loading,
          indicator: null,
        }}
        className="virtual-table"
        id="assets_table"
        columns={mergedColumns}
        pagination={false}
        components={{
          body: renderVirtualList,
        }}
      />
    </ResizeObserver>
  );
};

// Usage

export const PanelTable = ({
  list,
  panel,
  loading,
  proposal,
  elementsClass,
  loadResult,
  handleReplaceDrawer,
}: PropType) => {
  const { Functions, ShowImage, useAxios, ShowModal } = utility;
  const { deleteHttpRequest } = useAxios();
  const [deleteSpinner, setDeleteSpinner] = useState(false);
  const [visibleModal, setVisibleModal] = useState({
    visible: false,
    element: null,
  });
  const [propsModal, setPropsModal] = useState<ShowModalType>({
    show: false,
    title: "",
    className: "",
    hideModal: null,
    component: null,
    modalProps: {},
    confirmLoading: false,
  });

  const handleClickDelete = (rowData: any) => {
    const handleOk = () => {
      setDeleteSpinner(true);

      deleteHttpRequest(
        Endpoints.DEL_RESULT_ASSETS_BY_ID.replace(
          "{id}",
          rowData.id ? rowData.id.toString() : rowData.element.id.toString()
        ),
        {},
        (response: any) => {
          Functions.openNotificationSuccess("Soporte eliminado.", "Éxito");
          setPropsModal({
            show: false,
            ...propsModal,
          });
          loadResult();
        },
        (error) => {
          Functions.openNotificationError(
            Functions.errorObject(error),
            "Error"
          );
        },
        () => {
          setDeleteSpinner(false);
        }
      );
    };
    setPropsModal({
      show: true,
      className: "modal-confirm",
      title: "Eliminar soporte",
      component: (
        <>
          Estás por eliminar el soporte. Esta acción no puede deshacerse.
          ¿Deseas eliminar el soporte?
          <ButtonsModalConfirm
            okText="Eliminar"
            cancelText="Cancelar"
            loading={deleteSpinner}
            handleOK={handleOk}
            handleCancel={hideModal}
          />
        </>
      ),
      hideModal,
      modalProps: {
        closable: false,
        width: 588,
        footer: null,
      },
    });
  };

  const hideModal = () => {
    setPropsModal({
      show: false,
      ...propsModal,
    });
  };

  const handleClickMap = (rowData: any) => {
    console.log(rowData);
  };

  const handleClickReplace = (rowData: PlanningElementModel) => {
    console.log(rowData);
    handleReplaceDrawer(rowData.id);
  };

  const columns: any = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      // fixed: 'left',
      width: 244,
      ellipsis: {
        showTitle: false,
      },
      render: (image: any, item: PlanningElementModel) => (
        <div className="assets-image">
          <ShowImage url={item?.element?.image?.dir} isAnimated={true} />
        </div>
      ),
    },

    {
      title: "",
      dataIndex: "type",
      width: 200,
      key: "type",
      ellipsis: {
        showTitle: false,
      },
      render: (type: any, item: PlanningElementModel) => (
        <div>
          <div className="bold gray">{panel?.hierarchy?.name}</div>
          <div className="ellipsis-two-lines">{`${panel?.hierarchy?.hierarchy?.name} / ${panel?.hierarchy?.hierarchy?.hierarchy?.name}`}</div>
          <div className="ellipsis-two-lines text-min">
            {item?.element?.address?.address}
          </div>
        </div>
      ),
    },

    {
      title: "OTS",
      dataIndex: "ots",
      key: "ots",
      // width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (ots: any, item: PlanningElementModel) => {
        return (
          <div>
            <div>{Functions.formatNumber(item?.otsEstimated)}</div>
            <div className="text-min" style={{ marginBottom: "4px" }}>
              Totales
            </div>

            <div className="box flex g-2">
              <div className="bold">{Functions.formatNumber(item?.ots)}</div>
            </div>
            <div className="text-min">Por audiencia</div>
          </div>
        );
      },
    },

    {
      title: "CPM",
      dataIndex: "cpm_audience",
      key: "cpm_audience",
      // width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (cpm: any, item: PlanningElementModel) => {
        return (
          <div>
            <div>
              <div className="flex g-2">
                <div>{Functions.formatNumber(item?.cpmEstimated)}</div>
                <span className="text">
                  {proposal?.proposal_budget?.currency?.iso_code}
                </span>
              </div>
              <div className="text-min" style={{ marginBottom: "4px" }}>
                Totales
              </div>

              <div className="box flex g-2">
                <div className="bold">{Functions.formatNumber(item?.cpm)}</div>
                <span className="text">
                  {proposal?.proposal_budget?.currency?.iso_code}
                </span>
              </div>
              <div className="text-min">Por audiencia</div>
            </div>
          </div>
        );
      },
    },

    {
      title: "Medidas",
      dataIndex: "especifications",
      key: "especifications",
      // width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (especifications: any, item: PlanningElementModel) => {
        return (
          <div>
            <div>
              {Functions.formatNumber(item?.element?.commercialHeight)}
              <span className="m-letter">
                {item.element?.unitMeasure?.symbol}
              </span>
            </div>
            <div className="text-min" style={{ marginBottom: "4px" }}>
              Alto
            </div>
            <div>
              {Functions.formatNumber(item?.element?.commercialWidth)}
              <span className="m-letter">
                {item.element?.unitMeasure?.symbol}
              </span>
            </div>
            <div className="text-min">Ancho</div>
          </div>
        );
      },
    },

    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      // width: 195,
      ellipsis: {
        showTitle: false,
      },
      render: (price: any, item: PlanningElementModel) => {
        let text = `${Functions.formatNumber(item?.price)} ${
          proposal?.proposal_budget?.currency?.iso_code
        }`;

        return (
          <div className="box flex g-2">
            <div className="bold">{Functions.formatNumber(item?.price)}</div>
            <span className="text">
              {proposal?.proposal_budget?.currency?.iso_code}
            </span>
          </div>
        );
      },
    },

    {
      title: "Vendor",
      dataIndex: "provider",
      key: "provider",
      // width: 195,
      ellipsis: {
        showTitle: false,
      },
      render: (price: any, item: PlanningElementModel) => {
        return <div>{item?.element?.vendor?.aliasName}</div>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      ellipsis: {
        showTitle: false,
      },
      // width: 150,
      // fixed: 'right',
      render: (_: any, item: any) => (
        <div className={"actions-table"}>
          <Tooltip placement="top" title="Ver soporte">
            <div
              className="icon-button"
              onClick={() =>
                setVisibleModal({
                  element: item,
                  visible: true,
                })
              }
            >
              <EyeOutlined />
            </div>
          </Tooltip>
          {/* <Tooltip placement='top' title='Ubicar en el mapa'>
                        <div
                            className='icon-button'
                            onClick={() => handleClickMap(item)}>
                            <EnvironmentOutlined />
                        </div>
                    </Tooltip> */}
          <Tooltip placement="top" title="Reemplazar">
            <div
              className="icon-button"
              onClick={() => handleClickReplace(item)}
            >
              <RetweetOutlined />
            </div>
          </Tooltip>
          <Tooltip placement="top" title="Eliminar">
            <div
              className="icon-button"
              onClick={() => handleClickDelete(item)}
            >
              <DeleteOutlined />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getHeight = () => {
    let cant = panel.elementsList?.length;
    let height = 600;
    if (cant < 5) {
      height = cant * 142;
    }
    return height;
  };
  return (
    <>
      <Table
        columns={columns}
        dataSource={list}
        pagination={false}
        loading={{
          spinning: loading,
          indicator: null,
        }}
        className="virtual-table"
        id="assets_table"
      />
      <BodyModal
        visibleModal={visibleModal}
        proposal={proposal}
        panel={panel}
        elementsClass={elementsClass}
        handleOk={() => handleClickDelete(visibleModal.element)}
        setVisibleModal={setVisibleModal}
        handleReplaceDrawer={handleReplaceDrawer}
      />
      <ShowModal {...propsModal} />
    </>
  );
};
