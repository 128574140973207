import { DataMapType } from '../../../Types/result/map/DataMapType'
import { PlanningElementModel } from '../assetsList/PlanningElementModel'

export class MapModel {
    private _planningElements: PlanningElementModel[]

    constructor(map: any, included: any) {
        this._planningElements = map.data.map(
            (item) => new PlanningElementModel(item, included),
        )
    }

    get getDataMap(): DataMapType[] {
        let data = this._planningElements.map((item) => ({
            id: item.id,
            elementId: item.elementId,
            hierarchyId: item.hierarchy.id,
            geolocation: item.element?.address?.geolocation,
            icon: {
                label: item.hierarchy?.acronym,
                color: item.hierarchy?.colour,
            },
            ots: item.otsEstimated,
            image: item.element?.image?.dir,
            info: {
                type: item.hierarchy?.name,
                subCategory: item.hierarchy?.hierarchy?.name,
                category: item.hierarchy?.hierarchy?.hierarchy?.name,
                budget: item.price,
            },
            description: {
                cpm: item.cpmEstimated,
                code: item.element?.code,
                material: null,
                contract: item.period?.daysQuantity.toString(),
                city: item.planningGeographic?.city?.name,
                address: item.element?.address?.address,
            },
            trafic: item.element?.traffic?.name,
            light: item.element?.lightingType?.name,
        }))
        return data
    }

    get planningElements(): PlanningElementModel[] {
        return this._planningElements
    }

    set planningElements(value) {
        this._planningElements = value
    }
}
