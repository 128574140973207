import { UserType } from '../Types/UserType'

export class UserModel {
    private _id: string | null
    private _key: string | null
    private _username: string | null
    private _type: string | null
    private _email: string | null
    private _first_name: string | null
    private _last_name: string | null

    constructor(user?: UserType) {
        this._id = user.id
        this._key = user.id
        this._username = user.attributes.username
        this._type = user.type
        this._email = user.attributes.email
        this._first_name = user.attributes.first_name
        this._last_name = user.attributes.last_name
    }

    get id(): string {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get key(): string {
        return this._key
    }

    set key(value) {
        this._key = value
    }

    get username(): string {
        return this._username
    }

    set username(value) {
        this._username = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get email(): string {
        return this._email
    }

    set email(value) {
        this._email = value
    }

    get first_name(): string {
        return this._first_name
    }

    set first_name(value) {
        this._first_name = value
    }

    get last_name(): string {
        return this._last_name
    }

    set last_name(value) {
        this._last_name = value
    }
}
