//@ts-ignore
import { utility } from '@tdt-global/utility'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PlanificationList } from './Components/PlanificationHome/PlanificationList'
import './Assets/Index.scss'
import 'mapbox-gl/dist/mapbox-gl.css'
import { NewPlanification } from './Components/NewPlanification/NewPlanification'
import Breadcrumb from './Components/Breadcrumb'
import { Path } from './Common/Path'

export default function Root(props) {
    const { RenderIfToken } = utility

    return (
        <RenderIfToken>
            <div id='tdt-global-planning'>
                <BrowserRouter>
                    <Breadcrumb path={props.name} />
                    <Routes>
                        <Route
                            path={Path.PLANNING}
                            element={<PlanificationList />}
                        />
                        <Route
                            path={Path.NEW_PLANNING}
                            element={<NewPlanification />}
                        />
                    </Routes>
                </BrowserRouter>
            </div>
        </RenderIfToken>
    )
}
