// @ts-ignore
import { ColorList } from '@tdt-global/styleguide'
// @ts-ignore
import { Skeleton } from 'antd'
import { G2 } from '@ant-design/plots'
import { ElementClassificationsType } from '../../../../../../../Types/ElementClassificationsType'
//@ts-ignore
import { utility } from '@tdt-global/utility'

import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

type PropType = {
    load: boolean
    valueToShow: string
    classifications: ElementClassificationsType[]
}

export const InvestmentTypeSupport = ({
    load,
    classifications,
    valueToShow,
}: PropType) => {
    const G = G2.getEngine('canvas')
    const data = classifications?.map((item) => ({
        name: item.label,
        y: Number(item[valueToShow]),
    }))

    Highcharts.setOptions({
        lang: {
            thousandsSep: '.',
        },
    })

    let color = ColorList()

    const config: any = {
        chart: {
            backgroundColor: 'none',
        },
        title: {
            text: '',
        },
        lang: {
            thousandsSep: '.',
        },
        tooltip: {
            pointFormat: '{point.y}',
        },

        legend: {
            itemWidth: 130,
            y: 0,
            itemStyle: {
                fontWeight: 'normal',
                color: '#71678B',
                fontFamily: 'Segoe UI, Regular',
                fontSize: '13px',
            },
        },
        plotOptions: {
            pie: {
                size: '70%',
                colors: color,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    fontWeight: 'normal',
                    useHTML: true,
                    enabled: true,
                    format: `<span style=color:{point.color}>{point.name}</span> <br> <span style=font-weight:lighter>{point.y:,.0f} - {point.percentage:,.1f} %</span>`,
                    style: {
                        fontFamily: 'Segoe UI',
                        fontSize: '13px',
                    },
                },
                point: {
                    events: {
                        legendItemClick: function (e) {
                            e.preventDefault()
                        },
                    },
                },
            },
            series: {
                states: {
                    hover: {
                        enabled: true,
                    },
                },
            },
        },

        series: [
            {
                type: 'pie',
                data: data,
                formatter: function () {
                    return Highcharts.numberFormat(this.y, 0)
                },
            },
        ],
        credits: {
            enabled: false,
        },
    }

    return (
        <div className='investment-type-support'>
            <div className='body'>
                {!load ? (
                    <>
                        <div className='chart'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={config}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className='chart'>
                            <Skeleton.Avatar
                                active
                                size={'large'}
                                shape={'circle'}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
