import { DeleteOutlined, EyeOutlined, RetweetOutlined } from '@ant-design/icons'
import { Table, Skeleton } from 'antd'
import { PlanningElementModel } from '../../../../../../../../Models/result/assetsList/PlanningElementModel'

type PropType = {
    list: PlanningElementModel[]
    loading: boolean
}

export const SkeletonTable = ({ list, loading }: PropType) => {
    const dataSkeleton = [{ key: 0 }, { key: 1 }, { key: 2 }, { key: 3 }]

    const columns: any = [
        {
            title: '',
            dataIndex: 'image',
            key: 'image',
            // fixed: 'left',
            width: 244,
            ellipsis: {
                showTitle: false,
            },
            render: () => (
                <Skeleton.Avatar
                    shape='square'
                    active
                    style={{ height: '122px', width: '160px' }}
                />
            ),
        },

        {
            title: '',
            dataIndex: 'type',
            width: 280,
            key: 'type',
            ellipsis: {
                showTitle: false,
            },
            render: () => (
                <div className='skeleton-container'>
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                    <Skeleton.Button active />
                </div>
            ),
        },

        {
            title: 'OTS',
            dataIndex: 'ots',
            key: 'ots',
            // width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: () => {
                return (
                    <div className='skeleton-container'>
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                        <Skeleton.Button active className='skeleton-gray' />
                        <Skeleton.Button active />
                    </div>
                )
            },
        },

        {
            title: 'CPM',
            dataIndex: 'cpm_audience',
            key: 'cpm_audience',
            // width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: () => {
                return (
                    <div className='skeleton-container'>
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                        <Skeleton.Button active className='skeleton-gray' />
                        <Skeleton.Button active />
                    </div>
                )
            },
        },

        {
            title: 'Medidas',
            dataIndex: 'especifications',
            key: 'especifications',
            // width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: () => {
                return (
                    <div className='skeleton-container'>
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                    </div>
                )
            },
        },

        {
            title: 'Precio',
            dataIndex: 'price',
            key: 'price',
            // width: 195,
            ellipsis: {
                showTitle: false,
            },
            render: () => {
                return (
                    <div className='skeleton-container'>
                        <Skeleton.Button active className='skeleton-gray' />
                    </div>
                )
            },
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            ellipsis: {
                showTitle: false,
            },
            // width: 150,
            // fixed: 'right',
            render: () => <div className='actions-table'>
                        <div
                            className='icon-button'>
                            <EyeOutlined />
                        </div>
                        <div
                            className='icon-button'>
                            <RetweetOutlined />
                        </div>
                        <div
                            className='icon-button'>
                            <DeleteOutlined />
                        </div>
                </div>
        },
    ]

    return (
        <Table
            columns={columns}
            dataSource={dataSkeleton}
            pagination={false}
            loading={{
                spinning: loading,
                indicator: null,
            }}
            className='virtual-table'
            id='assets_table'
        />
    )
}
