import { Header } from './Header'
import { PlanificationTable } from './PlanificationTable'
import { useState } from 'react'

// @ts-ignore
import { Alert } from 'antd'
// @ts-ignore
import type { PaginationProps } from 'antd'
import { ResponseType } from '../../Types/ResponseType'
import { ProposalModel } from '../../Models/ProposalModel'

export const PlanificationList = () => {
    const [planList, setPlanList] = useState<any[] | null>(null)
    const [planSelected, setPlanSelected] = useState<any>({ ids: [] })
    const [nextPage, setNextPage] = useState<number>(1)
    const [loading, setLoading] = useState(false)
    const [sizePage, setSizePage] = useState<number>(25)
    const [showing, setShowing] = useState({
        total: 0,
        totaPages: 0,
    })
    const onChange = (page) => {
        if (page !== nextPage) {
            setNextPage(page)
            scrollTop()
        }
    }

    const onChangeSizePage = (pageSize: string) => {
        if (Number(pageSize) !== sizePage) {
            setSizePage(Number(pageSize))
            setNextPage(1)
            scrollTop()
        }
    }

    const scrollTop = () => {
        const node = document
            .getElementById('planification_table')
            ?.getElementsByClassName('ant-table-body')[0]
        if (node) {
            node.scrollTo({ top: 0 })
        }
    }

    const success = (response: ResponseType) => {
        const { current_page, per_page, total_items, total_pages } =
            response.data.meta

        setPlanList(
            response.data.data.map(
                (item: any) => new ProposalModel(item, response.data.included),
            ),
        )
        setShowing({
            total: total_items,
            totaPages: total_pages,
        })
    }

    return (
        <div className='planification-home'>
            <Header
                showing={showing}
                onChange={onChange}
                loading={loading}
                nextPage={nextPage}
                sizePage={sizePage}
                onChangeSizePage={onChangeSizePage}
            />
            {/* <div className='download-plan'>
                <FilePdfOutlined />
            </div> */}
            <div className='plan-selected'>
                {planSelected.ids?.length > 0 && (
                    <Alert
                        message={`Has seleccionado ${planSelected.ids?.length} planificaciones`}
                        type='success'
                        closeText='Deseleccionar'
                        afterClose={() => setPlanSelected({ ids: [] })}
                    />
                )}
            </div>
            <PlanificationTable
                loading={loading}
                planSelected={planSelected}
                nextPage={nextPage}
                planList={planList}
                sizePage={sizePage}
                success={success}
                setNextPage={setNextPage}
                setPlanSelected={setPlanSelected}
                setLoading={setLoading}
                isAnimated={true}
            />
        </div>
    )
}
