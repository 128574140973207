// @ts-ignore
import { Divider } from 'antd'
import { AdvertiserBox } from './AdvertiserBox'
import { HeaderBox } from './HeaderBox'
import {
    CheckCircleOutlined,
    FundProjectionScreenOutlined,
} from '@ant-design/icons'
import { HeaderOptimizationModel } from '../../../../../../Models/HeaderOptimizationModel'
//@ts-ignore
import { utility } from '@tdt-global/utility'

type PropType = {
    spin: boolean
    data: HeaderOptimizationModel
}

export const HeaderOptimization = ({ spin, data }: PropType) => {
    const { Functions } = utility
    console.log({ data })
    return (
        <div className='data'>
            <AdvertiserBox data={data?.proposal} />
            <Divider type='vertical' />
            <HeaderBox
                iso_type={data?.proposal_budget?.currency.iso_code}
                amount={Number(data?.planning?.budgetEstimated)}
                footer={
                    <>
                        Presupuesto <CheckCircleOutlined />
                    </>
                }
            />
            <Divider type='vertical' />
            <HeaderBox
                iso_type={null}
                amount={Number(data?.planning?.populationEstimated)}
                helpText='Cantidad estimada de personas alcanzadas durante el período de campaña.'
                footer={'Alcance total'}
            />
            <Divider type='vertical' />
            <HeaderBox
                iso_type={data?.proposal_budget?.currency.iso_code}
                amount={Functions.formatNumber(data?.planning?.cpmEstimated)}
                helpText='Es un cálculo que da como resultado el costo cada mil impresiones. Se incluyen todos los soportes que conforman la campaña.'
                footer={'CPM Total'}
            />
            <Divider type='vertical' />
            <HeaderBox
                iso_type={null}
                amount={Number(data?.planning?.otsEstimated)}
                helpText='En inglés Opportunity to see. Es la oportunidad de que una persona vea tu campaña.'
                footer={'OTS Totales'}
            />
        </div>
    )
}
