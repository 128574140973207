import React from 'react'
import { MixMedioData } from '../../../Types/result/mix/MixMedioData'
import { MixMedioType } from '../../../Types/result/mix/MixMedioType'
import { PlanningGeographicsType } from '../../../Types/result/mix/PlanningGeographicsType'
import { PlanningElementClassificationsModel } from './PlanningElementClassificationsModel'
import { PlanningGeographicsModel } from './PlanningGeographicsModel'

export class MixMedioModel {
    private _planningGeographics: PlanningGeographicsModel[]

    constructor(mix: MixMedioType, included: any) {
        this._planningGeographics = mix.data.map(
            (item) => new PlanningGeographicsModel(item, included),
        )
    }

    getStrategyString(elem: PlanningElementClassificationsModel) {
        let strategy = {
            1: 'Cobertura',
            2: 'Frecuencia',
            3: 'Segmentación',
            4: 'Segmentación',
            5: 'Call to action',
            6: 'Calidad de contacto',
            7: 'Impacto',
        }
        if (elem?.mainAction?.order) return strategy[elem?.mainAction?.order]
        else return null
    }

    get getMixMedioData(): MixMedioData[] {
        let data: any = this._planningGeographics.map((item) => {
            let elements = item.elementClassifications?.map((elem) => ({
                elementClassifications: elem,
                image: elem.hierarchy?.image?.dir,
                assetsType: elem.hierarchy?.name,
                subCategory: elem.hierarchy?.hierarchy?.name,
                material: elem.hierarchy?.hierarchy?.hierarchy?.name,
                strategy: this.getStrategyString(elem),
            }))

            return {
                planningGeographics: item,
                elements,
            }
        })
        return data
    }

    get planningGeographics(): PlanningGeographicsModel[] {
        return this._planningGeographics
    }

    set planningGeographics(value) {
        this._planningGeographics = value
    }
}
