import * as Highcharts from 'highcharts'
import HighchartMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'

type PropType = {
    series: any[]
    categories: string[]
    label?: boolean
    legend?: boolean
    legendPosition?: Highcharts.AlignValue
}

export const RadarChart = ({
    series,
    categories,
    label = false,
    legend = false,
    legendPosition = 'center',
}: PropType) => {
    HighchartMore(Highcharts)
    const config: any = {
        chart: label
            ? {
                  marginTop: 70,
                  polar: true,
                  type: 'area',
              }
            : {
                  polar: true,
                  type: 'area',
              },
        title: {
            text: '',
        },
        pane: {
            size: '95%',
            startAngle: 0,
            endAngle: 360,
        },
        xAxis: label
            ? {
                  categories: categories,
                  tickmarkPlacement: 'on',
                  lineWidth: 0,
                  labels: {
                      enabled: true,
                      x: -2,
                      y: -2,
                  },
              }
            : {
                  categories: categories,
                  tickInterval: 90,
                  labels: {
                      enabled: false,
                  },
              },
        yAxis: label
            ? {
                  min: 0,
                  max: 10,
                  showLastLabel: true,
                  tickInterval: 2.5,
                  gridLineColor: 'lightgray',
                  labels: {
                      allowOverlap: true,
                      enabled: true,
                  },
              }
            : {
                  min: 0,
                  max: 10,
                  showLastLabel: true,
                  tickInterval: 2,
              },
        plotOptions: {
            pointInterval: 90,
            series: {
                fillOpacity: 0.4,
                marker: {
                    radius: 2,
                    symbol: 'circle',
                },
            },
        },
        legend: legend
            ? {
                  alignItems: 'top',
                  verticalAlign: 'top',
                  symbolHeight: 8,
                  layout: 'horizontal',
                  itemStyle: {
                      fontWeight: 'normal',
                      fontSize: '13px',
                  },
              }
            : '',
        series: series,
        credits: {
            enabled: false,
        },
    }

    return <HighchartsReact highcharts={Highcharts} options={config} />
}
