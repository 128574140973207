// @ts-ignore
import { Skeleton } from 'antd'
import manBlue from '../../../../../../../Assets/Img/Grafico-Edades-Azul.svg'
import manGray from '../../../../../../../Assets/Img/Grafico-Edades-Gris.svg'
//@ts-ignore
import { utility } from '@tdt-global/utility'

type PropType = {
    data: any[]
    load: boolean
    population: number
}

export const EstimatedAge = ({ load, data, population }: PropType) => {
    const { Functions } = utility

    return (
        <div className='estimated-age'>
            <div className='body'>
                {!load ? (
                    <>
                        <div className='chart'>
                            <div className='graph'>
                                <div className='base'>
                                    {data.map((item, index) => {
                                        let sum = 50 / data.length - 1
                                        let size =
                                            index === 0 ? 60 : sum * index + 60

                                        return (
                                            <div key={index}>
                                                <img
                                                    src={manGray}
                                                    alt='ageRangeSvg'
                                                    style={{
                                                        height: size,
                                                        width: 34,
                                                    }}
                                                />
                                                {item.selected && (
                                                    <div
                                                        className='fill'
                                                        style={{
                                                            maxHeight: '100%',
                                                            height: size,
                                                            width: 34,
                                                        }}>
                                                        <img
                                                            src={manBlue}
                                                            alt='ageRangeSvg'
                                                            style={{
                                                                height: size,
                                                                width: 34,
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='total'>
                                Total{' '}
                                <span>
                                    {Functions.formatNumber(population)}
                                </span>
                            </div>
                            <div className='table'>
                                {data.map((item, index) => (
                                    <div className='table-item' key={index}>
                                        <span
                                            className={`dot ${
                                                item.selected ? 'selected' : ''
                                            }`}></span>
                                        <span className='name'>{`${
                                            item.name
                                        } : ${Functions.formatNumber(
                                            item.value,
                                        )}`}</span>
                                    </div>
                                ))}
                            </div>
                            <div className='footer'>
                                Valores estimados en base a la audiencia
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='chart'>
                            <Skeleton.Avatar
                                active
                                size={'large'}
                                shape={'circle'}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
