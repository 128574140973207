import { InfoCircleOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";

import * as Highcharts from "highcharts";
import HighchartMore from "highcharts/highcharts-more";

// @ts-ignore
import { Skeleton } from "antd";
import { RadarChart } from "../../../../Components/RadarChart";
import { RadarChartType } from "../../../../../../../Types/result/performance/RadarChartType";
HighchartMore(Highcharts);

type PropType = {
  load: boolean;
  strategy: string;
  data: RadarChartType[];
};

export const InvestmentOptimization = ({ load, strategy, data }: PropType) => {
  const [visible, setVisible] = useState(false);
  const [dataGraph, setDataGraph] = useState([]);

  let categories = [
    "Buscar cobertura",
    "Buscar frecuencia",
    "Segmentación",
    "Producir impacto",
  ];

  const dataForGraph = useCallback(() => {
    let dataValue = {
      ...data[1],
      type: "area",
      name: "Tú",
      color: "#5B8FF9",
    };
    let dataSuggest = {
      ...data[0],
      type: "area",
      name: "Optimización de inversión Planning",
      color: "#61DDAA",
    };
    return [dataValue, dataSuggest];
  }, [data]);

  useEffect(() => {
    if (data) {
      setDataGraph(dataForGraph);
    }
  }, [data]);

  return (
    <div className="investment-optimization">
      <div className="title">Optimización de inversión</div>
      <div className="body">
        {!load ? (
          <>
            <div className="text">{strategy}</div>
            <div className="chart">
              <RadarChart
                legend={true}
                categories={categories}
                series={dataGraph}
                label={true}
              />
            </div>
          </>
        ) : (
          <>
            <div className="text">
              <Skeleton.Button active block size={"small"} />
              <Skeleton.Button active block size={"small"} />
              <Skeleton.Button active block size={"small"} />
            </div>
            <div className="chart">
              <Skeleton.Avatar active size={"large"} shape={"circle"} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
