import { ProposalTypesType } from '../Types/ProposalTypesType'

export class ProposalTypesModel {
    private _id: number
    private _name: string
    private _type: string

    constructor(proposalTypes: ProposalTypesType) {
        this._id = proposalTypes.id
        this._name = proposalTypes.attributes.name
        this._type = proposalTypes.type
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }
}
