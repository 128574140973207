import {
    ShopOutlined,
    TrophyOutlined,
    SmileOutlined,
    ShareAltOutlined,
    CalendarOutlined,
    DollarCircleOutlined,
    GlobalOutlined,
    NotificationOutlined,
} from '@ant-design/icons'
import { ProposalModel } from '../../../../Models/ProposalModel'
import { loaderAbstractType } from '../../../../Types/LoaderAbstract'
// @ts-ignore
import { Tooltip, Skeleton } from 'antd'
// @ts-ignore
import moment from 'moment'
// @ts-ignore
import { SpinnerBar } from '@tdt-global/styleguide'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { CurrenciesModel } from '../../../../Models/CurrenciesModel'
import { useEffect, useState } from 'react'
import { FlexModel } from '../../../../Models/FlexModel'
import { ChartBudget } from '../ChartBudget'
import { useSearchParams } from 'react-router-dom'

type PropTypes = {
    currencies: CurrenciesModel[]
    flex: FlexModel[]
    result: ProposalModel
    loaderAbstract: loaderAbstractType[]
}
export const Abstract = ({
    result,
    loaderAbstract,
    flex,
    currencies,
}: PropTypes) => {
    const { Functions } = utility
    let [searchParams, setSearchParams] = useSearchParams()
    const [isoCode, setIsoCode] = useState(null)
    const [flexCode, setFlexCode] = useState(null)

    useEffect(() => {
        let isoCodeAux = null
        if (currencies.length && result.budget) {
            isoCodeAux = currencies.find(
                (item) => item.id === result.budget?.currency_id,
            ).iso_code
            isoCodeAux && setIsoCode(isoCodeAux)
        }
        if (flex.length && result.budget?.flex_type_id) {
            let flexType = flex.find(
                (item) => item.id === result.budget?.flex_type_id,
            ).name

            if (flexType) {
                var regExp = /\(([^)]+)\)/
                var matches = regExp.exec(flexType)
                if (matches[1] === '$') {
                    setFlexCode(isoCodeAux)
                } else setFlexCode(matches[1])
            }
        }
    }, [result.budget])

    useEffect(() => {
        if (result?.account?.id) {
            let tab = searchParams.get('tab')
            const body = document.getElementById('body-abstract')
            if (tab === '0' || tab === '1' || tab === '2' || tab === '3') {
                body.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            } else {
                body.scrollTo({
                    top: body.offsetHeight,
                    behavior: 'smooth',
                })
            }
        }
    }, [searchParams, result.proposalGeographics])

    return (
        <>
            <div className='title'>Resumen</div>
            <div className='body' id='body-abstract'>
                {result?.account ? (
                    <div className='abstract-item' id='advertiser-id'>
                        <div className='left'>
                            <ShopOutlined />
                        </div>
                        <div className='right'>
                            <div className='abstract-title'>
                                {result?.account?.name}
                            </div>
                        </div>
                    </div>
                ) : (
                    <span>
                        Aquí verás reflejado un resumen de los principales
                        aspectos de tu planificación.
                    </span>
                )}

                {result?.objectives.length > 0 && (
                    <div className='abstract-item' id='objective-id'>
                        <div className='left'>
                            <TrophyOutlined />
                        </div>
                        <div className='right'>
                            {result?.objectives
                                ?.sort((a, b) =>
                                    a.primary === b.primary
                                        ? 0
                                        : a.primary
                                        ? -1
                                        : 1,
                                )
                                .map((item, index) => {
                                    return item.primary ? (
                                        <div
                                            className='abstract-title'
                                            key={index}>
                                            {item.name}
                                        </div>
                                    ) : (
                                        <div
                                            className='abstract-sub-title'
                                            key={index}>
                                            {item.name}
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                )}
                {result?.audience && (
                    <div className='abstract-item' id='ageRange'>
                        <div className='left'>
                            <SmileOutlined />
                        </div>
                        <div className='right'>
                            <div className='abstract-title'>Edades</div>

                            <div className='abstract-sub-title'>
                                {loaderAbstract.find(
                                    (item) => item.key === 'ageRange',
                                )?.loader ? (
                                    <div className='spinner-center'>
                                        <SpinnerBar loading={true}></SpinnerBar>
                                    </div>
                                ) : result?.audience.age.start !==
                                  result?.audience.age.end ? (
                                    `${result?.audience.age.start} a ${
                                        result?.audience.age.end === 70
                                            ? '65+'
                                            : result?.audience.age.end
                                    } años`
                                ) : (
                                    `${result?.audience.age.start} años`
                                )}
                            </div>

                            <div className='abstract-title' id='gender'>
                                Género
                            </div>

                            <div className='abstract-sub-title'>
                                {loaderAbstract.find(
                                    (item) => item.key === 'gender',
                                )?.loader ? (
                                    <div className='spinner-center'>
                                        <SpinnerBar loading={true}></SpinnerBar>
                                    </div>
                                ) : (
                                    result?.audience?.gender?.name
                                )}
                            </div>

                            <div className='abstract-title' id='socioRange'>
                                Nivel socioeconómico
                            </div>

                            <div className='abstract-sub-title'>
                                {loaderAbstract.find(
                                    (item) => item.key === 'socioRange',
                                )?.loader ? (
                                    <div className='spinner-center'>
                                        <SpinnerBar loading={true}></SpinnerBar>
                                    </div>
                                ) : result?.audience?.socioeconomicsData ? (
                                    result?.audience?.socioeconomicsData[0]
                                        .id !==
                                    result?.audience?.socioeconomicsData[
                                        result?.audience?.socioeconomicsData
                                            .length - 1
                                    ].id ? (
                                        `${
                                            result?.audience
                                                ?.socioeconomicsData[0].name
                                        } a ${
                                            result?.audience
                                                ?.socioeconomicsData[
                                                result?.audience
                                                    ?.socioeconomicsData
                                                    .length - 1
                                            ].name
                                        }`
                                    ) : (
                                        result?.audience?.socioeconomicsData[0]
                                            .name
                                    )
                                ) : (
                                    ''
                                )}
                            </div>

                            <div className='abstract-title'>
                                Perfil de intereses
                            </div>

                            <div className='abstract-sub-title' id='interest'>
                                {loaderAbstract.find(
                                    (item) => item.key === 'interest',
                                )?.loader ? (
                                    <div className='spinner-center'>
                                        <SpinnerBar loading={true}></SpinnerBar>
                                    </div>
                                ) : result?.interest?.length > 0 ? (
                                    result?.interest
                                        .map((item) => item.name)
                                        .join(', ')
                                ) : (
                                    'Sin información'
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {result?.strategy && (
                    <div className='abstract-item' id='strategy'>
                        <div className='left'>
                            <ShareAltOutlined />
                        </div>
                        <div className='right'>
                            <div className='abstract-title'>Estrategia</div>
                            <div className='abstract-sub-title min-height-skeleton'>
                                {loaderAbstract.find(
                                    (item) => item.key === 'strategy',
                                )?.loader ? (
                                    <>
                                        <div className='spinner-center'>
                                            <SpinnerBar
                                                loading={true}></SpinnerBar>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {result?.strategy?.description ? (
                                            result?.strategy?.description
                                                ?.length > 120 ? (
                                                <Tooltip
                                                    zIndex={90}
                                                    title={
                                                        result?.strategy
                                                            ?.description
                                                    }>
                                                    {result?.strategy?.description.substr(
                                                        0,
                                                        120 - 1,
                                                    ) + '...'}
                                                </Tooltip>
                                            ) : (
                                                result?.strategy?.description
                                            )
                                        ) : (
                                            'Sin texto'
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {result?.name && (
                    <div className='abstract-item' id='campaignName'>
                        <div className='left'>
                            <NotificationOutlined />
                        </div>
                        <div className='right'>
                            {loaderAbstract.find(
                                (item) => item.key === 'campaignName',
                            )?.loader ? (
                                <div className='spinner-center'>
                                    <SpinnerBar loading={true}></SpinnerBar>
                                </div>
                            ) : (
                                <div className='abstract-title'>
                                    {result.name}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {result?.start_date && result?.finish_date && (
                    <div className='abstract-item' id='dates'>
                        <div className='left'>
                            <CalendarOutlined />
                        </div>
                        <div className='right'>
                            {loaderAbstract.find((item) => item.key === 'dates')
                                ?.loader ? (
                                <div className='spinner-center'>
                                    <SpinnerBar loading={true}></SpinnerBar>
                                </div>
                            ) : (
                                <div className='abstract-title'>
                                    {`${moment(result?.start_date)
                                        .format('DD MMM. YYYY')
                                        .toLowerCase()}
                                 - ${moment(result?.finish_date)
                                     .format('DD MMM. YYYY')
                                     .toLowerCase()}
                                    `}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {result?.strategy && result?.budget && (
                    <div className='abstract-item' id='budget'>
                        <div className='left'>
                            <DollarCircleOutlined />
                        </div>
                        <div className='right'>
                            {loaderAbstract.find(
                                (item) => item.key === 'budget',
                            )?.loader ? (
                                <>
                                    <div className='spinner-center'>
                                        <SpinnerBar loading={true}></SpinnerBar>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='abstract-title'>
                                        {result.budget.amount
                                            ? `${Functions.formatNumber(
                                                  result.budget.amount,
                                              )} ${isoCode && isoCode}`
                                            : 'Sin información'}
                                    </div>
                                    <div className='abstract-sub-title '>
                                        {result.budget.flex_value
                                            ? `Flexible ${Functions.formatNumber(
                                                  result.budget.flex_value,
                                              )} ${flexCode && flexCode}`
                                            : ''}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}

                {result?.proposalGeographics &&
                    result?.proposalGeographics?.length !== 0 && (
                        <div className='abstract-item' id='cities'>
                            <div className='left'>
                                <GlobalOutlined />
                            </div>
                            <div className='right'>
                                {loaderAbstract.find(
                                    (item) => item.key === 'cities',
                                )?.loader ? (
                                    <div className='spinner-center'>
                                        <SpinnerBar loading={true}></SpinnerBar>
                                    </div>
                                ) : result?.proposalGeographics?.length ? (
                                    <div className='abstract-title'>
                                        {`${result?.proposalGeographics
                                            .map((item) => item.city.name)
                                            .join(', ')}.`}
                                    </div>
                                ) : (
                                    <div className='abstract-title'>
                                        Sin información
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                {result?.proposalGeographics?.length > 0 &&
                    result?.budget?.amount && (
                        <div
                            className='abstract-item-chart'
                            id='proposalGeographics'>
                            <div className='right'>
                                {loaderAbstract.find(
                                    (item) =>
                                        item.key === 'proposalGeographics',
                                )?.loader ? (
                                    <div className='skeleton-distribution'>
                                        <Skeleton.Avatar
                                            active
                                            size={'large'}
                                        />
                                        <div className='right'>
                                            <Skeleton.Button
                                                active
                                                block
                                                size={'small'}
                                            />
                                            <Skeleton.Button
                                                active
                                                block
                                                size={'small'}
                                            />
                                            <Skeleton.Button
                                                active
                                                block
                                                size={'small'}
                                            />
                                            <Skeleton.Button
                                                active
                                                block
                                                size={'small'}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className='abstract-title'>
                                            <ChartBudget
                                                label={false}
                                                legendPosition='right'
                                                distribution={
                                                    result.proposalGeographics
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
            </div>
        </>
    )
}
