import { InterestProfileType } from '../../Types/interest/InterestProfileType'

export class InterestProfileModel {
    private _id: number
    private _type: string
    private _name: string
    private _interestTypeId?: number
    private _interestCategoryId?: number
    private _interestList?: InterestProfileModel[]

    constructor(interest: InterestProfileType, included) {
        this._id = interest.id
        this._type = interest.type
        this._name = interest.attributes.name
        this._interestTypeId = interest?.attributes?.interest_type_id
        this._interestCategoryId = interest?.attributes?.interest_category_id

        if (included) {
            if (interest?.relationships?.categories?.data) {
                this._interestList =
                    interest.relationships?.categories?.data?.map((item) => {
                        return new InterestProfileModel(
                            included.find(
                                (elem) =>
                                    elem.id === item.id &&
                                    elem.type === item.type,
                            ),
                            included,
                        )
                    })
            }
            if (interest?.relationships?.subcategories?.data) {
                this._interestList =
                    interest.relationships?.subcategories?.data?.map((item) => {
                        return new InterestProfileModel(
                            included.find(
                                (elem) =>
                                    elem.id === item.id &&
                                    elem.type === item.type,
                            ),
                            included,
                        )
                    })
            }
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get interestCategoryId(): number {
        return this._interestCategoryId
    }

    set interestCategoryId(value) {
        this._interestCategoryId = value
    }

    get interestTypeId(): number {
        return this._interestTypeId
    }

    set interestTypeId(value) {
        this._interestTypeId = value
    }

    get interestList(): InterestProfileModel[] {
        return this._interestList
    }

    set interestList(value) {
        this._interestList = value
    }
}
