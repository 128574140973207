// @ts-ignore
import { Skeleton, Descriptions } from 'antd'

export const TableMapSkeleton = () => {

    const skeletonLabel = <Skeleton.Button className='skeleton-label' active />
    
    return (
        <Descriptions size='small' column={1} bordered>
            <Descriptions.Item
                label={skeletonLabel}>
                <Skeleton.Button active className='skeleton-item' />
            </Descriptions.Item>
            <Descriptions.Item
                label={skeletonLabel}>
                <Skeleton.Button active className='skeleton-item' />
            </Descriptions.Item>
            <Descriptions.Item
                label={skeletonLabel}>
                <Skeleton.Button active className='skeleton-item' />
            </Descriptions.Item>
            <Descriptions.Item
                label={skeletonLabel}>
                <Skeleton.Button active className='skeleton-item' />
            </Descriptions.Item>
            <Descriptions.Item
                label={skeletonLabel}>
                <Skeleton.Button active className='skeleton-item' />
            </Descriptions.Item>
            <Descriptions.Item
                label={skeletonLabel}>
                <Skeleton.Button active className='skeleton-item' />
            </Descriptions.Item>
            <Descriptions.Item
                label={skeletonLabel}>
                <Skeleton.Button active className='skeleton-item' />
            </Descriptions.Item>
            <Descriptions.Item
                label={skeletonLabel}>
                <Skeleton.Button active className='skeleton-item' />
            </Descriptions.Item>
            <Descriptions.Item
                label={skeletonLabel}>
                <Skeleton.Button active className='skeleton-item' />
            </Descriptions.Item>
        </Descriptions>
    )
}
