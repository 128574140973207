// @ts-ignore
import { Modal, Descriptions, Skeleton } from 'antd'
// @ts-ignore
import { Button } from '@tdt-global/styleguide'
import { DeleteOutlined, RetweetOutlined } from '@ant-design/icons'

//@ts-ignore
import { utility } from '@tdt-global/utility'
import { HeaderOptimizationModel } from '../../../../../../../Models/HeaderOptimizationModel'
import { useEffect, useState } from 'react'
import { Endpoints } from '../../../../../../../Common/Endpoints'
import { DataMapType } from '../../../../../../../Types/result/map/DataMapType'
import { ElementModel } from '../../../../../../../Models/result/assetsList/ElementModel'
import { TableMapSkeleton } from './MapSkeleton/TableMapSkeleton'

type PropType = {
    visibleModal: {
        visible: boolean
        id: number | null
    }
    assetsList: DataMapType[]
    proposal: HeaderOptimizationModel
    handleOk: () => void
    setVisibleModal: (visibleModal) => void
    handleReplaceDrawer: (id: number) => void
}

export const BodyModal = ({
    visibleModal,
    assetsList,
    proposal,
    handleOk,
    setVisibleModal,
    handleReplaceDrawer,
}: PropType) => {
    const { Functions, ShowImage, useAxios } = utility
    const { getHttpRequest } = useAxios()
    const [spinner, setSpinner] = useState(true)
    const [assetsInfo, setAssetsInfo] = useState<DataMapType>(null)
    const [color, setColor] = useState(null)

    useEffect(() => {
        setAssetsInfo(null)
        if (visibleModal.visible) {
            let assetsInfo = assetsList.find(
                (item) => item.id === visibleModal.id,
            )
            setAssetsInfo(assetsInfo)
            setColor(
                assetsInfo?.icon?.color.startsWith('#')
                    ? assetsInfo?.icon?.color
                    : '#' + assetsInfo?.icon?.color,
            )
        }
    }, [visibleModal.visible])

    useEffect(() => {
        if (assetsInfo?.elementId) {
            loadModalInfo()
        }
    }, [assetsInfo?.elementId])

    const loadModalInfo = () => {
        setSpinner(true)
        getHttpRequest(
            Endpoints.GET_RESULT_MAP_MODAL_ELEMENT,
            {
                'filter[id]': [assetsInfo.elementId],
                include: [
                    'image',
                    'traffic',
                    'lightingType',
                    'address',
                    'address.city',
                    'vendor',
                ],
            },
            (response) => {
                let element = new ElementModel(
                    response.data.data[0],
                    response.data.included,
                )
                setAssetsInfo({
                    ...assetsInfo,
                    description: {
                        ...assetsInfo.description,
                        city: element.city.name,
                        code: element?.code,
                    },
                    image: element?.image?.dir,
                    trafic: element?.traffic?.name,
                    light: element?.lightingType?.name,
                    vendor: element?.vendor?.name,
                })
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {
                setSpinner(false)
            },
        )
    }

    return (
        <Modal
            centered
            visible={visibleModal.visible}
            onOk={() => setVisibleModal({ visible: false, id: null })}
            onCancel={() => setVisibleModal({ visible: false, id: null })}
            footer={null}
            zIndex={1000}
            width={832}>
            <div id='modal-map-result'>
                <div className='header'>
                    <div className='left'>
                        {!spinner ? (
                            <div
                                className='image'
                                style={{
                                    backgroundColor: color,
                                }}>
                                {assetsInfo?.icon?.label}
                            </div>
                        ) : (
                            <Skeleton.Avatar active />
                        )}
                        <div className='info'>
                            {!spinner ? (
                                <div className='semi-bold deep-purple font-14 m-b-2'>
                                    {assetsInfo?.info?.type}
                                </div>
                            ) : (
                                <div className='skeleton-container'>
                                    <Skeleton.Button active />
                                </div>
                            )}
                            {!spinner ? (
                                <div className='deep-purple m-b-2'>
                                    {assetsInfo?.info?.subCategory}
                                </div>
                            ) : (
                                <div className='skeleton-container'>
                                    <Skeleton.Button active />
                                </div>
                            )}
                            {!spinner ? (
                                <div className='deep-purple m-b-2'>
                                    {assetsInfo?.info?.category}
                                </div>
                            ) : (
                                <div className='skeleton-container'>
                                    <Skeleton.Button active />
                                </div>
                            )}
                            {!spinner ? (
                                <div className='semi-bold deep-purple font-14 m-t-6'>
                                    {Functions.formatNumber(
                                        assetsInfo?.info?.budget,
                                    )}
                                    <span className='normal-bold m-l-2'>
                                        {
                                            proposal?.proposal_budget?.currency
                                                ?.iso_code
                                        }
                                    </span>
                                </div>
                            ) : (
                                <div className='skeleton-container'>
                                    <Skeleton.Button active />
                                </div>
                            )}
                        </div>
                    </div>
                    {!spinner ? (
                        <div className='right'>
                            {Functions.formatNumber(assetsInfo?.ots)} OTS
                        </div>
                    ) : (
                        <Skeleton.Button
                            active
                            style={{
                                backgroundColor: '#c8b7f5b8',
                                width: '150px',
                                height: '12px',
                            }}
                        />
                    )}
                </div>
                <div className='body'>
                    <div className='left'>
                        <div className='image'>
                            <div>
                                {!spinner ? (
                                    <ShowImage
                                        url={assetsInfo?.image}
                                        isAnimated={true}
                                        style={{ width: 400, height: 300 }}
                                    />
                                ) : (
                                    <Skeleton.Button
                                        active
                                        style={{ width: 400, height: 300 }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='right'>
                        {!spinner ? (
                            <Descriptions size='small' column={1} bordered>
                                {assetsInfo?.description?.cpm && (
                                    <Descriptions.Item
                                        labelStyle={{
                                            textAlign: 'right',
                                            color: '#423464',
                                            fontWeight:
                                                'var(--font-weight-semi-bold)',
                                        }}
                                        label='CPM'>
                                        {`${assetsInfo?.description?.cpm} ${proposal?.proposal_budget?.currency?.iso_code}`}
                                    </Descriptions.Item>
                                )}
                                {assetsInfo?.description?.code && (
                                    <Descriptions.Item
                                        labelStyle={{
                                            textAlign: 'right',
                                            color: '#423464',
                                            fontWeight:
                                                'var(--font-weight-semi-bold)',
                                        }}
                                        label='Código'>
                                        {assetsInfo?.description?.code}
                                    </Descriptions.Item>
                                )}
                                {assetsInfo?.description?.material && (
                                    <Descriptions.Item
                                        labelStyle={{
                                            textAlign: 'right',
                                            color: '#423464',
                                            fontWeight:
                                                'var(--font-weight-semi-bold)',
                                        }}
                                        label='Material'>
                                        {assetsInfo?.description?.material}
                                    </Descriptions.Item>
                                )}
                                {assetsInfo?.description?.contract && (
                                    <Descriptions.Item
                                        labelStyle={{
                                            textAlign: 'right',
                                            color: '#423464',
                                            fontWeight:
                                                'var(--font-weight-semi-bold)',
                                        }}
                                        label='Tipo de contratación'>
                                        {`${assetsInfo?.description?.contract} días`}
                                    </Descriptions.Item>
                                )}
                                {assetsInfo?.description?.city && (
                                    <Descriptions.Item
                                        labelStyle={{
                                            textAlign: 'right',
                                            color: '#423464',
                                            fontWeight:
                                                'var(--font-weight-semi-bold)',
                                        }}
                                        label='Ciudad'>
                                        {assetsInfo?.description?.city}
                                    </Descriptions.Item>
                                )}
                                {assetsInfo?.description?.address && (
                                    <Descriptions.Item
                                        labelStyle={{
                                            textAlign: 'right',
                                            color: '#423464',
                                            fontWeight:
                                                'var(--font-weight-semi-bold)',
                                        }}
                                        label='Ubicación'>
                                        {assetsInfo?.description?.address}
                                    </Descriptions.Item>
                                )}
                                {assetsInfo?.trafic && (
                                    <Descriptions.Item
                                        labelStyle={{
                                            textAlign: 'right',
                                            color: '#423464',
                                            fontWeight:
                                                'var(--font-weight-semi-bold)',
                                        }}
                                        label='Tráfico'>
                                        {assetsInfo?.trafic}
                                    </Descriptions.Item>
                                )}
                                {assetsInfo?.light && (
                                    <Descriptions.Item
                                        labelStyle={{
                                            textAlign: 'right',
                                            color: '#423464',
                                            fontWeight:
                                                'var(--font-weight-semi-bold)',
                                        }}
                                        label='Tipo de iluminación'>
                                        {assetsInfo?.light}
                                    </Descriptions.Item>
                                )}
                                {assetsInfo?.vendor && (
                                    <Descriptions.Item
                                        labelStyle={{
                                            textAlign: 'right',
                                            color: '#423464',
                                            fontWeight:
                                                'var(--font-weight-semi-bold)',
                                        }}
                                        label='Proveedor'>
                                        {assetsInfo?.vendor}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                        ) : (
                            <div className='table'>
                                <TableMapSkeleton />
                            </div>
                        )}
                    </div>
                </div>
                <div className='footer'>
                    <Button
                        key='back'
                        ghost
                        size='small'
                        onClick={() => handleReplaceDrawer(visibleModal.id)}>
                        Reemplazar <RetweetOutlined />
                    </Button>

                    <Button
                        key='submit'
                        ghost
                        size='small'
                        // loading={loading}
                        onClick={handleOk}>
                        Eliminar <DeleteOutlined />
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
