import { ProposalGeographicsType } from '../Types/ProposalGeographicsType'
import { CityModel } from './CityModel'

export class ProposalGeographicsModel {
    private _id: number
    private _type: string
    private _budget: number
    private _city: CityModel

    constructor(proposalGeographic: ProposalGeographicsType, included?) {
        this._id = proposalGeographic.id
        this._type = proposalGeographic.type
        this._budget = proposalGeographic.attributes.budget
        this._city = null

        if (included) {
            proposalGeographic.relationships.city.data.id
                ? (this._city = new CityModel(
                      included.find(
                          (item) =>
                              item.id ===
                                  proposalGeographic.relationships.city.data
                                      .id &&
                              item.type ===
                                  proposalGeographic.relationships.city.data
                                      .type,
                      ),
                      included,
                  ))
                : (this._city = null)
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get budget(): number {
        return this._budget
    }

    set budget(value) {
        this._budget = value
    }

    get city(): CityModel {
        return this._city
    }

    set city(value) {
        this._city = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }
}
