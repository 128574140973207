import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom'
import {
    ArrowLeftOutlined,
    PlusOutlined,
    CloseOutlined,
} from '@ant-design/icons'
import { Path } from '../Common/Path'
// @ts-ignore
import { ButtonsModalConfirm } from '@tdt-global/styleguide'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { Endpoints } from '../Common/Endpoints'
import { useState } from 'react'
import { ShowModalType } from '../Types/ShowModalType'

const Breadcrumb = ({ ...props }) => {
    let [searchParams] = useSearchParams()
    const { useAxios, Functions, ShowModal } = utility
    const { deleteHttpRequest } = useAxios()
    const { path } = props
    const location = useLocation()
    const navigate = useNavigate()
    const [deleteSpinner, setDeleteSpinner] = useState(false)
    const [propsModal, setPropsModal] = useState<ShowModalType>({
        show: false,
        title: '',
        className: '',
        hideModal: null,
        component: null,
        modalProps: {},
        confirmLoading: false,
    })

    const pathOrigin = '/' + path.split('/')[1]

    const breadcrumbNameMap = {
        [Path.PLANNING]: 'Planificaciones',
        [Path.NEW_PLANNING]: 'Nueva planificación',
    }

    const pathSnippets = location.pathname.split('/').filter((i) => i)

    const handleCancel = (rowData: any) => {
        if (searchParams.get('id')) {
            const hideModal = () => {
                setPropsModal({
                    show: false,
                    ...propsModal,
                })
            }
            const handleOk = () => {
                setDeleteSpinner(true)

                deleteHttpRequest(
                    Endpoints.DEL_PROPOSAL.replace(
                        '{id}',
                        searchParams.get('id'),
                    ),
                    {},
                    (response: any) => {
                        setDeleteSpinner(false)
                        setPropsModal({
                            show: false,
                            ...propsModal,
                        })
                        navigate(Path.PLANNING)
                    },
                    (error) => {
                        Functions.openNotificationError(
                            Functions.errorObject(error),
                            'Error',
                        )
                    },
                    () => {},
                )
            }
            setPropsModal({
                show: true,
                className: 'modal-confirm',
                title: 'Eliminar planificación',
                component: (
                    <>
                        Estás por eliminar la planificación. Esta acción no
                        puede deshacerse. ¿Deseas eliminar la planificación?
                        <ButtonsModalConfirm
                            okText='Eliminar'
                            loading={deleteSpinner}
                            cancelText='Cancelar'
                            handleOK={handleOk}
                            handleCancel={hideModal}
                        />
                    </>
                ),
                hideModal: hideModal,
                modalProps: {
                    closable: false,
                    width: 588,
                    footer: null,
                },
            })
        } else navigate(Path.PLANNING)
    }

    return (
        <>
            {searchParams.get('tab') !== '5' &&
                searchParams.get('tab') !== 'new' && (
                    <div className='breadcrumb'>
                        <div className='box'>
                            <div className='back'>
                                {pathSnippets.length > 1 &&
                                    (searchParams.get('status') === 'create' &&
                                    searchParams.get('id') ? (
                                        <CloseOutlined onClick={handleCancel} />
                                    ) : (
                                        <ArrowLeftOutlined
                                            onClick={() =>
                                                navigate(Path.PLANNING)
                                            }
                                        />
                                    ))}
                                <div className='text'>
                                    {breadcrumbNameMap[location.pathname]}
                                </div>
                            </div>
                            {location.pathname === pathOrigin && (
                                <Link
                                    to={Path.NEW_PLANNING}
                                    className='btn-big'
                                    target='_blank'>
                                    <span> Nueva planificación</span>{' '}
                                    <PlusOutlined />
                                </Link>
                            )}
                        </div>
                        <ShowModal {...propsModal} />
                    </div>
                )}
        </>
    )
}

export default Breadcrumb
