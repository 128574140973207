type PropType = {
    title?: string
    children
}
export const Box = ({ title, children }: PropType) => {
    return (
        <div className='tabs-box'>
            {title && <div className='title'>{title}</div>}
            {children}
        </div>
    )
}
