import { PlanningGeographicsType } from '../../../Types/result/mix/PlanningGeographicsType'
import { CityModel } from '../../CityModel'
import { PlanningElementClassificationsModel } from './PlanningElementClassificationsModel'

export class PlanningGeographicsModel {
    private _id: number
    private _type: string
    private _budget: number
    private _budgetEstimated: number
    private _cpm: number
    private _cpmEstimated: number
    private _geographicId: number
    private _geographicModel: string
    private _ots: number
    private _ots_estimated: number
    private _planningId: number
    private _city: CityModel
    private _elementClassifications: PlanningElementClassificationsModel[]

    constructor(planGeo: PlanningGeographicsType, included: any) {
        this._id = planGeo.id
        this._type = planGeo.type
        this._budget = planGeo.attributes.budget
        this._budgetEstimated = planGeo.attributes.budget_estimated
        this._cpm = planGeo.attributes.cpm
        this._cpmEstimated = planGeo.attributes.cpm_estimated
        this._geographicId = planGeo.attributes.geographic_id
        this._geographicModel = planGeo.attributes.geographic_model
        this._ots = planGeo.attributes.ots
        this._ots_estimated = planGeo.attributes.ots_estimated
        this._planningId = planGeo.attributes.planning_id

        if (included && planGeo.relationships?.city?.data) {
            this._city = new CityModel(
                included.find(
                    (item) =>
                        item.id === planGeo.relationships?.city?.data.id &&
                        item.type === planGeo.relationships?.city?.data.type,
                ),
                included,
            )
        }

        if (
            included &&
            planGeo.relationships?.element_classifications?.data?.length
        ) {
            let elemClassList = []
            planGeo.relationships.element_classifications.data.map((item) => {
                included.find((elem) => {
                    if (elem.id === item.id && elem.type === item.type) {
                        elemClassList.push(
                            new PlanningElementClassificationsModel(
                                elem,
                                included,
                            ),
                        )
                        return true
                    } else return false
                })
            })
            this._elementClassifications = elemClassList
        }
    }

    get budget(): number {
        return this._budget
    }

    set budget(value) {
        this._budget = value
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get budgetEstimated(): number {
        return this._budgetEstimated
    }

    set budgetEstimated(value) {
        this._budgetEstimated = value
    }

    get cpm(): number {
        return this._cpm
    }

    set cpm(value) {
        this._cpm = value
    }

    get cpmEstimated(): number {
        return this._cpmEstimated
    }

    set cpmEstimated(value) {
        this._cpmEstimated = value
    }

    get geographicId(): number {
        return this._geographicId
    }

    set geographicId(value) {
        this._geographicId = value
    }

    get geographicModel(): string {
        return this._geographicModel
    }

    set geographicModel(value) {
        this._geographicModel = value
    }

    get ots(): number {
        return this._ots
    }
    set ots(value: number) {
        this._ots = value
    }
    get ots_estimated(): number {
        return this._ots_estimated
    }
    set ots_estimated(value: number) {
        this._ots_estimated = value
    }
    get planningId(): number {
        return this._planningId
    }
    set planningId(value: number) {
        this._planningId = value
    }
    get city(): CityModel {
        return this._city
    }
    set city(value: CityModel) {
        this._city = value
    }
    get elementClassifications(): PlanningElementClassificationsModel[] {
        return this._elementClassifications
    }
    set elementClassifications(value: PlanningElementClassificationsModel[]) {
        this._elementClassifications = value
    }
}
