import { ImageType } from '../Types/result/mix/ImageType'

export default class ImageModel {
    private _id: number
    private _type: string
    private _name: string
    private _dir: string
    private _imageableType: string
    private _imageableId: number
    private _version: number
    private _createdAt: string

    constructor(image: ImageType) {
        this._id = image.id
        this._type = image.type
        this._name = image.attributes.name
        this._dir = image.attributes.dir
        this._imageableType = image.attributes.imageable_type
        this._imageableId = image.attributes.imageable_id
        this._version = image.attributes.version
        this._createdAt = image.attributes.created_at
    }

    get id(): number {
        return this._id
    }
    set id(value: number) {
        this._id = value
    }
    get type(): string {
        return this._type
    }
    set type(value: string) {
        this._type = value
    }
    get name(): string {
        return this._name
    }
    set name(value: string) {
        this._name = value
    }
    get dir(): string {
        return this._dir
    }
    set dir(value: string) {
        this._dir = value
    }
    get imageableType(): string {
        return this._imageableType
    }
    set imageableType(value: string) {
        this._imageableType = value
    }
    get imageableId(): number {
        return this._imageableId
    }
    set imageableId(value: number) {
        this._imageableId = value
    }
    get version(): number {
        return this._version
    }
    set version(value: number) {
        this._version = value
    }
    get createdAt(): string {
        return this._createdAt
    }
    set createdAt(value: string) {
        this._createdAt = value
    }
}
