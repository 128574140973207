import { DepartmentType } from '../Types/DepartmentType'

export class DepartmentModel {
    private _id: number
    private _type: string
    private _name: string
    private _state_id: number
    private _population: number

    constructor(state: DepartmentType) {
        this._id = state.id
        this._type = state.type
        this._name = state.attributes.name
        this._state_id = state.attributes.state_id
        this._population = state.attributes.population
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get population(): number {
        return this._population
    }

    set population(value) {
        this._population = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get state_id(): number {
        return this._state_id
    }

    set state_id(value) {
        this._state_id = value
    }
}
