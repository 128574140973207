import { SuggestedBudgetType } from '../Types/SuggestedBudgetType'

export class SuggestedBudgetModel {
    private _id: number
    private _type: string
    private _amount: number
    private _population: number
    private _percentage: number

    constructor(suggested?: SuggestedBudgetType) {
        this._id = suggested.id
        this._type = suggested.type
        this._amount = suggested.attributes.amount
        this._population = suggested.attributes.population
        this._percentage = suggested.attributes.percentage
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get amount(): number {
        return this._amount
    }

    set amount(value) {
        this._amount = value
    }

    get population(): number {
        return this._population
    }

    set population(value) {
        this._population = value
    }

    get percentage(): number {
        return this._percentage
    }

    set percentage(value) {
        this._percentage = value
    }
}
