import { AccountType } from '../Types/AccountType'

export class AccountModel {
    private _id: number
    private _name: string
    private _type: string
    private _advertiser_id: number
    private _buyer_id: number
    private _discount: number
    private _file: string | null
    private _created_at: string

    constructor(account: AccountType) {
        this._id = account.id
        this._name = account.attributes.name
        this._type = account.type
        this._advertiser_id = account.attributes.advertiser_id
        this._buyer_id = account.attributes.buyer_id
        this._discount = account.attributes.discount
        this._file = account.attributes.file
        this._created_at = account.attributes.created_at
    }

    get accountObject(): any {
        return {
            id: this._id,
            name: this._name,
            type: this._type,
            advertiser_id: this.advertiser_id,
            buyer_id: this.buyer_id,
            discount: this.discount,
            file: this.file,
            created_at: this.created_at,
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get advertiser_id(): number {
        return this._advertiser_id
    }

    set advertiser_id(value) {
        this._advertiser_id = value
    }

    get buyer_id(): number {
        return this._buyer_id
    }

    set buyer_id(value) {
        this._buyer_id = value
    }

    get discount(): number {
        return this._discount
    }

    set discount(value) {
        this._discount = value
    }

    get file(): string {
        return this._file
    }

    set file(value) {
        this._file = value
    }

    get created_at(): string {
        return this._created_at
    }

    set created_at(value) {
        this._created_at = value
    }
}
