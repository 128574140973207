import { PlanningElementsGeographicsModel } from '../../../../../../../Models/result/assetsList/PlanningElementsGeographicsModel'
import { HeaderOptimizationModel } from '../../../../../../../Models/HeaderOptimizationModel'
import { Pagination } from 'antd'
import { PanelTable } from './PanelTable'
import { PlanningElementClassificationsModel } from '../../../../../../../Models/result/mix/PlanningElementClassificationsModel'
import { useEffect, useState } from 'react'
import { PlanningElementModel } from '../../../../../../../Models/result/assetsList/PlanningElementModel'

//@ts-ignore
import { utility } from '@tdt-global/utility'
import { Endpoints } from '../../../../../../../Common/Endpoints'
import { ResponseType } from '../../../../../../../Types/ResponseType'
import { AssetsListModel } from '../../../../../../../Models/result/assetsList/AssetsListModel'

type PropType = {
    panel: PlanningElementClassificationsModel
    proposal: HeaderOptimizationModel
    elementsClass: PlanningElementsGeographicsModel
    loadResult: () => void
    handleReplaceDrawer: (id: number) => void
}

export const AssetsPanel = ({
    panel,
    proposal,
    elementsClass,
    handleReplaceDrawer,
    loadResult,
}: PropType) => {
    const { useAxios, Functions } = utility
    const { getHttpRequest } = useAxios()
    const [nextPage, setNextPage] = useState<number>(1)
    const [list, setList] = useState<PlanningElementModel[]>([])
    const [loading, setLoading] = useState(false)
    const onChange = (page) => {
        if (page !== nextPage) {
            setNextPage(page)
        }
    }

    useEffect(() => {
        setList(panel.elementsList)
    }, [])

    useEffect(() => {
        if (list.length) {
            setLoading(true)
            loadPlanList()
        }
    }, [nextPage])

    const loadPlanList = () => {
        const data = {
            'page[size]': 10,
            'page[number]': nextPage,
            'filter[planning]': panel.planningId,
            'filter[elementClassification][]': panel.id,
            view: 'switch',
            sort: ['-price'],
            include: [
                'planning',
                'element',
                'element.address',
                'element.image',
                'element.lightingType',
                'element.unitMeasure',
                'element.vendor',
            ],
        }

        getHttpRequest(
            Endpoints.GET_RESULT_ASSETS_PAGINATED,
            data,
            (response: ResponseType) => {
                setList(
                    response.data.data.map(
                        (item: any) =>
                            new PlanningElementModel(
                                item,
                                response.data.included,
                            ),
                    ),
                )
            },
            error,
            always,
        )
    }

    const error = (error: any) =>
        Functions.openNotificationError(Functions.errorObject(error), 'Error')

    const always = () => setLoading(false)

    return (
        <div className='assets-panel'>
            {panel.elementsList?.length ? (
                <>
                    <PanelTable
                        panel={panel}
                        loading={loading}
                        list={list}
                        proposal={proposal}
                        elementsClass={elementsClass}
                        handleReplaceDrawer={handleReplaceDrawer}
                        loadResult={loadResult}
                    />
                    {panel.totalElements > 10 && (
                        <Pagination
                            total={panel.totalElements}
                            showTotal={(total, range) =>
                                `${range[0]}-${range[1]} de ${total}`
                            }
                            showSizeChanger={false}
                            defaultPageSize={10}
                            pageSize={10}
                            defaultCurrent={1}
                            current={nextPage}
                            disabled={loading}
                            onChange={onChange}
                        />
                    )}
                </>
            ) : (
                'No encontramos soportes en esta clasificación. Hemos redistribuido el presupuesto teniendo esto en consideración.'
            )}
        </div>
    )
}
