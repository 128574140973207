export const Endpoints = {
    GET_PLANIFICATIONS: 'v2/proposals',
    GET_PLANIFICATIONS_PAGINATED: 'v2/proposals/paginated',
    GET_ADVERTISERS: 'v2/accounts',
    GET_OBJECTIVES: 'v2/proposals/objectives',
    GET_GENDER: 'v2/genders',
    GET_SOCIOECONOMICS: 'socioeconomics',
    GET_INTERESTS_BY_AUDIENCE: 'audiences/{id}/interests',
    GET_AUDIENCE: 'audiences',
    GET_INTERESTS_SEARCH: 'interests',
    GET_INTERESTS_TYPES: 'interests/types',
    GET_STRATEGY_BY_PROPOSAL_ID: 'v2/proposals/{id}/suggested-strategy',

    GET_DESCRIPTION_STRATEGY: 'v2/proposals/strategies/description',
    GET_CURRENCIES: 'v2/currencies',
    GET_BUDGET_BY_PROPOSAL_ID: 'v2/proposals/budgets',
    GET_SUGGESTED_BUDGET_BY_PROPOSAL: 'v2/proposals/{id}/suggested-budget',

    GET_FLEX_TYPE: 'discount-types',

    GET_CITIES: 'v2/cities',
    GET_PROPOSAL_GEOGRAPHICS_BY_PROPOSAL: 'v2/proposals/{id}/geographics',

    GET_RESULT_HEADER: 'plannings/{id}/header',
    GET_RESULT_PERFORMANCE: 'plannings/{id}/performance',
    GET_RESULT_MIX: 'plannings/{id}/media-mix',
    GET_RESULT_ELEMENTS: 'plannings/{id}/elements',
    GET_RESULT_MIX_ELEMENTS: 'plannings/{id}/media-mix-with-elements',
    GET_RESULT_MAP: 'plannings/{id}/map',
    GET_RESULT_GEOGRAFIC_MAP: 'plannings/{id}/geographics',
    GET_RESULT_HIERARCHIES_MAP: 'plannings/{id}/hierarchies',
    GET_RESULT_MAP_MODAL_ELEMENT: 'elements',
    GET_RESULT_ASSETS_PAGINATED: 'plannings/elements/paginated',
    GET_RESULT_REPLACE_ASSETS: 'plannings/elements/{id}/replace',

    GET_STATUSES_ALL: 'v2/proposals/statuses',

    // POST
    POST_PROPOSAL: 'v2/proposals',
    POST_AUDIENCE: 'audiences',
    POST_STRATEGY: 'v2/proposals/strategies',
    POST_BUDGET: 'v2/proposals/budgets',
    POST_CITY_TO_PROPOSAL: 'v2/proposals/{id}/geographics',
    POST_PLANNINGS: 'plannings',

    // PATCH
    PATCH_PROPOSAL_BY_ID: 'v2/proposals/{id}',
    PATCH_AUDIENCE_BY_ID: 'audiences/{id}',
    PATCH_STRATEGY_BY_ID: 'v2/proposals/strategies/{id}',
    PATCH_BUDGET: 'v2/proposals/budgets/{id}',
    PATCH_PROPOSAL_GEOGRAPHICS: 'v2/proposals/{id}/geographics',
    PATCH_REPLACE_ASSETS: 'plannings/elements/{id}/replace',

    // DEL
    DEL_PROPOSAL: 'v2/proposals/{id}',
    DEL_PROPOSAL_GEOGRAPHICS: 'v2/proposals/{proposal}/geographics/{id}',
    DEL_RESULT_ASSETS_BY_ID: 'plannings/elements/{id}',
}
