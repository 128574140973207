type PropType = {
    title: string
    text: string
}

export const TabsHeader = ({ title, text }: PropType) => {
    return (
        <div className='tabs-header'>
            <div className='tabs-title'>{title}</div>
            <div className='tabs-text'>{text}</div>
        </div>
    )
}
