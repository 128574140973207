// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
//@ts-ignore
import { utility } from "@tdt-global/utility";
import { useEffect, useRef, useState } from "react";
import MapBoxController from "../../../../../../Models/MapBoxController";
// @ts-ignore
import { Col, Row, Skeleton } from "antd";
// @ts-ignore
import { Button, ButtonsModalConfirm } from "@tdt-global/styleguide";
import { CitiesSelector } from "./MapComponents/CitiesSelector";
import { DrawerMap } from "./MapComponents/DrawerMap";
import { BodyModal } from "./MapComponents/BodyModal";
import { MapModel } from "../../../../../../Models/result/map/MapModel";
import { HeaderOptimizationModel } from "../../../../../../Models/HeaderOptimizationModel";
import { PlanningGeographicsModel } from "../../../../../../Models/result/mix/PlanningGeographicsModel";
import { HierarchyModel } from "../../../../../../Models/result/mix/HierarchyModel";
import { DataMapType } from "../../../../../../Types/result/map/DataMapType";
import { ShowModalType } from "../../../../../../Types/ShowModalType";
import { Endpoints } from "../../../../../../Common/Endpoints";
import { PlanningElementModel } from "../../../../../../Models/result/assetsList/PlanningElementModel";

mapboxgl.accessToken = utility.Config.MAP_BOX_KEY;

type PropType = {
  spin: boolean;
  data: MapModel;
  proposal: HeaderOptimizationModel;
  citiesMapData: PlanningGeographicsModel[];
  citiesMapSpin: boolean;
  assetsTypeData: HierarchyModel[];
  assetsTypeSpin: boolean;
  filterCity: number[];
  filterHierarchy: number[];
  loadResult: () => void;
  changeFilters: (value: number, keyValue: string) => void;
  handleReplaceDrawer: (data: PlanningElementModel | number) => void;
};

export const MapResult = ({
  data,
  spin,
  citiesMapData,
  citiesMapSpin,
  assetsTypeData,
  assetsTypeSpin,
  proposal,
  filterCity,
  filterHierarchy,
  loadResult,
  changeFilters,
  handleReplaceDrawer,
}: PropType) => {
  const { Functions, useAxios, ShowModal } = utility;
  const { deleteHttpRequest } = useAxios();
  const [deleteSpinner, setDeleteSpinner] = useState(false);
  const mapCtr = useRef(new MapBoxController()).current;
  const mapContainerRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState({
    visible: false,
    id: null,
  });
  const [propsModal, setPropsModal] = useState<ShowModalType>({
    show: false,
    title: "",
    className: "",
    hideModal: null,
    component: null,
    modalProps: {},
    confirmLoading: false,
  });

  useEffect(() => {
    if (spin) {
      setVisibleModal({ visible: false, id: null });
    }
  }, [spin]);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const mapInit = () => {
    mapCtr.mapContainer = mapContainerRef.current;
    mapCtr.initMap(Functions.getTranslationMap("es"));
    mapCtr.addNavigationControl();
    mapCtr.map.setZoom(8);
    mapCtr.map.setCenter({
      lng: -99.141595,
      lat: 19.411892,
    });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      mapCtr.map.resize();
    });

    const mapContainer = document
      .getElementsByClassName("map-container")
      .item(0);
    if (mapContainer instanceof Element) {
      resizeObserver.observe(mapContainer);
    }
  }, []);

  useEffect(() => {
    if (data) {
      mapCtr.destroy();
      mapInit();
    }
  }, [data, filterCity]);

  useEffect(() => {
    if (mapCtr.mapIsDefined && data) {
      const bounds = new mapboxgl.LngLatBounds();
      let { markers, boundsResult } = createMarkers(data.getDataMap, bounds);

      if (markers) {
        mapCtr.map.fitBounds(boundsResult, {
          padding: 70,
          maxZoom: 12,
        });
      }
    }
  }, [data, mapCtr]);

  const filterData = () => {
    if (filterHierarchy.length) {
      const newData = data.getDataMap.filter((item) => {
        return !filterHierarchy.includes(item.hierarchyId);
      });
      return newData;
    } else return data.getDataMap;
  };

  useEffect(() => {
    if (mapCtr.mapIsDefined && data) {
      createMarkers(filterData());
    }
  }, [filterHierarchy]);

  const createMarkers = (list, bounds?) => {
    let markers = [];
    let boundsResult = bounds;
    mapCtr.removeMarker();

    if (list.length) {
      list.map((item) => {
        let coordinates = [item.geolocation.lng, item.geolocation.lat];

        let color = item.icon.color.startsWith("#")
          ? item.icon.color
          : "#" + item.icon.color;

        const el = document.createElement("div");
        const width = 24;
        const height = 30;
        el.className = "marker-svg";
        el.style.width = `${width}px`;
        el.style.height = `${height}px`;

        const el1 = document.createElement("div");
        el1.className = "marker-1-point";
        el1.innerHTML = item.icon.label;
        el1.style.backgroundColor = color;

        const el2 = document.createElement("div");
        el2.className = "marker-point";
        el2.style.backgroundColor = color;

        el.appendChild(el1);
        el.appendChild(el2);

        el.addEventListener("click", () => {
          setVisibleModal({ id: item.id, visible: true });
        });

        markers.push(
          new mapboxgl.Marker(el).setLngLat(coordinates).addTo(mapCtr.map)
        );
        boundsResult && boundsResult.extend(coordinates);
      });
      mapCtr.markers = markers;
    }
    return { markers, boundsResult };
  };

  const skeletonLoading = () => {
    return (
      <div className="skeleton-load-map">
        <Skeleton.Input active size="small" />
      </div>
    );
  };

  const handleClickDelete = (id: any) => {
    const handleOk = () => {
      setDeleteSpinner(true);

      deleteHttpRequest(
        Endpoints.DEL_RESULT_ASSETS_BY_ID.replace("{id}", id.toString()),
        {},
        (response: any) => {
          Functions.openNotificationSuccess("Soporte eliminado.", "Éxito");
          setPropsModal({
            show: false,
            ...propsModal,
          });
          setVisibleModal({
            id: null,
            visible: false,
          });
          loadResult();
        },
        (error) => {
          Functions.openNotificationError(
            Functions.errorObject(error),
            "Error"
          );
        },
        () => {
          setDeleteSpinner(false);
        }
      );
    };
    setPropsModal({
      show: true,
      className: "modal-confirm",
      title: "Eliminar soporte",
      component: (
        <>
          Estás por eliminar el soporte. Esta acción no puede deshacerse.
          ¿Deseas eliminar el soporte?
          <ButtonsModalConfirm
            okText="Eliminar"
            cancelText="Cancelar"
            loading={deleteSpinner}
            handleOK={handleOk}
            handleCancel={hideModal}
          />
        </>
      ),
      hideModal,
      modalProps: {
        closable: false,
        width: 588,
        footer: null,
      },
    });
  };
  const hideModal = () => {
    setPropsModal({
      show: false,
      ...propsModal,
    });
  };

  return (
    <Row className="map-result" style={{ position: "relative" }}>
      <Col span={24}>
        <div ref={mapContainerRef} className="map-container" />
        {(spin || citiesMapSpin || assetsTypeSpin) && skeletonLoading()}
        {/* {citiesMapData && (
                    <CitiesSelector
                        filterCity={filterCity}
                        citiesMapData={citiesMapData}
                        disabled={spin}
                        onClose={onClose}
                        changeFilters={changeFilters}
                    />
                )} */}
        <Button
          type="ghost"
          htmlType="button"
          size="small"
          style={{
            backgroundColor: "white",
            position: "absolute",
            left: 24,
            top: 24,
            padding: "0 8px",
          }}
          disabled={spin}
          onClick={showDrawer}
        >
          Soportes
        </Button>
      </Col>

      <DrawerMap
        filterHierarchy={filterHierarchy}
        assetsTypeData={assetsTypeData}
        visible={visible}
        changeFilters={changeFilters}
        onClose={onClose}
      />
      {data && (
        <BodyModal
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          assetsList={data.getDataMap}
          proposal={proposal}
          handleReplaceDrawer={handleReplaceDrawer}
          handleOk={() => handleClickDelete(visibleModal.id)}
        />
      )}
      <ShowModal {...propsModal} />
    </Row>
  );
};
