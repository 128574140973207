import { useEffect, useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { ObjectiveModel } from '../../../../../Models/ObjectiveModel'
import { FormObjectiveItem } from './FormObjectiveItem'
import { Footer } from './Footer'
import { SubObjectiveModel } from '../../../../../Models/SubObjectiveModel'
import { ProposalModel } from '../../../../../Models/ProposalModel'
import { ShowModalType } from '../../../../../Types/ShowModalType'
// @ts-ignore
import { utility } from '@tdt-global/utility'

// @ts-ignore
import { ButtonsModalConfirm } from '@tdt-global/styleguide'

type PropType = {
    objective: ObjectiveModel
    spinnerCreate: boolean
    proposal: ProposalModel
    active: boolean
    toggle: number | null
    isAnimated: boolean
    setToggle: (num: number) => void
    onChange: (objective, subObjectives, next?: boolean) => void
}

export const ObjectiveItem = ({ ...props }: PropType) => {
    const {
        objective,
        active,
        proposal,
        spinnerCreate,
        toggle,
        isAnimated,
        setToggle,
        onChange,
    } = props
    const { ShowModal, ShowImage } = utility
    const [changeValues, setChangeValues] = useState(false)
    const [subObjective, setSubObjective] = useState<SubObjectiveModel | any>()
    const [currentValue, setCurrentValue] = useState<number[]>([])
    const [propsModal, setPropsModal] = useState<ShowModalType>({
        show: false,
        title: '',
        className: '',
        hideModal: null,
        component: null,
        modalProps: {},
        confirmLoading: false,
    })

    const onChangeHandle = (checkedValues) => {
        let array = []
        checkedValues.map((elem) => {
            array.push(objective.subObjectives.find((item) => item.id === elem))
        })
        setSubObjective(array)
        setCurrentValue(checkedValues)
    }

    useEffect(() => {
        if (currentValue) {
            let oldValues = []
            proposal.objectives
                .sort((a, b) => a.id - b.id)
                .map((item) => {
                    if (!item.primary) oldValues.push(item.id)
                })
            if (
                JSON.stringify(currentValue.sort()) !==
                JSON.stringify(oldValues)
            ) {
                setChangeValues(true)
            } else setChangeValues(false)
        }
    }, [currentValue, proposal])

    const loadOldValuesFromProposal = () => {
        let oldValues = []
        proposal.objectives.map((item) => {
            if (!item.primary) oldValues.push(item.id)
        })
        setCurrentValue(oldValues)
        onChangeHandle(oldValues)
    }

    useEffect(() => {
        if (active) {
            loadOldValuesFromProposal()
        }
    }, [active, proposal])

    const hideModal = () => {
        if (active && changeValues) {
            loadOldValuesFromProposal()
        } else {
            setToggle(null)
        }

        setPropsModal({
            show: false,
            ...propsModal,
        })
    }

    const onNext = () => {
        if (active && !changeValues) {
            onChange(objective, subObjective, true)
            setChangeValues(false)
        } else if (proposal.objectives.length && (changeValues || !active)) {
            const handleOk = () => {
                onChange(objective, subObjective, false)
                setPropsModal({
                    show: false,
                    ...propsModal,
                })
            }
            setPropsModal({
                show: true,
                className: 'modal-confirm',
                title: 'Modificar objetivo',
                component: (
                    <>
                        Estás por modificar el objetivo. Esta modificación
                        tendrá impacto sobre el resultado de la planificación.
                        ¿Deseas modificar el objetivo?
                        <ButtonsModalConfirm
                            okText='Modificar'
                            cancelText='Cancelar'
                            handleOK={handleOk}
                            handleCancel={hideModal}
                        />
                    </>
                ),
                hideModal: hideModal,
                modalProps: {
                    closable: false,
                    width: 588,
                    footer: null,
                },
            })
        } else {
            setChangeValues(false)
            onChange(objective, subObjective, true)
        }
    }

    let bool = toggle === objective.id

    const handleToggle = (id) => {
        toggle === id ? setToggle(null) : setToggle(id)
    }

    return (
        <div
            id={bool && 'objective-selected'}
            className={`objective-item ${active ? 'active' : ''}`}>
            <div
                className={`top ${bool && 'toggle'}`}
                onClick={() => handleToggle(objective.id)}>
                <div className='icon'>
                    <ShowImage url={objective.icon} isAnimated={true} />
                </div>
                <div className='description'>
                    <div className='title'>{objective.name}</div>
                    <div className='text'>{objective.description}</div>
                </div>
                <div className='toggle-button'>
                    <DownOutlined rotate={bool && -180} />
                </div>
            </div>
            <div className={`middle-container ${bool && 'toggle'}`}>
                <div className='middle'>
                    {objective.subObjectives.length ? (
                        <>
                            <span>Objetivo complementario (Opcional)</span>
                            <FormObjectiveItem
                                subObjectives={objective.subObjectives}
                                value={currentValue}
                                onChangeHandle={onChangeHandle}
                            />
                        </>
                    ) : (
                        <span>
                            Este objetivo principal no cuenta con objetivos
                            complementarios
                        </span>
                    )}
                </div>

                <div className='footer'>
                    <Footer
                        changeValues={changeValues}
                        proposal={proposal}
                        active={active}
                        onNext={onNext}
                        spinnerCreate={spinnerCreate}
                    />
                </div>
            </div>
            <ShowModal {...propsModal} />
        </div>
    )
}
