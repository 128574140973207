import { TabsHeader } from '../TabsHeader'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { Box } from '../Box'
// @ts-ignore
import { Skeleton, Slider } from 'antd'
// @ts-ignore
import { Radio, Button, SpinnerBar } from '@tdt-global/styleguide'
import { useCallback, useEffect, useState } from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import { InterestProfile } from './InterestProfile'
import useSWR from 'swr'
import { Endpoints } from '../../../../../Common/Endpoints'
import { GenderModel } from '../../../../../Models/GenderModel'
import { GenderType } from '../../../../../Types/GenderType'
import { SocioEconomicModel } from '../../../../../Models/SocioEconomicModel'
import { SocioEconomicType } from '../../../../../Types/SocioEconomicType'
import { ProposalModel } from '../../../../../Models/ProposalModel'
import { AudienceModel } from '../../../../../Models/AudienceModel'
import { loaderAbstractType } from '../../../../../Types/LoaderAbstract'

type PropType = {
    proposal: ProposalModel
    audienceLoad: boolean
    loaderAbstract: loaderAbstractType[]
    interestLoad: boolean
    handleRefreshStrategy: () => void
    finishAudience: () => void
    setLoaderAbstract: (key: string, loader: boolean) => void
    changeResultWithOutSave: (proposal: ProposalModel) => void
}

export const Audience = ({
    proposal,
    interestLoad,
    audienceLoad,
    loaderAbstract,
    handleRefreshStrategy,
    finishAudience,
    setLoaderAbstract,
    changeResultWithOutSave,
}: PropType) => {
    const { useAxios, Functions } = utility
    const { getHttpRequestSWR, patchHttpRequest } = useAxios()
    const [gender, setGender] = useState(null)
    const [audience, setAudience] = useState<AudienceModel>(null)
    const [agesRange, setAgesRange] = useState<[number, number] | any>([])
    const [gendeList, setGendeList] = useState<GenderModel[]>([])
    const [updateSpinner, setUpdateSpinner] = useState(false)
    const [request, setRequest] = useState(null)
    const [socioEconomicsList, setSocioEconomicsList] = useState<
        SocioEconomicModel[]
    >([])

    const [levelRange, setLevelRange] = useState<[number, number] | any>([1, 1])

    const load = ({ url, params }) => getHttpRequestSWR(url, params)
    const { data, error }: any = useSWR(
        {
            url: Endpoints.GET_GENDER,
        },
        load,
    )

    const { data: socioEData, error: errorSocio }: any = useSWR(
        {
            url: Endpoints.GET_SOCIOECONOMICS,
            params: { sort: ['-order'] },
        },
        load,
    )

    const setGenderHandle = useCallback(() => {
        setGendeList(
            data?.data.map((item: GenderType) => new GenderModel(item)),
        )
    }, [data])

    useEffect(() => {
        data && setGenderHandle()
    }, [data])

    const setSocioEconomicsHandle = useCallback(() => {
        setSocioEconomicsList(
            socioEData?.data.map(
                (item: SocioEconomicType) => new SocioEconomicModel(item),
            ),
        )
    }, [socioEData])

    useEffect(() => {
        socioEData && setSocioEconomicsHandle()
    }, [socioEData])

    useEffect(() => {
        // load data from proposal
        if (audience) {
            setGender(audience.gender_id)
            setAgesRange([audience.age.start, audience.age.end])
            if (audience?.socioeconomicsData) {
                let socioSort = audience?.socioeconomicsData?.sort(
                    (a, b) => b.order - a.order,
                )
                setLevelRange([
                    socioSort[0].order,
                    socioSort[socioSort.length - 1].order,
                ])
            }
        }
    }, [audience])

    useEffect(() => {
        if (proposal.audience) {
            setAudience(proposal.audience)
        }
    }, [proposal.audience])

    const isCheck = (value) => {
        return value === gender
    }

    function formatter(value) {
        let nivel = socioEconomicsList.find((item) => item.order === value)
        return `${nivel?.name}`
    }

    const onChangeAge = (ages) => {
        if (ages[0] !== ages[1]) {
            setAgesRange(ages)
        }
    }

    const agesRangeHandle = (agesArray: any[]) => {
        if (
            (agesArray[0] !== agesArray[1] &&
                agesArray[0] !== proposal.audience.age.start) ||
            agesArray[1] !== proposal.audience.age.end
        ) {
            let attributes = {
                age: {
                    start: agesArray[0],
                    end: getSupAge(agesArray[1]),
                },
            }
            updateAudience('ageRange', attributes, {})
        }
    }

    const getSupAge = (age) => {
        if (age === 70) {
            return 99
        } else return age - 1
    }

    const genderHandle = (gender_id: number) => {
        if (gender_id !== gender) {
            if (request) {
                request.cancel()
            }
            setGender(gender_id)
            let attributes = {
                gender_id: gender_id,
            }
            updateAudience('gender', attributes, {})
        }
    }

    function arrayEquals(a, b) {
        return (
            Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index])
        )
    }

    const socioRangeHandle = (socioArray: any[]) => {
        let socioSort = audience.socioeconomicsData.sort(
            (a, b) => a.order - b.order,
        )
        let socio = [socioSort[0].id, socioSort[socioSort.length - 1].id]
        if (!arrayEquals(socioArray, socio)) {
            let arr = socioArray.reverse()
            let socioeconomics = []
            Array(2)
                .fill(0)
                .map((item, index) => {
                    let socio = socioEconomicsList.find(
                        (elem) => elem.order === arr[index],
                    )
                    socioeconomics.push({
                        id: socio.id,
                        type: 'socioeconomics',
                    })
                })

            let relationships = {
                socioeconomics: {
                    data: socioeconomics,
                },
            }
            updateAudience('socioRange', {}, relationships)
        }
    }

    const updateAudience = (key, attributes, relationships) => {
        setLoaderAbstract(key, true)
        setUpdateSpinner(true)
        let params = {
            data: {
                id: audience.id,
                type: 'audiences',
                attributes: {
                    account_id: audience.account_id,
                    ...attributes,
                },
                relationships: {
                    ...relationships,
                },
            },
        }
        setRequest(
            patchHttpRequest(
                Endpoints.PATCH_AUDIENCE_BY_ID.replace(
                    '{id}',
                    audience.id.toString(),
                ),
                params,
                (response) => {
                    if (key === 'ageRange') {
                        setLoaderAbstract('ageRange', false)
                        audience.age.start = agesRange[0]
                        audience.age.end = agesRange[1]
                    }
                    if (key === 'gender') {
                        setLoaderAbstract('gender', false)
                        audience.gender_id = attributes?.gender_id
                        audience.gender = gendeList.find(
                            (item) => item.id === attributes?.gender_id,
                        )
                    }
                    if (key === 'socioRange') {
                        setLoaderAbstract('socioRange', false)
                        audience.socioeconomics =
                            relationships?.socioeconomics?.data
                        let socio = []
                        relationships?.socioeconomics?.data.map((item) => {
                            socio.push(
                                socioEconomicsList.find(
                                    (elem) => elem.id === item.id,
                                ),
                            )
                        })
                        audience.socioeconomicsData = socio
                        proposal.strategy && handleRefreshStrategy()
                    }
                    Functions.openAlertSuccess(
                        'Se han guardado todos los cambios',
                    )
                    proposal.audience = audience
                    setUpdateSpinner(false)
                    changeResultWithOutSave(proposal)
                },
                (error) => {
                    Functions.openNotificationError(
                        Functions.errorObject(error),
                        'Error',
                    )
                    setUpdateSpinner(false)
                    setLoaderAbstract(key, false)
                    setAgesRange([audience.age.start, audience.age.end])
                    setGender(audience.gender_id)
                    let socioSort = audience.socioeconomics.sort(
                        (a, b) => a.id - b.id,
                    )
                    setLevelRange([
                        socioSort[0].id,
                        socioSort[socioSort.length - 1].id,
                    ])
                },
                () => {
                    setUpdateSpinner(false)
                },
            ),
        )
    }

    return (
        <div className='audience-tab'>
            <TabsHeader
                title='Define la audiencia'
                text='La audiencia de tu campaña debe responder a la pregunta: ¿a quién va dirigida mi campaña? Crea la audiencia basándote en la edad, el género, el nivel socioeconómico y un perfil de intereses comunes.'
            />

            <Box title='Edades'>
                <div className='rango'>
                    {!audienceLoad ? (
                        <Slider
                            range
                            step={5}
                            max={70}
                            min={15}
                            dots
                            tipFormatter={(value) =>
                                value === 70 ? '65+' : value
                            }
                            value={agesRange}
                            disabled={updateSpinner}
                            tooltipVisible
                            onChange={(ages) => onChangeAge(ages)}
                            onAfterChange={(ages) => agesRangeHandle(ages)}
                        />
                    ) : (
                        <Skeleton.Button active block size={'small'} />
                    )}
                </div>
            </Box>

            <Box title='Género'>
                <div className='gender'>
                    {audienceLoad || gendeList.length === 0 ? (
                        <>
                            <div className={`gender-button `}>
                                <Skeleton.Avatar
                                    active
                                    size={'small'}
                                    shape={'circle'}
                                />
                                <Skeleton.Input
                                    style={{ width: 70 }}
                                    active
                                    size={'small'}
                                />
                            </div>
                            <div className={`gender-button `}>
                                <Skeleton.Avatar
                                    active
                                    size={'small'}
                                    shape={'circle'}
                                />
                                <Skeleton.Input
                                    style={{ width: 70 }}
                                    active
                                    size={'small'}
                                />
                            </div>
                            <div className={`gender-button `}>
                                <Skeleton.Avatar
                                    active
                                    size={'small'}
                                    shape={'circle'}
                                />
                                <Skeleton.Input
                                    style={{ width: 70 }}
                                    active
                                    size={'small'}
                                />
                            </div>
                        </>
                    ) : (
                        gendeList.map((item, index) => (
                            <div
                                key={index}
                                className={`gender-button ${
                                    updateSpinner ? 'disabled' : ''
                                } ${isCheck(item.id) ? 'check' : ''}`}
                                onClick={() =>
                                    !updateSpinner && genderHandle(item.id)
                                }>
                                <Radio
                                    checked={isCheck(item.id)}
                                    disabled={updateSpinner}
                                />
                                <span>{item.name}</span>
                            </div>
                        ))
                    )}
                </div>
            </Box>

            <Box title='Nivel socioeconómico'>
                <div className='level'>
                    {!audienceLoad && socioEData ? (
                        <Slider
                            range
                            step={1}
                            min={1}
                            dots
                            value={levelRange}
                            max={socioEconomicsList.length}
                            reverse={true}
                            tipFormatter={formatter}
                            tooltipVisible
                            disabled={updateSpinner}
                            onChange={(level) => setLevelRange(level)}
                            onAfterChange={(level) => socioRangeHandle(level)}
                        />
                    ) : (
                        <Skeleton.Button active block size={'small'} />
                    )}
                </div>
            </Box>

            <SpinnerBar
                loading={
                    loaderAbstract.find((item) => item.key === 'interest')
                        ?.loader
                }>
                <Box title='Perfil de intereses'>
                    <InterestProfile
                        interestLoad={interestLoad}
                        loaderAbstract={loaderAbstract}
                        proposal={proposal}
                        updateSpinner={updateSpinner}
                        setUpdateSpinner={setUpdateSpinner}
                        changeResultWithOutSave={changeResultWithOutSave}
                        setLoaderAbstract={setLoaderAbstract}
                    />
                </Box>
            </SpinnerBar>

            <div className='footer-next'>
                <Button onClick={finishAudience} type={'primary'}>
                    Siguiente <ArrowRightOutlined />
                </Button>
                <span className='next'>
                    Próximo paso, configura la estrategia.
                </span>
            </div>
        </div>
    )
}
