// @ts-ignore
import { Button } from '@tdt-global/styleguide'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Endpoints } from '../../../../../Common/Endpoints'
import { Path } from '../../../../../Common/Path'
import { HeaderOptimizationModel } from '../../../../../Models/HeaderOptimizationModel'
import { PerformanceModel } from '../../../../../Models/PerformanceModel'
import { ProposalModel } from '../../../../../Models/ProposalModel'
import { AssetsListModel } from '../../../../../Models/result/assetsList/AssetsListModel'
import { MapModel } from '../../../../../Models/result/map/MapModel'
import { HierarchyModel } from '../../../../../Models/result/mix/HierarchyModel'
import { MixMedioModel } from '../../../../../Models/result/mix/MixMedioModel'
import { PlanningGeographicsModel } from '../../../../../Models/result/mix/PlanningGeographicsModel'
import { HeaderOptimization } from './components/HeaderOptimization'
import { TabsResult } from './components/TabsResult'
import { AssetsList } from './tabs/AssetsList'
import { MapResult } from './tabs/MapResult'
import { Mix } from './tabs/Mix'
import { Performance } from './tabs/Performance'
import { Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { BodyDrawerReplace } from './components/BodyDrawerReplace'
import { PlanningElementModel } from '../../../../../Models/result/assetsList/PlanningElementModel'

type PropType = {
    proposal: ProposalModel
}

export const Result = ({ proposal }: PropType) => {
    const { useAxios, Functions, Config } = utility
    const { getHttpRequest } = useAxios()
    const [tabKey, setTabKey] = useState('1')
    const [headerSpin, setHeaderSpin] = useState(true)
    const [headerData, setHeaderData] = useState(null)
    const [performanceSpin, setPerformanceSpin] = useState(true)
    const [performanceData, setPerformanceData] = useState(null)
    const [mixSpin, setMixSpin] = useState(true)
    const [mixData, setMixData] = useState(null)
    const [assetsSpin, setAssetsSpin] = useState(true)
    const [assetsData, setAssetsData] = useState(null)
    const [mapSpin, setMapSpin] = useState(true)
    const [mapData, setMapData] = useState<MapModel>(null)
    const [citiesMapSpin, setCitiesMapSpin] = useState(true)
    const [citiesMapData, setCitiesMapData] = useState(null)
    const [assetsTypeSpin, setAssetsTypeSpin] = useState(true)
    const [assetsTypeData, setAssetsTypeData] = useState(null)
    const [axiosMap, setAxiosMap] = useState(null)
    const [filterCity, setFilterCity] = useState<number[]>([])
    const [filterHierarchy, setFilterHierarchy] = useState<number[]>([])
    const [drawerOpen, setDrawerOpen] = useState({
        visible: false,
        id: null,
    })

    const navigate = useNavigate()
    const proposalId = proposal.id

    const changeFilters = (value: number, keyValue: string) => {
        if (keyValue === 'city') {
            if (filterCity.includes(value)) {
                setFilterCity(filterCity.filter((item) => item !== value))
            } else {
                setFilterCity([...filterCity, value])
            }
        } else {
            if (filterHierarchy.includes(value)) {
                setFilterHierarchy(
                    filterHierarchy.filter((item) => item !== value),
                )
            } else {
                setFilterHierarchy([...filterHierarchy, value])
            }
        }
    }

    useEffect(() => {
        if (proposal.planningsId) {
            loadResult()
        }
    }, [proposal])

    useEffect(() => {
        if (proposal.planningsId) {
            if (axiosMap) {
                axiosMap.cancel()
                loadMap()
            }
        }
    }, [filterCity])

    const loadResult = () => {
        loadHeader()
        loadPerformance()
        loadMix()
        loadAssets()
        loadMap()
        loadCitiesMap()
        loadAssetsTypeMap()
    }

    const loadAssetsTypeMap = () => {
        setAssetsTypeSpin(true)
        getHttpRequest(
            Endpoints.GET_RESULT_HIERARCHIES_MAP.replace(
                '{id}',
                proposal.planningsId.toString(),
            ),
            {},
            (response) => {
                setAssetsTypeData(
                    response.data.data.map(
                        (item) =>
                            new HierarchyModel(item, response.data.included),
                    ),
                )
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {
                setAssetsTypeSpin(false)
            },
        )
    }

    const loadCitiesMap = () => {
        setCitiesMapSpin(true)
        getHttpRequest(
            Endpoints.GET_RESULT_GEOGRAFIC_MAP.replace(
                '{id}',
                proposal.planningsId.toString(),
            ),
            {},
            (response) => {
                setCitiesMapData(
                    response.data.data.map(
                        (item) =>
                            new PlanningGeographicsModel(
                                item,
                                response.data.included,
                            ),
                    ),
                )
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {
                setCitiesMapSpin(false)
            },
        )
    }

    const loadMap = () => {
        setMapSpin(true)

        let params: any = {
            include: ['element.vendor'],
        }

        if (filterCity.length > 0) {
            params = {
                ...params,
                'filter[planningGeographic]': filterCity,
            }
        }

        setAxiosMap(
            getHttpRequest(
                Endpoints.GET_RESULT_MAP.replace(
                    '{id}',
                    proposal.planningsId.toString(),
                ),
                params,
                (response) => {
                    setFilterHierarchy([])
                    setMapData(
                        new MapModel(response.data, response.data.included),
                    )
                },
                (error) => {
                    Functions.openNotificationError(
                        Functions.errorObject(error),
                        'Error',
                    )
                },
                () => {
                    setMapSpin(false)
                },
                { baseURL: Config.END_POINT_PLANNING + 'api/' },
            ),
        )
    }

    const loadAssets = () => {
        setAssetsSpin(true)
        getHttpRequest(
            Endpoints.GET_RESULT_MIX_ELEMENTS.replace(
                '{id}',
                proposal.planningsId.toString(),
            ),
            {
                include: ['element.vendor'],
            },
            (response) => {
                setAssetsData(
                    new AssetsListModel(response.data, response.data.included),
                )
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {
                setAssetsSpin(false)
            },
            { baseURL: Config.END_POINT_PLANNING + 'api/' },
        )
    }

    const loadMix = () => {
        setMixSpin(true)
        getHttpRequest(
            Endpoints.GET_RESULT_MIX.replace(
                '{id}',
                proposal.planningsId.toString(),
            ),
            {},
            (response) => {
                setMixData(
                    new MixMedioModel(response.data, response.data.included),
                )
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {
                setMixSpin(false)
            },
        )
    }

    const loadHeader = () => {
        setHeaderSpin(true)
        getHttpRequest(
            Endpoints.GET_RESULT_HEADER.replace(
                '{id}',
                proposal.planningsId.toString(),
            ),
            {},
            (response) => {
                setHeaderData(
                    new HeaderOptimizationModel(
                        response.data,
                        response.data.included,
                    ),
                )
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {
                setHeaderSpin(false)
            },
        )
    }
    const loadPerformance = () => {
        setPerformanceSpin(true)
        getHttpRequest(
            Endpoints.GET_RESULT_PERFORMANCE.replace(
                '{id}',
                proposal.planningsId.toString(),
            ),
            {},
            (response) => {
                setPerformanceData(
                    new PerformanceModel(response.data, response.data.included),
                )
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {
                setPerformanceSpin(false)
            },
        )
    }

    const handleReplaceDrawer = (id: number) => {
        setDrawerOpen({
            visible: true,
            id,
        })
    }

    const onClose = () => {
        setDrawerOpen({
            visible: false,
            id: null,
        })
    }

    const renderTab = () => {
        switch (tabKey) {
            case '1':
                return (
                    <Performance
                        spin={performanceSpin}
                        data={performanceData}
                    />
                )
                break
            case '2':
                return (
                    <Mix spin={mixSpin} data={mixData} proposal={headerData} />
                )
                break
            case '3':
                return (
                    <MapResult
                        spin={mapSpin}
                        data={mapData}
                        citiesMapData={citiesMapData}
                        citiesMapSpin={citiesMapSpin}
                        assetsTypeData={assetsTypeData}
                        assetsTypeSpin={assetsTypeSpin}
                        proposal={headerData}
                        filterCity={filterCity}
                        filterHierarchy={filterHierarchy}
                        loadResult={loadResult}
                        changeFilters={changeFilters}
                        handleReplaceDrawer={handleReplaceDrawer}
                    />
                )
                break
            case '4':
                return (
                    <AssetsList
                        spin={assetsSpin}
                        data={assetsData}
                        proposal={headerData}
                        loadResult={loadResult}
                        handleReplaceDrawer={handleReplaceDrawer}
                    />
                )
                break
            default:
                break
        }
        return ''
    }

    return (
        <div className='optimization-tab'>
            <header>
                <div className='top'>
                    <div className='title'>Resultado</div>
                    <div className='save-button'>
                        <Button
                            type='primary'
                            onClick={() =>
                                navigate(Path.PLANNING, {
                                    state: { id: proposalId },
                                })
                            }>
                            Guardar planificación
                        </Button>
                    </div>
                </div>
                <HeaderOptimization spin={headerSpin} data={headerData} />
            </header>
            <section>
                <TabsResult tabKey={tabKey} setTabKey={setTabKey} />
                <div className='container-tab'>{renderTab()}</div>
            </section>

            <Drawer
                title={`Reemplazar soporte`}
                className='drawer-replace'
                placement='right'
                size={'large'}
                width={1300}
                zIndex={2000}
                closable={false}
                onClose={onClose}
                visible={drawerOpen.visible}
                extra={
                    <CloseOutlined
                        onClick={onClose}
                        style={{ color: '#8C8C8C' }}
                    />
                }>
                <BodyDrawerReplace
                    id={drawerOpen.id}
                    onClose={onClose}
                    loadResult={loadResult}
                    proposal={headerData}
                />
            </Drawer>
        </div>
    )
}
