// @ts-ignore
import { Skeleton, Divider } from 'antd'

export const MixPanelSkeleton = () => {
    
    const skeletonPurple = (
        <Skeleton.Button
            active
            style={{
                height: '12px',
                backgroundColor: '#c8b7f5b8',
                borderRadius: '2px',
                width: '120px',
            }}
        />
    )
    return (
        <div className='mix-panel-item'>
            <div className='image'>
                <Skeleton.Button
                    active
                    style={{ height: '161px', width: '100%' }}
                />
            </div>
            <div className='body'>
                <div className='info'>
                    {skeletonPurple}
                    <Skeleton.Button
                        active
                        style={{ height: '12px', width: '120px' }}
                    />
                    {skeletonPurple}
                    {skeletonPurple}
                    <Divider />
                    {skeletonPurple}
                    {skeletonPurple}
                    <Divider />
                </div>
            </div>
        </div>
    )
}
