// @ts-ignore
import { Collapse, Divider, Skeleton } from 'antd'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { MixPanel } from './MixComponents/MixPanel'
import { MixMedioModel } from '../../../../../../Models/result/mix/MixMedioModel'
import { MixPanelSkeleton } from './MixComponents/MixPanelSkeleton'
import { HeaderOptimizationModel } from '../../../../../../Models/HeaderOptimizationModel'

const { Panel } = Collapse

type PropType = {
    spin: boolean
    data: MixMedioModel
    proposal: HeaderOptimizationModel
}

export const Mix = ({ data, spin, proposal }: PropType) => {
    const { Functions } = utility

    const getExtra = (otsValue: number) =>
        spin ? (
            <div className='skeletons'>
                <Skeleton.Button
                    active
                    style={{ height: '12px', backgroundColor: '#c8b7f5b8' }}
                />
            </div>
        ) : (
            `${Functions.formatNumber(otsValue.toString())} OTS`
        )

    const getHeader = (name, assetsBool) => {
        let extra = assetsBool ? '' : 'No existen soportes en esta ciudad'

        return (
            <div className='skeletons'>
                {spin ? (
                    <Skeleton.Button
                        active
                        style={{ height: '12px', backgroundColor: '#c8b7f5b8' }}
                    />
                ) : (
                    <div>
                        {name}
                        <Divider type='vertical' />
                        {extra && <span className='not-assets'>{extra}</span>}
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className='collapse-result'>
            {spin ? (
                 <div className='skeletons-extra'>
                 <Collapse
                     accordion
                     bordered={false}
                     defaultActiveKey={['0']}
                     expandIconPosition={'left'}>
                     <Panel
                         header={
                             <Skeleton.Button
                                 active
                                 className='skeleton-purple'
                             />
                         }
                         showArrow={false}
                         key={0}
                         extra={
                             <div className='skeletons'>
                                 <Skeleton.Button
                                     active
                                     className='skeleton-purple'
                                 />
                             </div>
                         }>
                         <div className='mix-panel'>
                             <div className='mix-panel-list'>
                                 <MixPanelSkeleton />
                                 <MixPanelSkeleton />
                                 <MixPanelSkeleton />
                                 <MixPanelSkeleton />
                                 <MixPanelSkeleton />
                                 <MixPanelSkeleton />
                             </div>
                         </div>
                     </Panel>
                     <Panel
                         header={
                             <Skeleton.Button
                                 active
                                 className='skeleton-purple'
                             />
                         }
                         showArrow={false}
                         key={1}
                         extra={
                             <div className='skeletons'>
                                 <Skeleton.Button
                                     active
                                     className='skeleton-purple'
                                 />
                             </div>
                         }></Panel>
                     <Panel
                         header={
                             <Skeleton.Button
                                 active
                                 className='skeleton-purple'
                             />
                         }
                         showArrow={false}
                         key={2}
                         extra={
                             <div className='skeletons'>
                                 <Skeleton.Button
                                     active
                                     className='skeleton-purple'
                                 />
                             </div>
                         }></Panel>
                     <Panel
                         header={
                             <Skeleton.Button
                                 active
                                 className='skeleton-purple'
                             />
                         }
                         showArrow={false}
                         key={3}
                         extra={
                             <div className='skeletons'>
                                 <Skeleton.Button
                                     active
                                     className='skeleton-purple'
                                 />
                             </div>
                         }></Panel>
                 </Collapse>
             </div>
                
            ) : (
                <Collapse
                accordion
                bordered={false}
                defaultActiveKey={['0']}
                expandIconPosition={'left'}>
                {data?.getMixMedioData.map((item, index: number) => (
                    <Panel
                        header={getHeader(
                            item.planningGeographics.city.name,
                            !!item.elements.length,
                        )}
                        key={index}
                        extra={getExtra(
                            item.planningGeographics.ots_estimated,
                        )}>
                        <MixPanel panel={item} proposal={proposal} />
                    </Panel>
                ))}
            </Collapse>
            )}
        </div>
    )
}
