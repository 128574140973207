import { StrategyType } from '../Types/StrategyType'

export class StrategyModel {
    private _id: number | null
    private _key: number | null
    private _proposal_id: number
    private _type: string | null
    private _impact: number
    private _coverage: number
    private _segmentation_high: number
    private _segmentation_low: number
    private _frequency: number
    private _description: string | null

    constructor(strategy?: StrategyType) {
        this._id = strategy.id
        this._key = strategy.id
        this._proposal_id = strategy.attributes.proposal_id
        this._impact = strategy.attributes.impact
        this._coverage = strategy.attributes.coverage
        this._segmentation_high = strategy.attributes.segmentation_high
        this._segmentation_low = strategy.attributes.segmentation_low
        this._frequency = strategy.attributes.frequency
        this._description = strategy.attributes.description
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get key(): number {
        return this._key
    }

    set key(value) {
        this._key = value
    }

    get impact(): number {
        return this._impact
    }

    set impact(value) {
        this._impact = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get proposal_id(): number {
        return this._proposal_id
    }

    set proposal_id(value) {
        this._proposal_id = value
    }

    get coverage(): number {
        return this._coverage
    }

    set coverage(value) {
        this._coverage = value
    }

    get segmentation_high(): number {
        return this._segmentation_high
    }

    set segmentation_high(value) {
        this._segmentation_high = value
    }

    get segmentation_low(): number {
        return this._segmentation_low
    }

    set segmentation_low(value) {
        this._segmentation_low = value
    }

    get frequency(): number {
        return this._frequency
    }

    set frequency(value) {
        this._frequency = value
    }

    get description(): string {
        return this._description
    }

    set description(value) {
        this._description = value
    }
}
