// @ts-ignore
import { Drawer, Divider } from 'antd'
// @ts-ignore
import { Switch } from '@tdt-global/styleguide'
import { HierarchyModel } from '../../../../../../../Models/result/mix/HierarchyModel'

type PropType = {
    visible: boolean
    assetsTypeData: HierarchyModel[]
    filterHierarchy: number[]
    onClose: () => void
    changeFilters: (value: number, keyValue: string) => void
}

export const DrawerMap = ({
    assetsTypeData,
    changeFilters,
    onClose,
    filterHierarchy,
    visible,
}: PropType) => {
    const onChangeSwitch = (checked: boolean, id: number) => {
        changeFilters(id, 'hierarchy')
    }

    return (
        <Drawer
            placement='left'
            onClose={onClose}
            mask={true}
            getContainer={false}
            style={{ position: 'absolute' }}
            visible={visible}>
            <div className='drawer'>
                <div className='drawer-types'>
                    <div className='drawer-title'>Tipo de soportes</div>
                    <div className='drawer-content'>
                        {assetsTypeData?.map((item, index) => (
                            <div key={index} className='content-item'>
                                <div>
                                    <div
                                        className='color'
                                        style={{
                                            backgroundColor:
                                                item.colour.startsWith('#')
                                                    ? item.colour
                                                    : '#' + item.colour,
                                        }}>
                                        <span className='align-acronym'>{item.acronym}</span>
                                    </div>
                                </div>
                                <div className='title-2'>{item.name}</div>
                                <Divider type='vertical' className='divider'/>
                                <div className='sub-category'>
                                    {item.hierarchy?.name}
                                </div>
                                <div className='acction'>
                                    <Switch
                                        size='small'
                                        checked={
                                            !filterHierarchy.includes(item.id)
                                        }
                                        onChange={(v) =>
                                            onChangeSwitch(v, item.id)
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className='drawer-ots'>
                    <div className='drawer-title'>OTS</div>
                    <div className='ots-content'>
                        <div className='title-2'>Mostrar OTS</div>
                        <div className='acction'>
                            <Switch size='small' defaultChecked />
                        </div>
                    </div>
                </div> */}
            </div>
        </Drawer>
    )
}
