import { AudienceType } from '../Types/AudienceType'
import { GenderModel } from './GenderModel'
import { InterestModel } from './InterestModel'
import { SocioEconomicModel } from './SocioEconomicModel'

type AgeType = {
    start: number
    end: number
}

type SocioeconomicsType = {
    id: number
    type: string
}

export class AudienceModel {
    private _id: number
    private _name: string
    private _type: string
    private _account_id: number | null
    private _user_id: string
    private _gender_id: number
    private _age: AgeType
    private _created_at: string
    private _socioeconomics: SocioeconomicsType[]
    private _socioeconomicsData: SocioEconomicModel[]
    private _gender?: GenderModel

    constructor(audience: AudienceType, included?) {
        this._id = audience.id
        this._name = audience.attributes.name
        this._account_id = audience.attributes.account_id
        this._user_id = audience.attributes.user_id
        this._gender_id = audience.attributes.gender_id
        this._socioeconomics = audience.relationships.socioeconomics.data
        this._created_at = audience.attributes.created_at
        this._gender = null
        this._socioeconomicsData = null

        this._age = {
            start: audience.attributes.age.start,
            end: this.getSupAgeProposal(audience.attributes.age.end),
        }

        if (included) {
            audience.attributes.gender_id
                ? (this._gender = new GenderModel(
                      included.find(
                          (item) =>
                              item.id ===
                                  audience.relationships.gender.data.id &&
                              item.type ===
                                  audience.relationships.gender.data.type,
                      ),
                  ))
                : (this._gender = null)

            let socioArray = []

            audience.relationships.socioeconomics.data.map((item) => {
                let socio = included.find(
                    (elem) => elem.id === item.id && elem.type === item.type,
                )
                socio && socioArray.push(new SocioEconomicModel(socio))
            })
            this._socioeconomicsData = socioArray.sort(
                (a, b) => b.order - a.order,
            )
        }
    }

    getSupAgeProposal = (age) => {
        if (age === 99) {
            return 70
        } else return age + 1
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get account_id(): number {
        return this._account_id
    }

    set account_id(value) {
        this._account_id = value
    }

    get user_id(): string {
        return this._user_id
    }

    set user_id(value) {
        this._user_id = value
    }

    get gender_id(): number {
        return this._gender_id
    }

    set gender_id(value) {
        this._gender_id = value
    }

    get age(): AgeType {
        return this._age
    }

    set age(value) {
        this._age = value
    }

    get socioeconomics(): SocioeconomicsType[] {
        return this._socioeconomics
    }

    set socioeconomics(value) {
        this._socioeconomics = value
    }

    get created_at(): string {
        return this._created_at
    }

    set created_at(value) {
        this._created_at = value
    }

    get gender(): GenderModel {
        return this._gender
    }

    set gender(value) {
        this._gender = value
    }

    get socioeconomicsData(): SocioEconomicModel[] {
        return this._socioeconomicsData
    }

    set socioeconomicsData(value) {
        this._socioeconomicsData = value
    }
}
