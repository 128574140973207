import { useEffect, useState } from 'react'

import { PerformanceModel } from '../../../../../../Models/PerformanceModel'
import { ChartContainer } from './Components/ChartContainer'
import { EstimatedAge } from './Components/EstimatedAge'
import { EstimatedGender } from './Components/EstimatedGender'
import { GeographicalDistributionChart } from './Components/GeographicalDistributionChart'
import { InfoCardList } from './Components/InfoCardList'
import { InvestmentOptimization } from './Components/InvestmentOptimization'
import { InvestmentTypeSupport } from './Components/InvestmentTypeSupport'
//@ts-ignore
import { utility } from '@tdt-global/utility'

type PropType = {
    spin: boolean
    data: PerformanceModel
}

export const Performance = ({ spin, data }: PropType) => {
    const { Functions } = utility
    const [cardLists, setCardLists] = useState<any>([])
    const [genderData, setGenderData] = useState([])
    const [ageRange, setAgeRange] = useState<any[]>([])

    useEffect(() => {
        if (!spin) {
            const { planning, proposalBudget } = data
            const {
                cpmEstimated,
                cpmTotal,
                otsEstimated,
                otsTotal,
                populationEstimated,
                populationTotal,
                budgetTotal,
                budgetEstimated,
            } = planning
            const { amount, currency, flex_value } = proposalBudget
            let flex =
                data?.proposalBudget?.flex_type_id === 1
                    ? `${currency?.iso_code} ${Functions.formatNumber(
                          flex_value,
                      )}`
                    : `${flex_value}%`
            setCardLists([
                {
                    headerTitle: 'Presupuesto',
                    bodyTitle1: 'Original',
                    value1: (
                        <>
                            <span>{`${
                                currency?.iso_code
                            } ${Functions.formatNumber(amount)}`}</span>
                            {flex_value && <span>{`Flex ${flex}`}</span>}
                        </>
                    ),
                    bodyTitle2: 'Final',
                    value2: `${currency?.iso_code} ${Functions.formatNumber(
                        budgetEstimated,
                    )}`,
                },
                {
                    headerTitle: 'Alcance estimado (personas)',
                    bodyTitle1: 'Total',
                    value1: (
                        <span>
                            {Functions.formatNumber(populationEstimated)}
                        </span>
                    ),
                    bodyTitle2: 'Basado en audiencia',
                    value2: Functions.formatNumber(populationTotal),
                },

                {
                    headerTitle: 'CPM',
                    bodyTitle1: 'Total',
                    value1: (
                        <span>{`${currency?.iso_code} ${Functions.formatNumber(
                            cpmEstimated,
                        )}`}</span>
                    ),
                    bodyTitle2: 'Basado en audiencia',
                    value2: `${currency?.iso_code} ${Functions.formatNumber(
                        cpmTotal,
                    )}`,
                },
                {
                    headerTitle: 'OTS',
                    bodyTitle1: 'Totales',
                    value1: <span>{Functions.formatNumber(otsEstimated)}</span>,
                    bodyTitle2: 'Basado en audiencia',
                    value2: Functions.formatNumber(otsTotal),
                },
                ,
            ])
            setGenderData(
                data?.genders.map((item) => ({
                    type: item.genderId,
                    name: item.label,
                    value: item.value.toString(),
                    percentage: item.percentage,
                })),
            )
            setAgeRange(
                data?.ageRanges.map((item, index) => ({
                    id: index,
                    name: item.label,
                    value: item.value.toString(),
                    selected: item.selected,
                })),
            )
        }
    }, [data, spin])

    return (
        <div className='performance'>
            <InfoCardList cardLists={cardLists} load={spin} />
            <InvestmentOptimization
                strategy={data?.proposal?.strategy?.description}
                load={spin}
                data={data?.radarChart}
            />
            <div className='flex-chart'>
                <ChartContainer title={'Inversión por distribución geográfica'}>
                    <GeographicalDistributionChart
                        valueToShow='budget'
                        geographics={data?.geographics}
                        load={spin}
                    />
                </ChartContainer>
                <ChartContainer title={'Inversión por tipo de soporte'}>
                    <InvestmentTypeSupport
                        valueToShow='budget'
                        classifications={data?.elementClassifications}
                        load={spin}
                    />
                </ChartContainer>
            </div>
            <div className='flex-chart'>
                <ChartContainer title={'Alcance estimado por género'}>
                    <EstimatedGender load={spin} data={genderData} />
                </ChartContainer>
                <ChartContainer title={'Alcance estimado por edades'}>
                    <EstimatedAge
                        load={spin}
                        data={ageRange}
                        population={data?.planning?.populationTotal}
                    />
                </ChartContainer>
            </div>
            <div className='flex-chart'>
                <ChartContainer title={'OTS por distribución geográfica'}>
                    <GeographicalDistributionChart
                        valueToShow='ots_estimated'
                        geographics={data?.geographics}
                        load={spin}
                    />
                </ChartContainer>
                <ChartContainer title={'OTS por tipo de soporte'}>
                    <InvestmentTypeSupport
                        valueToShow='ots_estimated'
                        classifications={data?.elementClassifications}
                        load={spin}
                    />
                </ChartContainer>
            </div>
        </div>
    )
}
