import { useSearchParams } from 'react-router-dom'
// @ts-ignore
import { Skeleton } from 'antd'
// @ts-ignore
import { SpinnerBar } from '@tdt-global/styleguide'

export const SkeletonLoad = () => {
    let [searchParams] = useSearchParams()
    return (
        <div className='skeleton-load'>
            <div className='navigation'>
                <Skeleton.Input active size='small' />
                <Skeleton.Input active size='small' />
                <Skeleton.Input active size='small' />
                <Skeleton.Input active size='small' />
                <Skeleton.Input active size='small' />
                <Skeleton.Input active size='small' />
            </div>
            {Number(searchParams.get('tab')) !== 5 ? (
                <div className='skeleton-load-proposal'>
                    <div className='content'>
                        <Skeleton.Input active size='small' />
                        <Skeleton.Input active size='large' />

                        <Skeleton.Input active size='large' />
                    </div>
                    <div className='abstract'>
                        <Skeleton.Input active size='small' />
                        <Skeleton.Input active size='small' />
                        <Skeleton.Input active size='small' />
                        <Skeleton.Input active size='small' />
                        <Skeleton.Input active size='small' />
                        <Skeleton.Input active size='small' />
                        <Skeleton.Input active size='small' />
                        <Skeleton.Input active size='small' />
                        <Skeleton.Input active size='small' />
                        <Skeleton.Input active size='small' />
                    </div>
                </div>
            ) : (
                <div className='skeleton-load-planning'>
                    <div className='skeleton-load-header'>
                        <div className='skeleton-load-top'>
                            <Skeleton.Input active size='small' />
                        </div>
                        <SpinnerBar loading={true}></SpinnerBar>
                        <div className='skeleton-load-bottom'>
                            <Skeleton.Input active size='large' />
                        </div>
                    </div>

                    <div className='skeleton-load-container'>
                        <div className='skeleton-load-tabs'>
                            <div className='skeleton-load-tab'></div>
                            <div className='skeleton-load-tab'></div>
                            <div className='skeleton-load-tab'></div>
                            <div className='skeleton-load-tab'></div>
                        </div>
                        <div className='skeleton-load-content'>
                            <div className='section1'>
                                <Skeleton.Input
                                    className='ant-skeleton-100'
                                    active
                                    size='large'
                                />
                                <Skeleton.Input
                                    className='ant-skeleton-100'
                                    active
                                    size='large'
                                />
                                <Skeleton.Input
                                    className='ant-skeleton-100'
                                    active
                                    size='large'
                                />
                                <Skeleton.Input
                                    className='ant-skeleton-100'
                                    active
                                    size='large'
                                />
                            </div>
                            <div className='skeleton-load-body'>
                                <Skeleton.Input
                                    className='ant-skeleton-100'
                                    active
                                    size='small'
                                />
                                <Skeleton.Input
                                    className='ant-skeleton-100'
                                    active
                                    size='large'
                                />

                                <Skeleton.Input
                                    className='ant-skeleton-100 h300'
                                    active
                                    size='large'
                                />
                                <div className='skeleton-load-footer'>
                                    <div className='skeleton-load-footer-item'>
                                        <Skeleton.Input
                                            className='ant-skeleton-100'
                                            active
                                            size='small'
                                        />
                                        <Skeleton.Input
                                            className='ant-skeleton-100 h437'
                                            active
                                            size='large'
                                        />
                                    </div>

                                    <div className='skeleton-load-footer-item'>
                                        <Skeleton.Input
                                            className='ant-skeleton-100'
                                            active
                                            size='small'
                                        />
                                        <Skeleton.Input
                                            className='ant-skeleton-100 h437'
                                            active
                                            size='large'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
