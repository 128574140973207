// @ts-ignore
import { Popover } from 'antd'
// @ts-ignore
import { Button } from '@tdt-global/styleguide'
import { useEffect, useState } from 'react'
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    InfoCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons'
import { ProposalModel } from '../../../../../../Models/ProposalModel'
import { ChartBudget } from '../../../ChartBudget'
import { CityInputForm } from './CityInputForm'
import { CurrenciesModel } from '../../../../../../Models/CurrenciesModel'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { SuggestedBudgetModel } from '../../../../../../Models/SuggestedBudgetModel'
import { ProposalGeographicsModel } from '../../../../../../Models/ProposalGeographicsModel'

type PropType = {
    proposal: ProposalModel
    form: any
    budgetDistributionSuggested: SuggestedBudgetModel[]
    currencies: CurrenciesModel[]
    disableDistributionRestore: boolean
    disabledAll: boolean
    getSuggestedBudget: (any?: any) => void
}

export const BudgetDistribution = ({
    proposal,
    form,
    currencies,
    disabledAll,
    budgetDistributionSuggested,
    disableDistributionRestore,
    getSuggestedBudget,
}: PropType) => {
    const { Functions } = utility
    const [visible, setVisible] = useState(false)
    const [restoreVisible, setRestoreVisible] = useState(false)
    const [exedent, setExedent] = useState(0)
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (proposal.budget?.amount && proposal?.proposalGeographics) {
            let sum = 0
            proposal?.proposalGeographics?.map((item) => {
                sum += item.budget
            })

            setExedent(sum - Number(proposal.budget?.amount))
            isDisabled(
                budgetDistributionSuggested,
                proposal.proposalGeographics,
            )
        } else {
            setDisabled(true)
        }
    }, [
        proposal.proposalGeographics,
        proposal.budget,
        disableDistributionRestore,
    ])

    const isDisabled = (
        suggested: SuggestedBudgetModel[],
        proposalGeographics: ProposalGeographicsModel[],
    ) => {
        if (disableDistributionRestore) {
            for (let index = 0; index < suggested?.length; index++) {
                let proposalElement = proposalGeographics.find(
                    (elem) => elem.id === suggested[index].id,
                )
                if (proposalElement.budget !== suggested[index].amount) {
                    return setDisabled(false)
                    break
                }
            }
            return setDisabled(true)
        }
        return setDisabled(false)
    }

    const handleRestoreVisibleChange = (restoreVisible) => {
        setRestoreVisible(restoreVisible)
    }

    return (
        <div className='budget-distribution'>
            <div className='header'>
                <div className='title'>
                    <span>Distribución del presupuesto</span>
                    <Popover
                        placement='topLeft'
                        style={{ maxWidth: '300px' }}
                        content={
                            <div
                                style={{
                                    color: '#595959',
                                    maxWidth: '300px',
                                }}
                                className='description'>
                                No se incluye el presupuesto flexible. El
                                presupuesto flexible será ejecutado si al
                                momento de generar el resultado no se encuentran
                                soportes dentro del presupuesto original que
                                cumplan con las necesidades de la planificación.
                            </div>
                        }
                        trigger='click'
                        arrowPointAtCenter
                        visible={visible}
                        onVisibleChange={(visible) => setVisible(visible)}>
                        <InfoCircleOutlined />
                    </Popover>
                </div>
                <div className='refresh-button'>
                    <Button
                        onClick={getSuggestedBudget}
                        disabled={disabled || disabledAll}
                        type='ghost'
                        size='small'>
                        Restaurar valores
                        <SyncOutlined />
                    </Button>
                    <Popover
                        placement='topRight'
                        overlayStyle={{ width: '320px' }}
                        content={
                            <div style={{ color: '#595959' }}>
                                Restaura los valores iniciales sugeridos por
                                Planning.
                            </div>
                        }
                        trigger='click'
                        arrowPointAtCenter
                        visible={restoreVisible}
                        onVisibleChange={handleRestoreVisibleChange}>
                        <InfoCircleOutlined />
                    </Popover>
                </div>
            </div>
            <div className='container'>
                <div className='left'>
                    <div className='campaign-cities'>
                        <div className='title'>Ciudades de tu campaña</div>
                        <div className='body'>
                            {proposal.proposalGeographics?.length &&
                            proposal.budget?.amount ? (
                                <CityInputForm
                                    currencies={currencies}
                                    form={form}
                                    disabledAll={disabledAll}
                                    proposal={proposal}
                                />
                            ) : (
                                'Ingresa el presupuesto y elige al menos una ciudad para activar la distribución.'
                            )}
                        </div>
                    </div>
                </div>
                <div className='center'></div>
                <div className='right'>
                    <div className='title'>
                        Gráfico de distribución del presupuesto
                    </div>

                    {proposal.proposalGeographics?.length &&
                    proposal.budget?.amount ? (
                        <>
                            <span>
                                Para la distribución del presupuesto Planning
                                calcula de forma inteligente cuál es la mejor
                                opción en base a la audiencia que hayas definido
                                para tu campaña.
                            </span>
                            <ChartBudget
                                label={false}
                                legendPosition='center'
                                distribution={proposal.proposalGeographics}
                            />
                        </>
                    ) : (
                        <>
                            <span>
                                Ingresa el presupuesto y elige al menos una
                                ciudad para activar la distribución.
                            </span>
                        </>
                    )}
                </div>
            </div>
            {exedent &&
            proposal.proposalGeographics?.length &&
            proposal.budget?.amount ? (
                <div className='footer'>
                    <div className='icon'>
                        {Math.sign(exedent) === 1 ? (
                            <ArrowUpOutlined />
                        ) : Math.sign(exedent) === -1 ? (
                            <ArrowDownOutlined />
                        ) : (
                            ''
                        )}
                    </div>

                    <div className='footer-text'>
                        {`
                    ${
                        Math.sign(exedent) === 1
                            ? 'Has excedido tu presupuesto por '
                            : Math.sign(exedent) === -1
                            ? 'Quedan por distribuir '
                            : ''
                    }
                    ${
                        currencies.find(
                            (item) => item.id === proposal.budget?.currency_id,
                        ).iso_code
                    }
                    ${Functions.formatNumber(Math.abs(exedent))}. Edita los
                    valores asignados. También puedes utilizar el botón
                    Restaurar valores para restaurar a los valores
                    predeterminados.`}
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    )
}
