import { InputNumberDetail } from "../FormItems/InputNumberDetail";
// @ts-ignore
import { Checkbox } from "@tdt-global/styleguide";
// @ts-ignore
import { Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ProposalModel } from "../../../../../../Models/ProposalModel";
import { useEffect, useState } from "react";
import { loaderAbstractType } from "../../../../../../Types/LoaderAbstract";

type PropType = {
  visible: boolean;
  isPercentage: boolean;
  isFlex: boolean | null;
  proposal: ProposalModel;
  suffixSelectorCurrency: JSX.Element;
  suffixSelectorFlex: JSX.Element;
  disableCheckBox: boolean;
  loaderAbstract: loaderAbstractType[];
  disabledAll: boolean;
  checkFlex: () => void;
  handleVisibleChange: (visible: any) => void;
};

export const Budgets = ({
  visible,
  isFlex,
  loaderAbstract,
  isPercentage,
  proposal,
  suffixSelectorCurrency,
  suffixSelectorFlex,
  disableCheckBox,
  disabledAll,
  handleVisibleChange,
  checkFlex,
}: PropType) => {
  const [propsFlex, setPropsFlex] = useState({
    min: 0,
    max: 100,
    placeholder: "Ingresa un valor entre 0 y 100.",
    errorMessage: "Por favor ingresa un valor exacto o porcentaje.",
    errorMessageMax: "Por favor ingresa un valor exacto o porcentaje.",
  });
  useEffect(() => {
    if (isPercentage) {
      setPropsFlex({
        min: 1,
        max: 100,
        placeholder: "Ingresa valor entre 0 y 100.",
        errorMessage: "Por favor ingresa un porcentaje.",
        errorMessageMax: "Ingresa un valor entre 0 y 100.",
      });
    } else {
      setPropsFlex({
        min: 1,
        max: proposal.budget ? Number(proposal.budget.amount) : 2000000000,
        placeholder: "Ingresa un importe.",
        errorMessage: "Por favor ingresa un valor exacto.",
        errorMessageMax:
          "Por favor ingresa un valor menor o igual al presupuesto.",
      });
    }
  }, [isPercentage, proposal?.budget?.amount]);

  return (
    <div className="budget-container">
      <InputNumberDetail
        size="large"
        name="budget"
        label="Presupuesto"
        className="large text-end"
        placeholder="Ingresa un importe."
        errorMessage="Por favor ingresa un importe."
        disabled={
          loaderAbstract.find((item) => item.key === "budget")?.loader ||
          disabledAll
        }
        required
        min={170000}
        max={2000000000}
        suffixSelector={suffixSelectorCurrency}
        errorMessageMax="Por favor ingresa un valor menor o igual a 2.000.000.000."
      />

      <div className="check-flex">
        <Checkbox
          checked={isFlex}
          disabled={
            !proposal?.budget?.amount ||
            disableCheckBox ||
            loaderAbstract.find((item) => item.key === "budget")?.loader ||
            disabledAll
          }
          onClick={checkFlex}
        >
          <div>Presupuesto flexible</div>
        </Checkbox>
        <Popover
          placement="topLeft"
          style={{ maxWidth: "300px" }}
          content={
            <div
              style={{
                color: "#595959",
                maxWidth: "300px",
              }}
              className="description"
            >
              El presupuesto flexible será ejecutado si al momento de generar el
              resultado no se encuentran soportes dentro del presupuesto
              original que cumplan con las necesidades de la planificación.
            </div>
          }
          trigger="click"
          arrowPointAtCenter
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <InfoCircleOutlined />
        </Popover>
      </div>

      {isFlex && (
        <div className="flex-container">
          <span className="flex-title">
            Incluye un valor o porcentaje por el que se puede exceder tu
            presupuesto.
          </span>

          <InputNumberDetail
            size="large"
            className="large text-end"
            name="flex_value"
            min={0}
            disabled={
              loaderAbstract.find((item) => item.key === "budget")?.loader ||
              disabledAll
            }
            required={isFlex}
            suffixSelector={suffixSelectorFlex}
            {...propsFlex}
          />
        </div>
      )}
    </div>
  );
};
