import { SocioEconomicType } from '../Types/SocioEconomicType'

export class SocioEconomicModel {
    private _id: number
    private _name: string
    private _type: string
    private _short_name: string | null
    private _order: number

    constructor(socioEcomomic: SocioEconomicType) {
        this._id = socioEcomomic.id
        this._name = socioEcomomic.attributes.name
        this._type = socioEcomomic.type
        this._short_name = socioEcomomic.attributes.short_name
        this._order = socioEcomomic.attributes.order
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get short_name(): string | null {
        return this._short_name
    }

    set short_name(value) {
        this._short_name = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get order(): number {
        return this._order
    }

    set order(value) {
        this._order = value
    }
}
