// @ts-ignore
import { Steps, Tooltip } from 'antd'
import { EditOutlined, StopOutlined } from '@ant-design/icons'
import { StepList } from '../../../../Common/StepList'
import { useEffect, useState } from 'react'
import { ProposalModel } from '../../../../Models/ProposalModel'
const { Step } = Steps

type PropType = {
    searchParams: URLSearchParams
    setParams: (str: number) => void
    stepMax: number
    proposal: ProposalModel
}

export const Navigation = ({ ...props }: PropType) => {
    const { searchParams, setParams, stepMax, proposal } = props
    const [tooltip, setTooltip] = useState('')

    const onChange = (current) => {
        if (current <= stepMax && !proposal.planningsId) {
            setParams(current)
        }
    }

    useEffect(() => {
        let text = StepList.find((item) => item.id === stepMax)
        setTooltip(text.tooltip)
    }, [stepMax])

    const customStep = (text, index) => {
        let tabNumber = Number(searchParams.get('tab'))
        let icon = null

        if (tabNumber === index) {
            icon = null
        } else if (proposal.planningsId) {
            icon = <StopOutlined />
        } else if (stepMax >= index) {
            icon = <EditOutlined />
        } else if (stepMax < index) {
            icon = <StopOutlined />
        }

        return (
            <Tooltip placement='topLeft' title={stepMax < index ? tooltip : ''}>
                <div className='step'>
                    {text}
                    {icon}
                </div>
            </Tooltip>
        )
    }

    useEffect(() => {
        if (document.querySelectorAll('.change-classname-step')) {
            let data = document.querySelectorAll('.change-classname-step')
            data.forEach((element) => {
                if (!proposal.planningsId) {
                    element.className = element.className.replace(
                        'ant-steps-item-wait',
                        'ant-steps-item-finish',
                    )
                } else {
                    element.className = element.className.replace(
                        'ant-steps-item-finish',
                        'ant-steps-item-wait',
                    )
                }
            })
        }
    }, [searchParams, stepMax])

    const getClassName = (index) => {
        if (stepMax >= index) {
            return 'change-classname-step'
        } else return ''
    }

    return (
        <Steps
            current={Number(searchParams.get('tab'))}
            onChange={onChange}
            progressDot
            direction='vertical'>
            {StepList.map((item) => (
                <Step
                    key={item.id}
                    className={getClassName(item.id)}
                    title={customStep(item.title, item.id)}
                />
            ))}
        </Steps>
    )
}
