import { GeolocationType } from '../Types/GeolocationType'
import { StateType } from '../Types/StateType'

export class StateModel {
    private _id: number
    private _type: string
    private _name: string
    private _country_id: number
    private _iso_3166_2: string
    private _geolocation: GeolocationType

    constructor(state: StateType) {
        this._id = state.id
        this._type = state.type
        this._name = state.attributes.name
        this._country_id = state.attributes.country_id
        this._iso_3166_2 = state.attributes.iso_3166_2
        this._geolocation = state.attributes.geolocation
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get iso_3166_2(): string {
        return this._iso_3166_2
    }

    set iso_3166_2(value) {
        this._iso_3166_2 = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get country_id(): number {
        return this._country_id
    }

    set country_id(value) {
        this._country_id = value
    }

    get geolocation(): GeolocationType {
        return this._geolocation
    }

    set geolocation(value) {
        this._geolocation = value
    }
}
