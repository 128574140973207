import { Card } from './Card'
import cero from '../../Assets/Img/cero.jpg'
import campaign from '../../Assets/Img/campaign-lab.jpg'
import fast from '../../Assets/Img/fast-start.jpg'
import { Suspense, useCallback } from 'react'
import FromStart from './FromStart/FromStart'
//@ts-ignore
import { utility } from '@tdt-global/utility'
// @ts-ignore
import { Loading } from '@tdt-global/styleguide'
import { useSearchParams } from 'react-router-dom'

export const NewPlanification = () => {
    const { LocalStorage } = utility
    let [searchParams, setSearchParams] = useSearchParams()

    const setOption = (str: string) => {
        removeAdvertiser()
        if (str === 'fromStart') {
            setSearchParams({ option: str, tab: '0', status: 'create' })
        } else setSearchParams({ option: str })
    }

    const removeAdvertiser = useCallback(() => {
        LocalStorage.removeAdvertiser()
    }, [])

    const RenderOption = () => {
        switch (searchParams.get('option')) {
            case 'fromStart':
                return <FromStart />
                break
            case 'quick start':
                return <h1>quick start</h1>
                break

            case 'campaign lab':
                return <h1>campaign lab</h1>
                break

            default:
                break
        }
    }

    return (
        <div className='new-planification'>
            {!searchParams.get('option') ? (
                <div className='planification-card'>
                    <Card
                        image={cero}
                        setOption={setOption}
                        value='fromStart'
                        title='Desde cero'
                        description='Esta opción permite controlar cada aspecto de tu planificación. Define la audiencia, configura la estrategia y completa los detalles. Puedes editar todos los datos para obtener mejores resultados.'
                        isAnimated={true}
                    />
                    <Card
                        image={fast}
                        setOption={setOption}
                        value='quick start'
                        comingSoon
                        title='Comienzo rápido'
                        description='Esta opción permite optimizar al máximo tu tiempo a la hora de crear una planificación exitosa. Ahorra pasos y aprovecha todo el poder de Planning.'
                        isAnimated={true}
                    />
                    <Card
                        image={campaign}
                        setOption={setOption}
                        value='campaign lab'
                        comingSoon
                        title='Lab de campañas'
                        description='Esta opción permite jugar con la herramienta, aprender, testear distintas estrategias. Puedes recurrir al lab cuando tengas dudas y necesites una guía extra en tu tarea.'
                        isAnimated={true}
                    />
                </div>
            ) : (
                <Suspense fallback={<Loading />}>{RenderOption()}</Suspense>
            )}
        </div>
    )
}
