import lottieAnimation from '../../../../Assets/Img/load-planification-list.json'
import Lottie from 'lottie-web'
import { useEffect } from 'react'

export const PreLoad = () => {
    const initLottie = () => {
        Lottie.loadAnimation({
            container: document.getElementById('animation'), // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: lottieAnimation, // the path to the animation json
        })
    }

    useEffect(() => {
        initLottie()
    }, [])

    return (
        <div className='pre-load'>
            <div id='animation'></div>
            <div className='text'>
                Aguarda mientras cargamos los datos de tu planificación
            </div>
        </div>
    )
}
