import { OrganizationsType } from '../../Types/element/OrganizationsType'

export class OrganizationsModel {
    private _id: number
    private _type: string
    private _name: string | null
    private _aliasName: string
    private _file: string
    private _organizationTypeId: number
    private _subcategoryId: number
    private _webSite: string

    constructor(org: OrganizationsType) {
        this._id = org.id
        this._type = org.type
        this._name = org.attributes.name
        this._aliasName = org.attributes.alias_name
        this._file = org.attributes.file
        this._organizationTypeId = org.attributes.organization_type_id
        this._subcategoryId = org.attributes.subcategory_id
        this._webSite = org.attributes.web_site
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get aliasName(): string {
        return this._aliasName
    }

    set aliasName(value) {
        this._aliasName = value
    }

    get name(): string {
        return this._name
    }

    set name(value) {
        this._name = value
    }

    get file(): string {
        return this._file
    }

    set file(value) {
        this._file = value
    }

    get organizationTypeId(): number {
        return this._organizationTypeId
    }

    set organizationTypeId(value) {
        this._organizationTypeId = value
    }

    get subcategoryId(): number {
        return this._subcategoryId
    }

    set subcategoryId(value) {
        this._subcategoryId = value
    }

    get webSite(): string {
        return this._webSite
    }

    set webSite(value) {
        this._webSite = value
    }
}
