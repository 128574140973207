import AdvertiserItem from './AdvertiserItem'
import { ArrowRightOutlined } from '@ant-design/icons'
// @ts-ignore
import { utility } from '@tdt-global/utility'
import { useCallback, useEffect, useState } from 'react'
// @ts-ignore
import { Skeleton } from 'antd'
import { AccountModel } from '../../../../../Models/AccountModel'
import { ResultType } from '../../../../../Types/ResultType'
// @ts-ignore
import { ButtonsModalConfirm, Button } from '@tdt-global/styleguide'
import { ShowModalType } from '../../../../../Types/ShowModalType'
import { useSearchParams } from 'react-router-dom'

type PropType = {
    spinnerAccountList: boolean
    proposal: ResultType
    advertiserList: AccountModel[]
    spinnerLoad: boolean
    finishAdvertiser: () => void
    onChangeResult: (account: any) => void
}

export const Advertiser = ({ ...props }: PropType) => {
    const {
        spinnerAccountList,
        advertiserList,
        proposal,
        spinnerLoad,
        onChangeResult,
        finishAdvertiser,
    } = props
    let [searchParams] = useSearchParams()
    const { ShowModal, Functions } = utility

    const [propsModal, setPropsModal] = useState<ShowModalType>({
        show: false,
        title: '',
        className: '',
        hideModal: null,
        component: null,
        modalProps: {},
        confirmLoading: false,
    })

    const groupBy = useCallback(() => {
        let result = []
        let aux = []
        let letter = ''
        advertiserList.map((item) => {
            if (item.name.charAt(0).toUpperCase() === letter) {
                aux.push(item)
                letter = item.name.charAt(0).toUpperCase()
            } else {
                aux.push(item)
                aux.length && result.push(aux)
                aux = []
                letter = item.name.charAt(0).toUpperCase()
            }
        })
        return result
    }, [advertiserList, proposal])

    const hideModal = () => {
        setPropsModal({
            show: false,
            ...propsModal,
        })
    }

    const onChange = (elem: AccountModel) => {
        if (proposal?.account?.id && elem.id !== proposal?.account?.id) {
            const handleOk = () => {
                onChangeResult({
                    account: elem,
                })
                setPropsModal({
                    show: false,
                    ...propsModal,
                })
            }

            setPropsModal({
                show: true,
                className: 'modal-confirm',
                title: 'Modificar anunciante',
                component: (
                    <>
                        Estás por modificar el anunciante. Esta modificación
                        tendrá impacto sobre el resultado de la planificación.
                        ¿Deseas modificar el anunciante?
                        <ButtonsModalConfirm
                            okText='Modificar'
                            cancelText='Cancelar'
                            handleOK={handleOk}
                            handleCancel={hideModal}
                        />
                    </>
                ),
                hideModal: hideModal,
                modalProps: {
                    closable: false,
                    width: 588,
                    footer: null,
                },
            })
        } else
            onChangeResult({
                account: elem,
            })
    }

    useEffect(() => {
        if (
            advertiserList &&
            !spinnerLoad &&
            document.getElementById('advertiser-selected')
        ) {
            if (proposal?.account?.id) {
                setTimeout(() => {
                    const node = document.getElementById('advertiser-selected')
                    const body = document.getElementById('body-advertiser')
                    var headerOffset = body.offsetTop + 16
                    var elementPosition =
                        node.getBoundingClientRect().top - headerOffset

                    if (node) {
                        body.scrollTo({
                            top: elementPosition,
                            behavior: 'smooth',
                        })
                    }
                }, 200)
            }
        }
    }, [spinnerLoad, advertiserList, searchParams])

    return (
        <div className='advertiser-tab'>
            <div className='title'>Selecciona un anunciante</div>
            <div
                className={`body ${proposal.account && 'body-small'}`}
                id='body-advertiser'>
                <Skeleton
                    loading={!spinnerAccountList || spinnerLoad}
                    style={{ width: 400, height: 50 }}
                    avatar
                    active
                    paragraph={{ rows: 0 }}
                />
                <Skeleton
                    loading={!spinnerAccountList || spinnerLoad}
                    style={{ width: 400, height: 50 }}
                    avatar
                    active
                    paragraph={{ rows: 0 }}
                />
                <Skeleton
                    loading={!spinnerAccountList || spinnerLoad}
                    style={{ width: 400, height: 50 }}
                    avatar
                    active
                    paragraph={{ rows: 0 }}
                />
                <Skeleton
                    loading={!spinnerAccountList || spinnerLoad}
                    style={{ width: 400, height: 50 }}
                    avatar
                    active
                    paragraph={{ rows: 0 }}
                />
                <Skeleton
                    loading={!spinnerAccountList || spinnerLoad}
                    style={{ width: 400, height: 50 }}
                    avatar
                    active
                    paragraph={{ rows: 0 }}
                />
                <Skeleton
                    loading={!spinnerAccountList || spinnerLoad}
                    style={{ width: 400, height: 50 }}
                    avatar
                    active
                    paragraph={{ rows: 0 }}
                />

                {advertiserList &&
                    !spinnerLoad &&
                    groupBy().map((item, index) => {
                        let letter = item[0].name.charAt(0).toUpperCase()

                        return (
                            <div className='row' key={index}>
                                <div className='label'>{letter}</div>
                                <div className='list'>
                                    {item.map((elem) => (
                                        <AdvertiserItem
                                            key={elem.id}
                                            elem={elem}
                                            onChange={onChange}
                                            proposal={proposal}
                                            isAnimated={true}
                                        />
                                    ))}
                                </div>
                            </div>
                        )
                    })}

                <ShowModal {...propsModal} />
            </div>
            {proposal.account && (
                <div className='footer-next'>
                    <Button onClick={finishAdvertiser} type={'primary'}>
                        Siguiente <ArrowRightOutlined />
                    </Button>
                    <span className='next'>
                        Próximo paso, selecciona los objetivos.
                    </span>
                </div>
            )}
        </div>
    )
}
