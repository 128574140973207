// @ts-ignore
import { Checkbox, Space } from 'antd'
import { SubObjectiveModel } from '../../../../../Models/SubObjectiveModel'
// @ts-ignore
import { Checkbox as CheckboxTDT } from '@tdt-global/styleguide'

type PropType = {
    subObjectives: SubObjectiveModel[]
    value: number[]
    onChangeHandle: (num: number[]) => void
}

export const FormObjectiveItem = ({
    subObjectives,
    value,
    onChangeHandle,
}: PropType) => {
    return (
        <Checkbox.Group onChange={onChangeHandle} value={value}>
            <Space direction='vertical'>
                {subObjectives.map((item, index) => (
                    <CheckboxTDT key={index} value={item.id}>
                        {item.name}
                    </CheckboxTDT>
                ))}
            </Space>
        </Checkbox.Group>
    )
}
