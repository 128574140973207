import Lottie from 'lottie-web'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import planningLogo from '../../../Assets/Img/Planning-Logo.svg'
import planningText from '../../../Assets/Img/Planning-Text.svg'
import { Path } from '../../../Common/Path'
import lottieAnimation from '../../../Assets/Img/load-animation.json'

export const LoadOptimization = ({ setLoadOptimization, loadOptimization }) => {
    const navigate = useNavigate()

    const initLottie = () => {
        Lottie.loadAnimation({
            container: document.getElementById('animation'), // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: lottieAnimation, // the path to the animation json
        })
    }

    useEffect(() => {
        initLottie()
    }, [])

    let sentences = [
        'Analizando los datos ingresados...',
        'Optimizando el resultado para garantizar la mejor distribución del presupuesto...',
        'Generando el mejor mix de medios para alcanzar el objetivo de campaña...',
        'Buscando soportes que coincidan con los parámetros de estrategia...',
        'Seleccionando ubicaciones que se ajustan al presupuesto...',
    ]
    return (
        <>
            <div
                className={`white ${
                    loadOptimization ? 'show' : 'hidden'
                }`}></div>
            <div
                className={`load-optimization ${
                    loadOptimization ? 'show' : 'hidden'
                }`}>
                <div className='navbar'>
                    <div className='left'>
                        <div
                            className='logo'
                            onClick={() => navigate(Path.PLANNING)}>
                            <img
                                className='logotipo'
                                alt='logo'
                                src={planningLogo}
                            />
                            <img
                                className='text'
                                alt='text'
                                src={planningText}
                            />
                        </div>
                    </div>
                </div>
                <div id='animation'></div>
                <div className='sentences'>
                    {sentences.map((sentence, index) => (
                        <div key={index}>{sentence}</div>
                    ))}
                </div>
            </div>
        </>
    )
}
