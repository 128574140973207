import { HeaderOptimizationType } from '../Types/HeaderOptimizationType'
import { BudgetModel } from './BudgetModel'
import { PlanningsModel } from './PlanningsModel'
import { ProposalModel } from './ProposalModel'

export class HeaderOptimizationModel {
    private _planning: PlanningsModel
    private _proposal: ProposalModel
    private _proposal_budget: BudgetModel

    constructor(header: HeaderOptimizationType, included?) {
        this._planning = new PlanningsModel(header.planning)
        this._proposal = new ProposalModel(header.proposal, included)
        this._proposal_budget = new BudgetModel(
            header.proposal_budget,
            included,
        )
    }

    get planning(): PlanningsModel {
        return this._planning
    }

    set planning(value) {
        this._planning = value
    }

    get proposal(): ProposalModel {
        return this._proposal
    }

    set proposal(value) {
        this._proposal = value
    }

    get proposal_budget(): BudgetModel {
        return this._proposal_budget
    }

    set proposal_budget(value) {
        this._proposal_budget = value
    }
}
