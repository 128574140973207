import { PeriodType } from '../Types/PeriodType'

export class PeriodModel {
    private _id: number
    private _type: string
    private _name: string
    private _daysQuantity: number

    constructor(period: PeriodType, included: any) {
        this._id = period.id
        this._type = period.type
        this._name = period.attributes.name
        this._daysQuantity = period.attributes.days_quantity
    }

    get id(): number {
        return this._id
    }

    get type(): string {
        return this._type
    }

    get name(): string {
        return this._name
    }

    get daysQuantity(): number {
        return this._daysQuantity
    }

    set daysQuantity(value: number) {
        this._daysQuantity = value
    }

    set name(value: string) {
        this._name = value
    }

    set type(value: string) {
        this._type = value
    }

    set id(value: number) {
        this._id = value
    }
}
