// @ts-ignore
import { Button } from '@tdt-global/styleguide'
import { Table, Popover, Skeleton } from 'antd'
import { RetweetOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
//@ts-ignore
import { utility } from '@tdt-global/utility'
import { Endpoints } from '../../../../../../Common/Endpoints'
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons/lib/icons'
import { ReplaceModel } from '../../../../../../Models/result/replace/ReplaceModel'
import { HeaderOptimizationModel } from '../../../../../../Models/HeaderOptimizationModel'

type PropTypes = {
    id: number
    proposal: HeaderOptimizationModel
    onClose: () => void
    loadResult: () => void
}

export const BodyDrawerReplace = ({ ...props }: PropTypes) => {
    const { id, proposal, onClose, loadResult } = props
    const { useAxios, Functions, ShowImage } = utility
    const { getHttpRequest, patchHttpRequest } = useAxios()
    const [loading, setLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const [original, setOriginal] = useState<ReplaceModel[]>([])
    const [replaceList, setReplaceList] = useState<ReplaceModel[]>([])
    const [rowSelection, setRowSelection] = useState([])

    const dataSkeleton = [
        { key: 0 },
        { key: 1 },
        { key: 2 },
        { key: 3 },
        { key: 4 },
        { key: 5 },
    ]

    useEffect(() => {
        if (id) {
            setOriginal([])
            setReplaceList([])
            loadReplaceList()
        }
    }, [id])

    const loadReplaceList = () => {
        setLoading(true)
        getHttpRequest(
            Endpoints.GET_RESULT_REPLACE_ASSETS.replace('{id}', id.toString()),
            {},
            (response) => {
                let data: ReplaceModel[] = response.data.data.map(
                    (item) => new ReplaceModel(item, response.data.included),
                )
                setOriginal([data[0]])
                setReplaceList(data.slice(1))
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {
                setLoading(false)
            },
        )
    }

    const patchReplaceAssets = () => {
        setUpdateLoading(true)
        let data = replaceList.find((item) => item.id === rowSelection[0])
        let param = {
            data: {
                id: id,
                type: 'planning_elements',
                attributes: {
                    element_id: data.id,
                    price: data.price,
                    ots: data.ots,
                    ots_estimated: data.otsEstimated,
                    cpm: data.cpm,
                    cpm_estimated: data.cpmEstimated,
                    period_id: data.periodId,
                },
            },
        }

        patchHttpRequest(
            Endpoints.PATCH_REPLACE_ASSETS.replace('{id}', id.toString()),
            { ...param },
            (response) => {
                Functions.openNotificationSuccess(
                    'Se ha reemplazado el soporte correctamente',
                    'Éxito',
                )
                onClose()
                loadResult()
            },
            (error) => {
                Functions.openNotificationError(
                    Functions.errorObject(error),
                    'Error',
                )
            },
            () => {
                setUpdateLoading(false)
            },
        )
    }

    const columnsBetterOption: any = [
        {
            title: (
                <span className='title-replace'>
                    Mejor opción
                    <Popover
                        style={{ maxWidth: '300px' }}
                        content={
                            <div
                                style={{
                                    color: '#595959',
                                    maxWidth: '300px',
                                }}
                                className='description'>
                                {
                                    'Información sobre la optimización de inversión'
                                }
                            </div>
                        }
                        zIndex={2000}
                        arrowPointAtCenter>
                        <InfoCircleOutlined />
                    </Popover>
                </span>
            ),
            dataIndex: 'image',
            key: 'image',
            // fixed: 'left',
            width: 236,
            ellipsis: {
                showTitle: false,
            },
            render: (image: any, item: ReplaceModel) => (
                <div className='assets-image'>
                    {loading ? (
                        <Skeleton.Avatar
                            shape='square'
                            active
                            style={{ height: '122px', width: '160px' }}
                        />
                    ) : (
                        <ShowImage
                            url={item?.element?.image?.dir}
                            isAnimated={true}
                        />
                    )}
                </div>
            ),
        },

        {
            title: 'Tipo y ubicación',
            dataIndex: 'type',
            width: 200,
            key: 'type',
            ellipsis: {
                showTitle: false,
            },
            render: (type: any, item: ReplaceModel) => {
                if (loading) {
                    return (
                        <div className='skeleton-container'>
                            <Skeleton.Button active />
                            <Skeleton.Button active />
                            <Skeleton.Button active />
                        </div>
                    )
                }
                let hierarchy1 = item?.element?.hierarchy?.name
                let hierarchy2 = item?.element?.hierarchy?.hierarchy?.name
                let hierarchy3 =
                    item?.element?.hierarchy?.hierarchy?.hierarchy?.name
                return (
                    <div>
                        {hierarchy1 && hierarchy2 && hierarchy3 && (
                            <>
                                <div className='bold gray'>
                                    {hierarchy1 ? hierarchy1 : ''}
                                </div>
                                <div className='ellipsis-two-lines'>{`${
                                    hierarchy2 ? hierarchy2 : ''
                                } / ${hierarchy3 ? hierarchy3 : ''}`}</div>
                                <div className='ellipsis-two-lines text-min'>
                                    {item?.element?.address?.address}
                                </div>
                            </>
                        )}
                    </div>
                )
            },
        },

        {
            title: 'OTS',
            dataIndex: 'ots',
            key: 'ots',
            // width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (ots: any, item: ReplaceModel) => {
                return (
                    <div>
                        {loading ? (
                            <div className='skeleton-container'>
                                <Skeleton.Button active />
                                <Skeleton.Button active />
                                <Skeleton.Button active />
                                <Skeleton.Button active />
                            </div>
                        ) : (
                            <>
                                <div>
                                    {Functions.formatNumber(item?.otsEstimated)}
                                </div>
                                <div
                                    className='text-min'
                                    style={{ marginBottom: '4px' }}>
                                    Totales
                                </div>
                                <div className='box flex g-2'>
                                    <div className='bold'>
                                        {Functions.formatNumber(item?.ots)}
                                    </div>
                                </div>
                                <div className='text-min'>Por audiencia</div>
                            </>
                        )}
                    </div>
                )
            },
        },

        {
            title: 'CPM',
            dataIndex: 'cpm_audience',
            key: 'cpm_audience',
            // width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (cpm: any, item: ReplaceModel) => {
                return (
                    <div>
                        {loading ? (
                            <div className='skeleton-container'>
                                <Skeleton.Button active />
                                <Skeleton.Button active />
                                <Skeleton.Button active />
                                <Skeleton.Button active />
                            </div>
                        ) : (
                            <div>
                                <div className='flex g-2'>
                                    <div>
                                        {Functions.formatNumber(
                                            item?.cpmEstimated,
                                        )}
                                    </div>
                                    <span className='text'>
                                        {
                                            proposal?.proposal_budget?.currency
                                                ?.iso_code
                                        }
                                    </span>
                                </div>
                                <div
                                    className='text-min'
                                    style={{ marginBottom: '4px' }}>
                                    Totales
                                </div>

                                <div className='box flex g-2'>
                                    <div className='bold'>
                                        {Functions.formatNumber(item?.cpm)}
                                    </div>
                                    <span className='text'>
                                        {
                                            proposal?.proposal_budget?.currency
                                                ?.iso_code
                                        }
                                    </span>
                                </div>
                                <div className='text-min'>Por audiencia</div>
                            </div>
                        )}
                    </div>
                )
            },
        },

        {
            title: 'Medidas',
            dataIndex: 'specifications',
            key: 'specifications',
            // width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (specifications: any, item: ReplaceModel) => {
                return (
                    <div>
                        {loading ? (
                            <div className='skeleton-container'>
                                <Skeleton.Button active />
                                <Skeleton.Button active />
                                <Skeleton.Button active />
                                <Skeleton.Button active />
                            </div>
                        ) : (
                            <>
                                <div>
                                    {Functions.formatNumber(
                                        item?.element?.commercialHeight,
                                    )}
                                    <span className='text-min'>
                                        {item?.element?.unitMeasure?.symbol}
                                    </span>
                                </div>
                                <div
                                    className='text-min'
                                    style={{ marginBottom: '4px' }}>
                                    Alto
                                </div>
                                <div>
                                    {Functions.formatNumber(
                                        item?.element?.commercialWidth,
                                    )}
                                    <span className='text-min'>
                                        {item?.element?.unitMeasure?.symbol}
                                    </span>
                                </div>
                                <div className='text-min'>Ancho</div>
                            </>
                        )}
                    </div>
                )
            },
        },

        {
            title: 'Precio',
            dataIndex: 'price',
            key: 'price',
            // width: 195,
            ellipsis: {
                showTitle: false,
            },
            render: (price: any, item: ReplaceModel) => {
                return (
                    <div>
                        {loading ? (
                            <div className='skeleton-container'>
                                <Skeleton.Button active />
                            </div>
                        ) : (
                            <div className='box flex g-2'>
                                <div className='bold'>
                                    {Functions.formatNumber(item?.price)}
                                </div>
                                <span className='text'>
                                    {
                                        proposal?.proposal_budget?.currency
                                            ?.iso_code
                                    }
                                </span>
                            </div>
                        )}
                    </div>
                )
            },
        },
        {
            title: 'Vendor',
            dataIndex: 'provider',
            key: 'provider',
            width: 195,
            ellipsis: {
                showTitle: false,
            },
            render: (provider: any, item: ReplaceModel) => {
                return (
                    <div>
                        {loading ? (
                            <div className='skeleton-container'>
                                <Skeleton.Button active />
                            </div>
                        ) : (
                            <div>{item?.element?.vendor?.name}</div>
                        )}
                    </div>
                )
            },
        },
    ]
    const columns: any = [
        {
            title: '',
            dataIndex: 'image',
            key: 'image',
            // fixed: 'left',
            width: 204,
            ellipsis: {
                showTitle: false,
            },
            render: (image: any, item: ReplaceModel) => (
                <div className='assets-image'>
                    {loading ? (
                        <Skeleton.Avatar
                            shape='square'
                            active
                            style={{ height: '122px', width: '160px' }}
                        />
                    ) : (
                        <ShowImage
                            url={item?.element?.image?.dir}
                            isAnimated={true}
                        />
                    )}
                </div>
            ),
        },

        {
            title: 'Tipo y ubicación',
            dataIndex: 'type',
            width: 200,
            key: 'type',
            ellipsis: {
                showTitle: false,
            },
            render: (type: any, item: ReplaceModel) => {
                let hierarchy1 = item?.element?.hierarchy?.name
                let hierarchy2 = item?.element?.hierarchy?.hierarchy?.name
                let hierarchy3 =
                    item?.element?.hierarchy?.hierarchy?.hierarchy?.name
                if (loading) {
                    return (
                        <div className='skeleton-container'>
                            <Skeleton.Button active className='skeleton-gray' />
                            <Skeleton.Button active className='skeleton-gray' />
                            <Skeleton.Button active className='skeleton-gray' />
                        </div>
                    )
                }
                return (
                    <div>
                        {hierarchy1 && hierarchy2 && hierarchy3 && (
                            <>
                                <div className='bold gray'>
                                    {hierarchy1 ? hierarchy1 : ''}
                                </div>
                                <div className='ellipsis-two-lines'>{`${
                                    hierarchy2 ? hierarchy2 : ''
                                } / ${hierarchy3 ? hierarchy3 : ''}`}</div>
                                <div className='ellipsis-two-lines text-min'>
                                    {item?.element?.address?.address}
                                </div>
                            </>
                        )}
                    </div>
                )
            },
        },

        {
            title: 'OTS',
            dataIndex: 'ots',
            key: 'ots',
            // width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (ots: any, item: ReplaceModel) => {
                return (
                    <div>
                        {loading ? (
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                                <Skeleton.Button active />
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                            </div>
                        ) : (
                            <>
                                <div>
                                    {Functions.formatNumber(item?.otsEstimated)}
                                </div>
                                <div
                                    className='text-min'
                                    style={{ marginBottom: '4px' }}>
                                    Totales
                                </div>
                                <div className='box flex g-2'>
                                    <div className='bold'>
                                        {Functions.formatNumber(item?.ots)}
                                    </div>
                                </div>
                                <div className='text-min'>Por audiencia</div>
                            </>
                        )}
                    </div>
                )
            },
        },

        {
            title: 'CPM',
            dataIndex: 'cpm_audience',
            key: 'cpm_audience',
            // width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (cpm: any, item: ReplaceModel) => {
                return (
                    <div>
                        {loading ? (
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                                <Skeleton.Button active />
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                            </div>
                        ) : (
                            <div>
                                <div className='flex g-2'>
                                    <div>
                                        {Functions.formatNumber(
                                            item?.cpmEstimated,
                                        )}
                                    </div>
                                    <span className='text'>
                                        {
                                            proposal?.proposal_budget?.currency
                                                ?.iso_code
                                        }
                                    </span>
                                </div>
                                <div
                                    className='text-min'
                                    style={{ marginBottom: '4px' }}>
                                    Totales
                                </div>

                                <div className='box flex g-2'>
                                    <div className='bold'>
                                        {Functions.formatNumber(item?.cpm)}
                                    </div>
                                    <span className='text'>
                                        {
                                            proposal?.proposal_budget?.currency
                                                ?.iso_code
                                        }
                                    </span>
                                </div>
                                <div className='text-min'>Por audiencia</div>
                            </div>
                        )}
                    </div>
                )
            },
        },

        {
            title: 'Medidas',
            dataIndex: 'specifications',
            key: 'specifications',
            // width: 200,
            ellipsis: {
                showTitle: false,
            },
            render: (specifications: any, item: ReplaceModel) => {
                return (
                    <div>
                        {loading ? (
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                            </div>
                        ) : (
                            <>
                                <div>
                                    {Functions.formatNumber(
                                        item?.element?.commercialHeight,
                                    )}
                                    <span className='text-min'>
                                        {item?.element?.unitMeasure?.symbol}
                                    </span>
                                </div>
                                <div
                                    className='text-min'
                                    style={{ marginBottom: '4px' }}>
                                    Alto
                                </div>
                                <div>
                                    {Functions.formatNumber(
                                        item?.element?.commercialWidth,
                                    )}
                                    <span className='text-min'>
                                        {item?.element?.unitMeasure?.symbol}
                                    </span>
                                </div>
                                <div className='text-min'>Ancho</div>
                            </>
                        )}
                    </div>
                )
            },
        },

        {
            title: 'Precio',
            dataIndex: 'price',
            key: 'price',
            width: 160,
            ellipsis: {
                showTitle: false,
            },
            render: (price: any, item: ReplaceModel) => {
                let indicator =
                    item?.priceIndicator === 1 ? (
                        <span className='indicator green'>
                            <ArrowDownOutlined />
                        </span>
                    ) : item?.priceIndicator === -1 ? (
                        <span className='indicator red'>
                            <ArrowUpOutlined />
                        </span>
                    ) : (
                        <span className='indicator'></span>
                    )
                return (
                    <div>
                        {loading ? (
                            <div className='skeleton-container'>
                                <Skeleton.Button active />
                            </div>
                        ) : (
                            <div className='indicator-container'>
                                {indicator}

                                <div className='box flex g-2'>
                                    <div className='bold'>
                                        {Functions.formatNumber(item?.price)}
                                    </div>
                                    <span className='text'>
                                        {
                                            proposal?.proposal_budget?.currency
                                                ?.iso_code
                                        }
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
        },
        {
            title: 'Vendor',
            dataIndex: 'provider',
            key: 'provider',
            width: 195,
            ellipsis: {
                showTitle: false,
            },
            render: (provider: any, item: ReplaceModel) => {
                return (
                    <div>
                        {loading ? (
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                            </div>
                        ) : (
                            <div>{item?.element?.vendor?.name}</div>
                        )}
                    </div>
                )
            },
        },
    ]

    const selectRow = (record: any) => {
        setRowSelection([record.key])
    }

    const handleRowSelection = {
        onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: ReplaceModel[],
        ) => {
            setRowSelection(selectedRowKeys)
        },
    }

    return (
        <section id='drawer-replace-body'>
            <div className='body'>
                <article>
                    {/* <SpinnerBar loading={loading}></SpinnerBar> */}
                    <Table
                        id='assets-table-origin'
                        columns={columnsBetterOption}
                        dataSource={
                            original.length === 0 && loading
                                ? [dataSkeleton[0]]
                                : original
                        }
                        pagination={false}
                        loading={{
                            spinning: loading,
                            indicator: null,
                        }}
                    />
                </article>

                <h4>Selecciona un reemplazo</h4>
                <article>
                    <Table
                        id='assets-table-replace'
                        columns={columns}
                        rowSelection={{
                            type: 'radio',
                            selectedRowKeys: rowSelection,
                            ...handleRowSelection,
                        }}
                        onRow={(record) => ({
                            onClick: () => {
                                !loading && selectRow(record)
                            },
                        })}
                        dataSource={
                            replaceList.length === 0 && loading
                                ? dataSkeleton
                                : replaceList
                        }
                        pagination={false}
                        loading={{
                            spinning: loading,
                            indicator: null,
                        }}
                    />
                </article>
            </div>
            <footer>
                <Button key='back' ghost size='small' onClick={onClose}>
                    Cancelar
                </Button>

                <Button
                    key='submit'
                    ghost
                    size='small'
                    disabled={rowSelection.length === 0}
                    loading={updateLoading}
                    onClick={patchReplaceAssets}>
                    Reemplazar <RetweetOutlined />
                </Button>
            </footer>
        </section>
    )
}
