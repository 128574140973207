// @ts-ignore
import { Collapse, Skeleton } from 'antd'
import { SkeletonTable } from './SkeletonTable'

const { Panel } = Collapse

export const SkeletonAssestsList = () => {
    return (
        <div className='skeletons'>
            <div className='callapse-item'>
                <div className='collapse-city'>
                    <div className='skeleton-container'>
                        <Skeleton.Button
                            active
                            className='skeleton-gray'
                            style={{ width: '10%' }}
                        />
                    </div>
                </div>
                <Collapse
                    bordered={false}
                    defaultActiveKey={['0']}>
                    <Panel
                        header={
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                    style={{ width: '11%' }}
                                />
                            </div>
                        }
                        key={0}
                        showArrow={false}
                        extra={
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                    style={{ width: '10%', height: '12px'}}
                                />
                            </div>
                        }>
                        <SkeletonTable list={[]} loading={false} />
                    </Panel>
                    <Panel
                        header={
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                    style={{
                                        width: '10%',
                                        verticalAlign: 'middle',
                                    }}
                                />
                            </div>
                        }
                        showArrow={false}
                        key={1}
                        extra={
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                    style={{ width: '10%' }}
                                />
                            </div>
                        }></Panel>
                    <Panel
                        header={
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                    style={{
                                        width: '10%',
                                        verticalAlign: 'middle',
                                    }}
                                />
                            </div>
                        }
                        showArrow={false}
                        key={1}
                        extra={
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                    style={{ width: '10%' }}
                                />
                            </div>
                        }></Panel>
                </Collapse>
            </div>
            <div className='callapse-item'>
                <div className='collapse-city'>
                    <div className='skeleton-container'>
                        <Skeleton.Button
                            active
                            style={{
                                width: '10%',
                                backgroundColor: '#c8b7f5b8',
                            }}
                        />
                    </div>
                </div>
                <Collapse
                    accordion
                    bordered={false}
                    defaultActiveKey={['0']}
                    expandIconPosition={'left'}>
                    <Panel
                        header={
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                    style={{
                                        width: '11%',
                                        verticalAlign: 'middle',
                                    }}
                                />
                            </div>
                        }
                        showArrow={false}
                        key={1}
                        extra={
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                            </div>
                        }></Panel>
                    <Panel
                        header={
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                    style={{
                                        width: '11%',
                                        verticalAlign: 'middle',
                                    }}
                                />
                            </div>
                        }
                        showArrow={false}
                        key={2}
                        extra={
                            <div className='skeleton-container'>
                                <Skeleton.Button
                                    active
                                    className='skeleton-gray'
                                />
                            </div>
                        }></Panel>
                </Collapse>
            </div>
        </div>
    )
}
