import { Box } from "../Box";
import { TabsHeader } from "../TabsHeader";

// @ts-ignore
import {
  Form,
  Input,
  DatePicker,
  Select,
  Empty,
  // @ts-ignore
} from "antd";
import { ProposalModel } from "../../../../../Models/ProposalModel";
import { useEffect, useState } from "react";
import {
  Button,
  SpinnerBar,
  ButtonsModalConfirm,
  // @ts-ignore
} from "@tdt-global/styleguide";
// @ts-ignore
import moment from "moment";

//@ts-ignore
import { utility } from "@tdt-global/utility";
import { FireOutlined } from "@ant-design/icons";
import { Endpoints } from "../../../../../Common/Endpoints";

import { CurrenciesModel } from "../../../../../Models/CurrenciesModel";
import { FlexModel } from "../../../../../Models/FlexModel";

import { BudgetModel } from "../../../../../Models/BudgetModel";
import { Cities } from "./Cities";

import { Budgets } from "./Budgets/Budgets";
import { BudgetDistribution } from "./BudgetDistribution/BudgetDistribution";
import { ProposalGeographicsType } from "../../../../../Types/ProposalGeographicsType";
import { ProposalGeographicsModel } from "../../../../../Models/ProposalGeographicsModel";
import { SuggestedBudgetType } from "../../../../../Types/SuggestedBudgetType";
import { SuggestedBudgetModel } from "../../../../../Models/SuggestedBudgetModel";
import { loaderAbstractType } from "../../../../../Types/LoaderAbstract";
import { ShowModalType } from "../../../../../Types/ShowModalType";
import { localeES } from "./LocaleDatePicker";
import "moment/locale/es";

const { RangePicker } = DatePicker;
const { Option } = Select;
type PropType = {
  currencies: CurrenciesModel[];
  proposal: ProposalModel;
  flex: FlexModel[];
  loaderAbstract: loaderAbstractType[];
  budgetDistributionSuggested: SuggestedBudgetModel[];
  disabledAll: boolean;
  setDisabledAll: (value: boolean) => void;
  setBudgetDistributionSuggested: (
    budgetDistributionSuggested: SuggestedBudgetModel[]
  ) => void;
  getOptimization: () => void;
  updateProposalDetail: (data: any) => void;
  setLoaderAbstract: (key: string, loader: boolean) => void;
  onChangeDetail: (data: any) => void;
  changeResultWithOutSave: (proposal: ProposalModel) => void;
};

export const Details = ({
  proposal,
  currencies,
  loaderAbstract,
  flex,
  budgetDistributionSuggested,
  disabledAll,
  setDisabledAll,
  setBudgetDistributionSuggested,
  getOptimization,
  setLoaderAbstract,
  updateProposalDetail,
  changeResultWithOutSave,
  onChangeDetail,
}: PropType) => {
  const [form] = Form.useForm();
  const { useAxios, Functions, ShowModal } = utility;
  const { getHttpRequest, postHttpRequest, patchHttpRequest } = useAxios();
  const [loadSpin, setLoadSpin] = useState(true);
  const [timeOutid, setTimeOutId] = useState(null);
  const [timeOutBudget, setTimeOutBudget] = useState(null);
  const [reqCreate, setReqCreate] = useState(null);
  const [reqUpdate, setReqUpdate] = useState(null);
  const [isPercentage, setIsPercentage] = useState(true);
  const [timeOutFlex, setTimeOutFlex] = useState(null);
  const [isFlex, setIsFlex] = useState(null);
  const [visible, setVisible] = useState(false);
  const [disableCheckBox, setDisableCheckBox] = useState(true);
  const [disableDistributionRestore, setDisableDistributionRestore] =
    useState(true);
  const [propsModal, setPropsModal] = useState<ShowModalType>({
    show: false,
    title: "",
    className: "",
    hideModal: null,
    component: null,
    modalProps: {},
    confirmLoading: false,
  });

  useEffect(() => {
    if (proposal.id) {
      setLoadSpin(false);
      let name =
        proposal.name && proposal.objectives
          ? proposal.name
          : `${proposal.objectives
              .find((item) => item.primary)
              ?.name.toLowerCase()}`;
      let dates = [
        proposal.start_date ? moment(proposal.start_date) : moment(new Date()),
        proposal.finish_date
          ? moment(proposal.finish_date)
          : moment(new Date()).add(1, "M"),
      ];

      let objectToSave: any = {};

      if (!proposal.name) objectToSave.name = name;
      if (!proposal.start_date) objectToSave.dates = dates;

      if (!proposal.name || !proposal.start_date)
        updateProposalDetail(objectToSave);

      form.setFieldsValue({
        campaignName: name,
        dates: dates,
      });
    }
  }, [proposal]);

  useEffect(() => {
    let formValues: any = {};
    if (proposal.budget && currencies.length && flex.length) {
      formValues = {
        ...formValues,
        budget: proposal.budget.amount,
        currency: proposal.budget.currency_id,
      };
      setDisableCheckBox(false);

      if (proposal.budget.flex_type_id && proposal.budget.flex_value) {
        setIsFlex(true);
        formValues = {
          ...formValues,
          flex_type: proposal.budget.flex_type_id,
          flex_value: proposal.budget.flex_value,
        };
      }
      form.setFieldsValue(formValues);
    } else {
      formValues = {
        ...formValues,
        flex_type: 2,
        currency: 56,
      };
      form.setFieldsValue(formValues);
    }
  }, [proposal.budget]);

  useEffect(() => {
    if (
      document
        .getElementsByClassName("ant-picker-range")[0]
        ?.className.includes("ant-picker-focused")
    ) {
      let test = document.getElementsByClassName("ant-picker-range");

      test[0].className = test[0].className.replace("ant-picker-focused", "");
      console.log("focused");
    }
    if (
      document.getElementsByClassName("ant-input-number-affix-wrapper-focused")
    ) {
      let test = document.getElementsByClassName(
        "ant-input-number-affix-wrapper"
      );

      for (let index = 0; index < test.length; index++) {
        test[index].className = test[index].className.replace(
          "ant-input-number-affix-wrapper-focused",
          ""
        );
      }
    }
  });

  useEffect(() => {
    let value = form.getFieldsValue(["flex_type"]);
    setIsPercentage(value.flex_type === 2);
  }, [proposal?.budget?.flex_type_id]);

  const hadProposalGeographic = () => {
    return !!proposal?.proposalGeographics?.length;
  };

  const onValuesChange = (value, allValues) => {
    let param: any = {};
    let keyValue = Object.keys(value)[0];

    if (keyValue === "budget" && allValues.budget === null) {
      allValues.budget = "";
      disableCheck();
    } else if (keyValue === "budget" && allValues.budget !== null) {
      setDisableCheckBox(false);
    }

    if (keyValue === "flex_value" && allValues.flex_value === null) {
      allValues.flex_value = "";
    }

    if (keyValue === "budget" && allValues.budget > 2000000000) {
      clearTimeout(timeOutBudget);
      form.validateFields(["budget"]);
      return;
    }

    if (
      keyValue === "flex_value" &&
      allValues.flex_type === 1 &&
      allValues.flex_value > allValues.budget
    ) {
      clearTimeout(timeOutFlex);
      form.validateFields(["flex_value"]);
      return;
    }

    if (
      keyValue === "flex_value" &&
      allValues.flex_type === 2 &&
      allValues.flex_value > 100
    ) {
      clearTimeout(timeOutFlex);
      form.validateFields(["flex_value"]);
      return;
    }

    if (keyValue === "flex_type") {
      setIsPercentage(value[keyValue] === 2);
      allValues.flex_value = "";
      form.setFieldsValue({
        flex_value: "",
      });
    }

    if (keyValue == "budget" && allValues.budget < allValues.flex_value) {
      allValues.flex_value = "";
      form.setFieldsValue({
        flex_value: "",
      });
    }

    if (keyValue === "campaignName") {
      param = {
        ...param,
        key: keyValue,
        value: value[keyValue],
      };
      clearTimeout(timeOutid);
      setTimeOutId(
        setTimeout(() => {
          if (value[keyValue] === "") {
            let nameStart = `${proposal.objectives
              .find((item) => item.primary)
              ?.name.toLowerCase()}`;
            let name = Functions.capitalizeFirstLetter(nameStart);
            param.value = name;
            form.setFieldsValue({
              [keyValue]: name,
            });
          }
          onChangeDetail(param);
          setDisabledAll(true);
        }, 1000)
      );
    } else if (keyValue === "dates") {
      param = {
        ...param,
        key: keyValue,
        value: value[keyValue] ? value[keyValue] : [],
      };
      onChangeDetail(param);
      setDisabledAll(true);
    } else if (
      keyValue === "budget" &&
      (allValues.budget || allValues.budget === 0 || allValues.budget === "") &&
      allValues.currency
    ) {
      if (
        keyValue === "budget" &&
        (value[keyValue] || value[keyValue] === 0) &&
        value[keyValue].toString() !== proposal.budget?.amount
      ) {
        if (!proposal.budget) {
          clearTimeout(timeOutBudget);
          setTimeOutBudget(
            setTimeout(() => {
              createBudget(allValues, hadProposalGeographic());
              setDisabledAll(true);
            }, 1000)
          );
        } else {
          clearTimeout(timeOutBudget);
          setTimeOutBudget(
            setTimeout(() => {
              updateBudget(allValues, hadProposalGeographic());
              setDisabledAll(true);
            }, 1000)
          );
        }
      } else clearTimeout(timeOutBudget);
    } else if (keyValue === "currency" && allValues.budget) {
      updateBudget(allValues, hadProposalGeographic());
      setDisabledAll(true);
    } else if (
      keyValue === "flex_value" &&
      (allValues.flex_value ||
        allValues.flex_value === 0 ||
        allValues.flex_value === "") &&
      allValues.flex_type &&
      allValues.budget &&
      allValues.currency
    ) {
      if (
        keyValue === "flex_value" &&
        (value[keyValue] ||
          value[keyValue] === 0 ||
          allValues.flex_value === "") &&
        value[keyValue]?.toString() !== proposal.budget?.flex_value
      ) {
        if (!proposal.budget) {
          clearTimeout(timeOutFlex);
          setTimeOutFlex(
            setTimeout(() => {
              createBudget(allValues);
              setDisabledAll(true);
            }, 1000)
          );
        } else {
          clearTimeout(timeOutFlex);
          setTimeOutFlex(
            setTimeout(() => {
              updateBudget(allValues);
              setDisabledAll(true);
            }, 1000)
          );
        }
      } else clearTimeout(timeOutFlex);
    } else if (
      keyValue === "flex_type" &&
      allValues.budget &&
      allValues.currency &&
      allValues.flex_value
    ) {
      updateBudget(allValues, hadProposalGeographic());
      setDisabledAll(true);
    } else if (keyValue.startsWith("input")) {
      if (!value[keyValue]) {
        setDisableDistributionRestore(false);
        clearTimeout(timeout);
        return;
      } else {
        setDisableDistributionRestore(true);
      }

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        let param = [
          {
            id: Number(keyValue.split("-")[1]),
            type: "proposal_geographics",
            attributes: {
              budget: value[keyValue] ? value[keyValue] : 0,
            },
          },
        ];
        setLoaderAbstract("proposalGeographics", true);
        patchProposalGeographics(param);
        setDisabledAll(true);
      }, 1000);
    }

    // setDisabledAll(true)
  };
  let timeout = null;

  const createParamSuggested = (suggestedBudget: SuggestedBudgetModel[]) => {
    return suggestedBudget.map((item) => ({
      id: item.id,
      type: item.type,
      attributes: {
        budget: item.amount,
      },
    }));
  };

  const getSuggestedBudget = (showAlert = true, enableAll?) => {
    setLoaderAbstract("proposalGeographics", true);
    setDisabledAll(true);
    getHttpRequest(
      Endpoints.GET_SUGGESTED_BUDGET_BY_PROPOSAL.replace(
        "{id}",
        proposal.id.toString()
      ),
      {},
      (response) => {
        let suggestedBudget: SuggestedBudgetModel[] = response?.data.data.map(
          (item: SuggestedBudgetType) => new SuggestedBudgetModel(item)
        );
        setBudgetDistributionSuggested(suggestedBudget);
        let param = createParamSuggested(suggestedBudget);
        patchProposalGeographics(param, showAlert);
        enableAll && setDisabledAll(false);
      },
      (error) => {
        Functions.openNotificationError(Functions.errorObject(error), "Error");
        setLoaderAbstract("proposalGeographics", false);
      },
      () => {}
    );
  };

  const patchProposalGeographics = (param, showAlert = true) => {
    patchHttpRequest(
      Endpoints.PATCH_PROPOSAL_GEOGRAPHICS.replace(
        "{id}",
        proposal.id.toString()
      ),
      {
        data: param,
        sort: ["-budget"],
        include: ["city.state", "city.department", "city"],
      },
      (response) => {
        let proposalGeographics = response?.data.data.map(
          (item: ProposalGeographicsType) =>
            new ProposalGeographicsModel(item, response.data.included)
        );
        proposal.proposalGeographics = proposalGeographics;
        changeResultWithOutSave(proposal);
        setLoaderAbstract("cities", false);
        setLoaderAbstract("proposalGeographics", false);
        setDisableDistributionRestore(true);
        setDisabledAll(false);
        showAlert &&
          Functions.openAlertSuccess("Se han guardado todos los cambios");
      },
      (error) => {
        Functions.openNotificationError(Functions.errorObject(error), "Error");
        setLoaderAbstract("proposalGeographics", false);
      },
      () => {}
    );
  };

  const updateBudget = (allValues, refreshSuggested?) => {
    setLoaderAbstract("budget", true);

    setReqUpdate(
      patchHttpRequest(
        Endpoints.PATCH_BUDGET.replace("{id}", proposal.budget.id.toString()),
        {
          data: {
            id: proposal.budget.id,
            type: "proposal_budgets",
            attributes: {
              proposal_id: proposal.id,
              currency_id: allValues.currency,
              amount: allValues.budget,
              flex_type_id: allValues.flex_type ? allValues.flex_type : null,
              flex_value:
                allValues.flex_value || allValues.flex_value === 0
                  ? allValues.flex_value
                  : null,
            },
          },
        },
        (response) => {
          let newBudget = new BudgetModel(response.data.data);
          proposal.budget = newBudget;
          changeResultWithOutSave(proposal);
          setLoaderAbstract("budget", false);
          setDisableDistributionRestore(true);
          refreshSuggested
            ? getSuggestedBudget(false, true)
            : setDisabledAll(false);
          Functions.openAlertSuccess("Se han guardado todos los cambios");
        },
        (error) => {
          Functions.openNotificationError(
            Functions.errorObject(error),
            "Error"
          );
          setLoaderAbstract("budget", false);
        },
        () => {}
      )
    );
  };

  const createBudget = (allValues, refreshSuggested?) => {
    setLoaderAbstract("budget", true);

    setReqCreate(
      postHttpRequest(
        Endpoints.POST_BUDGET,
        {
          data: {
            type: "proposal_budgets",
            attributes: {
              proposal_id: proposal.id,
              currency_id: allValues.currency,
              amount: allValues.budget,
              flex_type_id: allValues.flex_type ? allValues.flex_type : null,
              flex_value:
                allValues.flex_value || allValues.flex_value === 0
                  ? allValues.flex_value
                  : null,
            },
          },
        },
        (response) => {
          let newBudget = new BudgetModel(response.data.data);
          proposal.budget = newBudget;
          changeResultWithOutSave(proposal);
          refreshSuggested && getSuggestedBudget(false);
          setLoaderAbstract("budget", false);
          setDisabledAll(false);
          Functions.openAlertSuccess("Se han guardado todos los cambios");
        },
        (error) => {
          Functions.openNotificationError(
            Functions.errorObject(error),
            "Error"
          );
          setLoaderAbstract("budget", false);
        },
        () => {}
      )
    );
  };

  const hideModal = () => {
    setPropsModal({
      show: false,
      ...propsModal,
    });
  };

  const finishDetail = () => {
    form
      .validateFields()
      .then(() => {
        const handleOk = () => {
          getOptimization();
          setPropsModal({
            show: false,
            ...propsModal,
          });
        };
        setPropsModal({
          show: true,
          className: "modal-confirm",
          title: "Resultado",
          component: (
            <>
              Una vez que generes el resultado no podrás volver a editar los
              pasos previos. ¿Deseas continuar?
              <ButtonsModalConfirm
                okText="Continuar"
                cancelText="Cancelar"
                handleOK={handleOk}
                handleCancel={hideModal}
              />
            </>
          ),
          hideModal: hideModal,
          modalProps: {
            closable: false,
            width: 588,
            footer: null,
          },
        });
      })
      .catch(() => {
        console.log("fallo");
      });
  };

  const suffixSelectorCurrency = (
    <Form.Item name="currency" noStyle>
      <Select
        loading={!currencies}
        disabled={
          loaderAbstract.find((item) => item.key === "budget")?.loader ||
          disabledAll
        }
        notFoundContent={<Empty description={false} />}
        style={{ width: 200 }}
      >
        {currencies
          .filter((item) => item.id === 56)
          .map((item, index) => (
            <Option
              key={index}
              value={item.id}
            >{`${item.name} (${item.iso_code})`}</Option>
          ))}
      </Select>
    </Form.Item>
  );

  const suffixSelectorFlex = (
    <Form.Item name="flex_type" noStyle>
      <Select
        loading={!flex}
        disabled={
          loaderAbstract.find((item) => item.key === "budget")?.loader ||
          disabledAll
        }
        notFoundContent={<Empty description={false} />}
        style={{ width: 200 }}
      >
        {flex.map((item, index) => (
          <Option key={index} value={item.id}>{`${item.name}`}</Option>
        ))}
      </Select>
    </Form.Item>
  );

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const clearInformation = () => {
    let param = form.getFieldsValue(true);
    param.flex_type = 2;
    param.flex_value = null;
    updateBudget(param);
  };

  const disableCheck = () => {
    if (isFlex) {
      checkFlex();
    } else {
      clearInformation();
    }
    setDisableCheckBox(true);
  };

  const checkFlex = () => {
    if (isFlex && proposal.budget?.flex_type_id) {
      clearInformation();
    } else {
      form.setFieldsValue({
        flex_type: 2,
        flex_value: null,
      });
    }
    setIsFlex((prev) => !prev);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  return (
    <>
      <Form
        name="basic"
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
        autoComplete="off"
      >
        <div className="detail-tab">
          <TabsHeader
            title="Completa los detalles"
            text="¡Último paso! Para generar un resultado a la medida de tu campaña completa los detalles, ten en cuenta que todos los campos son obligatorios. Para facilitar aún más tu planificación te sugerimos el nombre y período."
          />

          <Box>
            <Form.Item
              className="middle"
              label="Nombre de campaña"
              name="campaignName"
              rules={[
                {
                  required: true,
                  message: "Por favor completa el nombre de campaña.",
                },
              ]}
            >
              <Input
                allowClear
                size="large"
                type="text"
                disabled={disabledAll}
                placeholder="Ej. Anunciante - Objetivo de campaña"
              />
            </Form.Item>
          </Box>
          <Box>
            <Form.Item
              className="middle"
              label="Período"
              name="dates"
              rules={[
                {
                  type: "array" as const,
                  required: true,
                  message: "Por favor selecciona un periodo.",
                },
              ]}
            >
              <RangePicker
                className="full"
                locale={localeES}
                disabledDate={disabledDate}
                allowClear={false}
                disabled={disabledAll}
                size="large"
                format={"DD MMM. YYYY"}
              />
            </Form.Item>
          </Box>

          <Box>
            <Budgets
              isPercentage={isPercentage}
              isFlex={isFlex}
              visible={visible}
              proposal={proposal}
              suffixSelectorCurrency={suffixSelectorCurrency}
              suffixSelectorFlex={suffixSelectorFlex}
              disableCheckBox={disableCheckBox}
              loaderAbstract={loaderAbstract}
              checkFlex={checkFlex}
              disabledAll={disabledAll}
              handleVisibleChange={handleVisibleChange}
            />
          </Box>

          <SpinnerBar
            loading={
              loaderAbstract.find((item) => item.key === "proposalGeographics")
                ?.loader
            }
          >
            <Box>
              <Cities
                form={form}
                proposal={proposal}
                loaderAbstract={loaderAbstract}
                disabledAll={disabledAll}
                setDisabledAll={setDisabledAll}
                setLoaderAbstract={setLoaderAbstract}
                getSuggestedBudget={getSuggestedBudget}
                changeResultWithOutSave={changeResultWithOutSave}
              />
            </Box>
          </SpinnerBar>

          <SpinnerBar
            loading={
              proposal.proposalGeographics?.length !== 0 &&
              proposal.budget?.amount &&
              loaderAbstract.find((item) => item.key === "proposalGeographics")
                ?.loader
            }
          >
            <Box>
              <BudgetDistribution
                budgetDistributionSuggested={budgetDistributionSuggested}
                currencies={currencies}
                proposal={proposal}
                disableDistributionRestore={disableDistributionRestore}
                disabledAll={disabledAll}
                form={form}
                getSuggestedBudget={getSuggestedBudget}
              />
            </Box>
          </SpinnerBar>

          <div className="footer-next">
            <Button onClick={finishDetail} type={"primary"}>
              Resultado <FireOutlined />
            </Button>
            <span className="next">Ver optimización</span>
          </div>
          <ShowModal {...propsModal} />
        </div>
      </Form>
    </>
  );
};
