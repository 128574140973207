import { LightingTypesType } from '../../Types/element/LightingTypesType'

export class LightingTypesModel {
    private _type: string
    private _id: number
    private _name: string

    constructor(lighting: LightingTypesType) {
        this._type = lighting.type
        this._id = lighting.id
        this._name = lighting.attributes.name
    }

    get type(): string {
        return this._type
    }
    set type(value: string) {
        this._type = value
    }

    get name(): string {
        return this._name
    }
    set name(value: string) {
        this._name = value
    }

    get id(): number {
        return this._id
    }
    set id(value: number) {
        this._id = value
    }
}
