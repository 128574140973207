import { InterestProfileModel } from '../InterestProfileModel'
// @ts-ignore
import { Tooltip } from 'antd'

type PropoType = {
    interestModal: InterestProfileModel[]
}

export const TabsInterestProfile = ({ interestModal }: PropoType) => {
    return (
        <div className='tabs-categories'>
            {interestModal?.map((item, index) => (
                <div className='categories' key={index}>
                    <div className='categories-name'>
                        <div className='circle-modal'></div> {item.name}
                    </div>
                    <div className='subcategories-list'>
                        <ul>
                            {item.interestList?.map((elem, val) => (
                                <li key={val} className='subcategories'>
                                    {elem.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    )
}
