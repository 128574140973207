import { ReplaceType } from '../../../Types/result/replace/ReplaceType'
import { ElementModel } from '../assetsList/ElementModel'

export class ReplaceModel {
    private _id: number
    private _key: number
    private _price: number
    private _priceIndicator: number
    private _otsEstimated: number
    private _ots: number
    private _cpmEstimated: number
    private _cpm: number
    private _periodId: number
    private _priceUsd: number
    private _element: ElementModel

    constructor(replace: ReplaceType, included: any) {
        this._id = replace.id
        this._key = replace.id
        this._price = replace.attributes.price
        this._priceIndicator = replace.attributes.price_indicator
        this._otsEstimated = replace.attributes.ots_estimated
        this._ots = replace.attributes.ots
        this._cpmEstimated = replace.attributes.cpm_estimated
        this._cpm = replace.attributes.cpm
        this._periodId = replace.attributes.period_id
        this._priceUsd = replace.attributes.price_usd

        if (included && replace.relationships?.element?.id) {
            this._element = new ElementModel(
                included.find(
                    (item) =>
                        item.id === replace.relationships.element.id &&
                        item.type === replace.relationships.element.type,
                ),
                included,
            )
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get key(): number {
        return this._key
    }

    set key(value) {
        this._key = value
    }

    get element(): ElementModel {
        return this._element
    }

    set element(value) {
        this._element = value
    }

    get price(): number {
        return this._price
    }

    set price(value) {
        this._price = value
    }

    get priceIndicator(): number {
        return this._priceIndicator
    }

    set priceIndicator(value) {
        this._priceIndicator = value
    }

    get otsEstimated(): number {
        return this._otsEstimated
    }

    set otsEstimated(value) {
        this._otsEstimated = value
    }

    get ots(): number {
        return this._ots
    }

    set ots(value) {
        this._ots = value
    }

    get cpmEstimated(): number {
        return this._cpmEstimated
    }

    set cpmEstimated(value) {
        this._cpmEstimated = value
    }

    get cpm(): number {
        return this._cpm
    }

    set cpm(value) {
        this._cpm = value
    }

    get periodId(): number {
        return this._periodId
    }

    set periodId(value) {
        this._periodId = value
    }

    get priceUsd(): number {
        return this._priceUsd
    }

    set priceUsd(value) {
        this._priceUsd = value
    }
}
