import {
    BarsOutlined,
    EnvironmentOutlined,
    ExperimentOutlined,
    LineChartOutlined,
} from '@ant-design/icons'
// @ts-ignore
import { Tabs } from 'antd'
const { TabPane } = Tabs

type PropType = {
    tabKey: string
    setTabKey: (num: string) => void
}

export const TabsResult = ({ tabKey, setTabKey }: PropType) => {
    return (
        <div className='tabs'>
            <Tabs defaultActiveKey={tabKey} onChange={(key) => setTabKey(key)}>
                <TabPane
                    tab={
                        <span>
                            <LineChartOutlined />
                            Performance
                        </span>
                    }
                    key='1'></TabPane>
                <TabPane
                    tab={
                        <span>
                            <ExperimentOutlined />
                            Mix de medios
                        </span>
                    }
                    key='2'></TabPane>
                <TabPane
                    tab={
                        <span>
                            <EnvironmentOutlined />
                            Mapa
                        </span>
                    }
                    key='3'></TabPane>
                <TabPane
                    tab={
                        <span>
                            <BarsOutlined />
                            Listado de soportes
                        </span>
                    }
                    key='4'></TabPane>
            </Tabs>
        </div>
    )
}
