// @ts-ignore
import { Tag } from 'antd'
import { RightOutlined } from '@ant-design/icons'

//@ts-ignore
import { utility } from '@tdt-global/utility'

type PropType = {
    image: string
    setOption: (str: string) => void
    value: string
    comingSoon?: boolean
    title: string
    description: string
    isAnimated: boolean
}
export const Card = ({ ...props }: PropType) => {
    const {
        image,
        setOption,
        value,
        comingSoon,
        title,
        description,
        isAnimated,
    } = props
    const { ShowImage } = utility
    return (
        <div
            className={`card ${comingSoon ? '' : 'comingSoon'}`}
            onClick={() => !comingSoon && setOption(value)}>
            <div className='box image'>
                <ShowImage url={image} isAnimated={true} />
            </div>
            <div className='box text'>
                <div className='title'>
                    <span>{title}</span>
                    {comingSoon && (
                        <div className='tag'>
                            <Tag>Próximamente</Tag>
                        </div>
                    )}
                </div>
                <div className='description'>{description}</div>
            </div>
            {!comingSoon && (
                <div className='box new-button'>
                    <RightOutlined />
                </div>
            )}
        </div>
    )
}
