import { AssetsListType } from '../../../Types/result/assetsList/AssetsListType'
import { PlanningElementsGeographicsModel } from './PlanningElementsGeographicsModel'

export class AssetsListModel {
    private _planningElements: PlanningElementsGeographicsModel[]

    constructor(assets: AssetsListType, included: any) {
        this._planningElements = assets.data.map(
            (item) => new PlanningElementsGeographicsModel(item, included),
        )
    }

    get planningElements(): PlanningElementsGeographicsModel[] {
        return this._planningElements
    }

    set planningElements(value) {
        this._planningElements = value
    }
}
