import { InfoCircleOutlined } from '@ant-design/icons'
// @ts-ignore
import { Popover } from 'antd'

type PropType = {
    strategy: string
}

export const MixComponentsInfoWindow = ({ strategy }: PropType) => {
    return (
        <span>
            <Popover
                placement='topLeft'
                style={{}}
                content={
                    <div>
                        {strategy === 'Frecuencia'
                            ? 'Entre los parámetros de la estrategia, este tipo de soporte se destaca por su cualidad de brindar frecuencia.'
                            : strategy === 'Segmentación'
                            ? ' Entre los parámetros de la estrategia, este tipo de soporte se destaca por su cualidad de especificar segmentación.'
                            : strategy === 'Cobertura'
                            ? 'Entre los parámetros de la estrategia, este tipo de soporte se destaca por su cualidad de brindar cobertura.'
                            : strategy === 'Impacto'
                            ? 'Entre los parámetros de la estrategia, este tipo de soporte se destaca por su cualidad de producir gran impacto.': ''}
                    </div>
                }
                trigger='click'
                arrowPointAtCenter>
                <InfoCircleOutlined />
            </Popover>
        </span>
    )
}
