import { BudgetType } from '../Types/BudgetType'
import { CurrenciesModel } from './CurrenciesModel'
import { FlexModel } from './FlexModel'

export class BudgetModel {
    private _id: number
    private _type: string
    private _proposal_id: number
    private _currency_id: number
    private _amount: string
    private _flex_type_id: number | null
    private _flex_value: string | null
    private _created_at: string
    private _currency: CurrenciesModel
    private _flex: FlexModel

    constructor(budget: BudgetType, included?) {
        this._id = budget.id
        this._type = budget.type
        this._proposal_id = budget.attributes.proposal_id
        this._currency_id = budget.attributes.currency_id
        this._amount = budget.attributes.amount
        this._flex_type_id = budget.attributes.flex_type_id
        this._flex_value = budget.attributes.flex_value
        this._created_at = budget.attributes.created_at
        this._currency = null
        this._flex = null

        if (included) {
            budget.attributes.currency_id
                ? (this._currency = new CurrenciesModel(
                      included.find(
                          (item) =>
                              item.id ===
                                  budget.relationships.currency.data.id &&
                              item.type ===
                                  budget.relationships.currency.data.type,
                      ),
                  ))
                : (this._currency = null)

            budget.attributes.flex_type_id
                ? (this._flex = new FlexModel(
                      included.find(
                          (item) =>
                              item.id ===
                                  budget.relationships.flexType.data.id &&
                              item.type ===
                                  budget.relationships.flexType.data.type,
                      ),
                  ))
                : (this._flex = null)
        }
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get amount(): string {
        return this._amount
    }

    set amount(value) {
        this._amount = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get proposal_id(): number {
        return this._proposal_id
    }

    set proposal_id(value) {
        this._proposal_id = value
    }

    get currency_id(): number {
        return this._currency_id
    }

    set currency_id(value) {
        this._currency_id = value
    }

    get flex_type_id(): number | null {
        return this._flex_type_id
    }

    set flex_type_id(value) {
        this._flex_type_id = value
    }

    get flex_value(): string | null {
        return this._flex_value
    }

    set flex_value(value) {
        this._flex_value = value
    }

    get created_at(): string {
        return this._created_at
    }

    set created_at(value) {
        this._created_at = value
    }

    get currency(): CurrenciesModel {
        return this._currency
    }

    set currency(value) {
        this._currency = value
    }

    get flex(): FlexModel {
        return this._flex
    }

    set flex(value) {
        this._flex = value
    }
}
