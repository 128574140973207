//@ts-ignore
import { utility } from '@tdt-global/utility'

// @ts-ignore
import { Skeleton, Space } from 'antd'
import useSWR from 'swr'
import { Endpoints } from '../../../../../Common/Endpoints'
import { useCallback, useEffect, useState } from 'react'
import { ObjectiveModel } from '../../../../../Models/ObjectiveModel'
import { ObjectiveType } from '../../../../../Types/ObjectiveType'
import { ObjectiveItem } from './ObjectiveItem'
import { SubObjectiveModel } from '../../../../../Models/SubObjectiveModel'
import { TabsHeader } from '../TabsHeader'
import { ProposalModel } from '../../../../../Models/ProposalModel'

type PropType = {
    loadUpdateObjective: boolean
    onChangeResult: (objectives: any) => void
    spinnerCreate: boolean
    proposal: ProposalModel
    spinnerLoad: boolean
}

export const Objective = ({
    onChangeResult,
    spinnerLoad,
    loadUpdateObjective,
    spinnerCreate,
    proposal,
}: PropType) => {
    const { useAxios, Functions } = utility
    const { getHttpRequestSWR } = useAxios()
    const [objective, setObjective] = useState<ObjectiveModel[]>([])
    const [toggle, setToggle] = useState(null)
    const load = ({ url, params }) => getHttpRequestSWR(url, params)
    const { data, error }: any = useSWR(
        {
            url: Endpoints.GET_OBJECTIVES,
            params: { 'include[0]': 'complementaries' },
        },
        load,
    )

    const setObjectiveHandle = useCallback(() => {
        setObjective(
            data?.data.map(
                (item: ObjectiveType) => new ObjectiveModel(item, data?.data),
            ),
        )
    }, [data])

    useEffect(() => {
        data && setObjectiveHandle()
    }, [data])

    useEffect(() => {
        setToggle(proposal.objectives.find((elem) => elem.primary)?.id)
    }, [proposal])

    useEffect(() => {
        setTimeout(() => {
            Functions.scrollToTargetAdjusted('objective-selected')
        }, 300)
    }, [toggle])

    const onChange = (
        objective: ObjectiveModel,
        subObjective: SubObjectiveModel,
        next?: boolean,
    ) => {
        onChangeResult({
            objective: {
                objective,
                subObjective,
            },
            next: next,
        })
    }

    const renderObjectives = () => {
        if (proposal.objectives.length) {
            let obj = proposal.objectives.find((item) => item.primary)
            return objective.sort((a, b) => (a.id === obj.id ? -1 : 1))
        } else return objective
    }

    return (
        <div className='objective-tab'>
            <TabsHeader
                title='Selecciona un objetivo'
                text='El objetivo marcará el foco de tu campaña. ¿Cuál es el resultado que esperas alcanzar? Alinea las decisiones que tomes en los pasos siguientes con este objetivo para alcanzar el éxito. Puedes elegir un objetivo principal y hasta tres objetivos complementarios.'
            />

            {(!data || loadUpdateObjective || spinnerLoad) && (
                <Space direction='vertical'>
                    <Space>
                        <Skeleton.Avatar active size={100} shape={'square'} />
                        <Skeleton
                            style={{ width: 400, height: 100 }}
                            paragraph={{ rows: 2 }}
                        />
                    </Space>
                    <Space>
                        <Skeleton.Avatar active size={100} shape={'square'} />
                        <Skeleton
                            style={{ width: 400, height: 100 }}
                            paragraph={{ rows: 2 }}
                        />
                    </Space>
                    <Space>
                        <Skeleton.Avatar active size={100} shape={'square'} />
                        <Skeleton
                            style={{ width: 400, height: 100 }}
                            paragraph={{ rows: 2 }}
                        />
                    </Space>
                    <Space>
                        <Skeleton.Avatar active size={100} shape={'square'} />
                        <Skeleton
                            style={{ width: 400, height: 100 }}
                            paragraph={{ rows: 2 }}
                        />
                    </Space>
                </Space>
            )}
            {!loadUpdateObjective &&
                !spinnerLoad &&
                renderObjectives().map((item, index) => (
                    <ObjectiveItem
                        objective={item}
                        toggle={toggle}
                        setToggle={setToggle}
                        active={
                            !!proposal.objectives.find(
                                (elem) => elem.id === item.id && elem.primary,
                            )
                        }
                        key={index}
                        onChange={onChange}
                        spinnerCreate={spinnerCreate}
                        proposal={proposal}
                        isAnimated={true}
                    />
                ))}
        </div>
    )
}
