import { MainActionType } from '../Types/MainActionType'

export class MainActionModel {
    private _id: number
    private _type: string
    private _name: string
    private _order: number

    constructor(planElem: MainActionType) {
        this._id = planElem.id
        this._type = planElem.type
        this._name = planElem.attributes.name
        this._order = planElem.attributes.order
    }

    get id(): number {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get type(): string {
        return this._type
    }

    set type(value) {
        this._type = value
    }

    get name(): string {
        return this._name
    }
    set name(value: string) {
        this._name = value
    }

    get order(): number {
        return this._order
    }
    set order(value: number) {
        this._order = value
    }
}
